// ------------------ //
// --- HYPHENATOR --- //
// ------------------ //

import '@lib/Hyphenator';
/*global Hyphenator*/

(function(){
	window.hyphenationDone = jQuery.Deferred();
	var hyphenate = function() {
		var debug = true;

		// Keine automatischen Zeilenumbrüche für USA
		if (CMJSContext.page.properties.languageCode === 'en' && CMJSContext.page.properties.site.indexOf('fsb-na') !== -1) {
			return;
		}

		jQuery('p, .of-pstandard li')/*.not('.of-pstandard--slogan p')*/.addClass('hyphen-p');
		Hyphenator.config({
			classname:	'hyphen-p',
			minwordlength : 8,
			onerrorhandler : function (e) {
				//do nothing
				if (debug) console.log('Hyphenator', e);
			},
			onhyphenationdonecallback : function() {
				window.hyphenationDone.resolve();
			}
		});
		Hyphenator.run();
	};

	if (typeof Hyphenator === "undefined") {
		jQuery(function(){ hyphenate(); });
	}
	else {
		hyphenate();
	}
})();
