'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';
import SearchConstants from '../../constants/SearchConstants';
import SearchActions from '../../actions/SearchActions';

// SubComponents
import SeitenErgebnis from '../Common/SeitenErgebnis.jsx';

class Bereichsergebnisse extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			page: 1
		};
    }

    _loadMore = () => {
		if (this._isActive()) {
			this.setState({page: this.state.page + 1});
			SearchActions.filter(this.props.facetKey, (this.state.page + 1) * SearchConstants.NUM_PAGES_ACTIVE);
		}
		else {
			SearchActions.filter(this.props.facetKey);
		}
	};

    _isActive = () => {
		return this.props.facetKey === this.props.activeFacet;
	};

    render() {
		let ergebnisse = valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + this.props.facetKey + '.docs') || [];
		let numResults = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + this.props.facetKey + '.numFound')) || 0;

		// skip if no results
		if (ergebnisse.length === 0) {
			return null;
		}

		// trim display to size.
		if (!this._isActive()) {
			ergebnisse = ergebnisse.slice(0, SearchConstants.NUM_PAGES_INACTIVE);
		}
		else {
			ergebnisse = ergebnisse.slice(0, this.state.page * SearchConstants.NUM_PAGES_ACTIVE);
		}

		// render single result entry
		let ergebnisTags = ergebnisse.map((ergebnis, i) => {
			return <SeitenErgebnis key={ergebnis.id} i={i + 1} seite={ergebnis} {...this.props}/>;
		});

		// render 'load More' link or loading indicator
		let loading, loadMore;
		if (this.props.loading) {
			loading = <div className='pagesearch__ergebnisse__bereich__loading'></div>;
		}
		else {
			if (!this._isActive() && (numResults > SearchConstants.NUM_PAGES_INACTIVE) || (numResults > SearchConstants.NUM_PAGES_ACTIVE * this.state.page)) {
				loadMore = <div className='pagesearch__ergebnisse__bereich__more' onClick={this._loadMore}>{CMJSContext.L('srch.load_more')}</div>;
			}
		}

		return (
			<div className='pagesearch__ergebnisse__bereich pagesearch__ergebnisse__bereich--page'>
				<h3 className='pagesearch__ergebnisse__bereich__headline'>{this.props.facetName} ({numResults})</h3>
				{ergebnisTags}
				{loading}
				{loadMore}
			</div>
		);
	}
}

Bereichsergebnisse.propTypes = {
	facetKey: PropTypes.string.isRequired,
	facetName: PropTypes.string.isRequired,
	activeFacet: PropTypes.string,
	loading: PropTypes.bool
};
export default  Bereichsergebnisse;
