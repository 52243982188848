'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchConstants from '../../constants/SearchConstants';


class ResponsiveImage extends Component {

	displayName = 'ImagePreloader';


	constructor(props, context) {
		super(props, context);
	}

	render() {
		///static/productimg/article/564/0_12_1078_00102_0105.jpg
		let p = SearchConstants.SELEKTOR_IMAGE_URI + 'article/';
		let n = this.props.bildname;

		// Make sure the name starts with a slash
        if (n.indexOf('/') !== 0) {
            n = '/' + n;
        }

        // swap variables around if it's an imagecreator image
		if (n.indexOf('/imagecreator') === 0) {
            p = n + '&f=';
            n = '';
        }

		return (
			<picture>
				<source media="(min-width: 1200px)" data-srcset={p + '166' + n + ' 1x, ' + p + '332' + n + ' 2x'} />
				<source media = "(min-width: 1024px)" data-srcset={p + '140' + n + ' 1x, ' + p + '280' + n + ' 2x'} />
				<source media = "(min-width: 768px)" data-srcset={p + '162' + n + ' 1x, ' + p + '324' + n + ' 2x'} />
				<source media = "(max-width: 767px)" data-srcset={p + '361' + n + ' 1x, ' + p + '722' + n + ' 2x'} />
				<img className="lazyload img-responsive of-image " src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={p + '722' + n} />
			</picture>
		);
	}

}

ResponsiveImage.propTypes = {
	bildname: PropTypes.string.isRequired
};

export default  ResponsiveImage;
