'use strict';

/*
 * Holds all information prisioner.
 */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 *
 * @constructor
 * @class Represents a Merkzettel
 */
export const Merkzettel = function() {
	this.merkzettelID = 'mz-' + parseInt(Math.random() * 10000);
	this.produkte = {};
};

Merkzettel.prototype.merkzettelID = null;
Merkzettel.prototype.produkte = null;
Merkzettel.prototype.isPersistent = false;

/**
 * Adds a produkt to the merkzettel
 * @param {Produkt} produkt
 */
Merkzettel.prototype.addProdukt = function(produkt) {
	this._addProdukt(produkt, false);
};
Merkzettel.prototype._addProdukt = function(produkt) {
	const self = this;
	if (produkt != null) {
		// TODO do some Ajax stuff and move the following to the callback function

		var addProduktRequest = $j.ajax({
			type: 'POST',
			url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
			dataType: 'json',
			data: {
				module			: 'Merkzettel/AjaxMerkzettelData',
				languageCode	: CMJSContext.page.properties.languageCode,
				action			: 'addProdukt',
				pid				: produkt.pid,
				url				: produkt.url,
				mediaUrl		: produkt.mediaUrl,
				name			: produkt.name,
				shortName		: produkt.shortName,
				description		: produkt.description,
				references		: produkt.getAllReferenzenAsJSON(),
				bzqFilter		: produkt.bzqFilter
			}
		});

		addProduktRequest.done(function(data){
			if (window.merkzettelDaten.debug) log('addProduktRequest done', data);
			if (data.status === 'OK') {
				if (typeof data.uid === 'string') {
					self.merkzettelID = data.uid;
				}
				self.produkte[produkt.pid] = produkt;

				if (typeof data.p === 'string' && data.p === '1') {
					self.isPersistent = true;
				}
				else {
					self.isPersistent = false;
				}

				CMJSContext.NotificationCenter.defaultCenter.postNotification(MerkzettelDatenClass.NOTIFICATION_PRODUKT_ADDED, window.merkzettelDaten, {produkt: produkt, merkzettel: self});
				window.merkzettelDaten.updateMenuEntry();
			}
			else if (data.status === 'NOK') {
				if (window.merkzettelDaten.debug) log('addProduktRequest Error.', data);
			}
		});

		addProduktRequest.fail(function(){
			if (window.merkzettelDaten.debug) log('Failed to perform addProduktRequest');
		});
	}
};

/**
 * Removes a produkt from the merkzettel
 * @param {Produkt} produkt
 */
Merkzettel.prototype.removeProdukt = function(produkt) {
	var retVal = false;
	var self = this;
	if (produkt instanceof Produkt) {

		var storedProdukt = null;
		$j.each(self.produkte, function(i,p) {
			if (p.equals(produkt)) {
				storedProdukt = self.produkte[p.pid];
			}
		});

		if (storedProdukt !== null) {
			var removeProduktRequest = $j.ajax({
				type: 'POST',
				url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
				dataType: 'json',
				data: {
					module			: 'Merkzettel/AjaxMerkzettelData',
					languageCode	: CMJSContext.page.properties.languageCode,
					action			: 'removeProdukt',
					pid				: storedProdukt.pid
				}
			});

			removeProduktRequest.done(function(data){
				if (window.merkzettelDaten.debug) log('removeProduktRequest done', data, self.produkte);
				if (data.statusMessage === 'OK') {
					delete self.produkte[storedProdukt.pid];
					if (window.merkzettelDaten.debug) log('removeProduktRequest deleted product', data, self.produkte);
					CMJSContext.NotificationCenter.defaultCenter.postNotification(MerkzettelDatenClass.NOTIFICATION_PRODUKT_REMOVED, window.merkzettelDaten, {produkt: storedProdukt, merkzettel: self});
					window.merkzettelDaten.updateMenuEntry();
				}
				else if (data.statusMessage === 'NOK') {
					if (window.merkzettelDaten.debug) log('removeProduktRequest Error.', data);
				}
			});

			removeProduktRequest.fail(function(){
				if (window.merkzettelDaten.debug) log('Failed to perform removeProduktRequest');
			});
		}
		else {
			if (window.merkzettelDaten.debug) log('Error. That produkt isnt even stored locally', produkt);
		}
	}

	return retVal;
};

/**
 * Test is the given produkt is stored on this merkzettel.
 * If the given produkt has not id (mostly the case) the url will be used for comparision.
 * @param {Produkt} produkt
 * @returns {boolean}
 */
Merkzettel.prototype.containsProdukt = function(produkt) {
	var retVal = false;
	$j.each(this.produkte, function(i,p) {
		if (p.equals(produkt)) {
			retVal = true;
		}
	});
	return retVal;
};

/**
 * Returns the number of Produkte stored on this Merkzettel.
 * @returns {Number}
 */
Merkzettel.prototype.numberOfProdukte = function() {
	return Object.keys(this.produkte).length;
};


/**
 * Creates a merkzettel from a given json
 * @param {Object} json
 * @return {Merkzettel|null} A new Merkzettel instance including all produkte or null if any validation failed.
 */
Merkzettel.inflateFromJSON = function(json) {
	var merkzettel = new Merkzettel();
	if (merkzettel instanceof Merkzettel) {
		merkzettel.merkzettelID = json.uid;
		if (typeof json.p === 'string' && json.p === '1') {
			merkzettel.isPersistent = true;
		}
		else {
			merkzettel.isPersistent = false;
		}
		$j.each(json.data, function(i,produktJson){
			var produkt = Produkt.inflateFromJSON(produktJson);
			if (produkt instanceof Produkt) {
				merkzettel.produkte[produkt.pid] = produkt;
			}
		});
	}

	if (window.merkzettelDaten.debug) log('inflated Merkzettel', json, merkzettel);
	return merkzettel;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 * A Product!
 * @param {String} id
 * @param {String} url
 * @param {String} mediaUrl
 * @param {String} name
 * @param {String} shortName
 * @param {String} description
 * @param {String} [bzqFilter]
 * @constructor
 * @return {Produkt|null} Returns the new object or null if the validation failed.
 * TODO validation
 */
export const Produkt = function(pid, url, mediaUrl, name, shortName, description, bzqFilter) {
	this.pid = pid;
	this.url = url;
	this.mediaUrl = mediaUrl;
	this.name = name;
	this.shortName = shortName;
	this.description = (description === '' ? ' ' : description);
	this.references = [];
	this.bzqFilter = bzqFilter;
};

Produkt.prototype.pid = null;
Produkt.prototype.url = null;
Produkt.prototype.mediaUrl = null;
Produkt.prototype.name = null;
Produkt.prototype.shortName = null;
Produkt.prototype.description = null;
Produkt.prototype.references = null;
Produkt.prototype.bzqFilter = null;

/**
 * Checks the two products for equality
 * @param {Produkt} otherProdukt
 * @returns {boolean}
 */
Produkt.prototype.equals = function(otherProdukt) {
	return otherProdukt != null && this.pid === otherProdukt.pid;
};

/**
 * Adds the given referenz to the product.
 * @param {Referenz} referenz
 */
Produkt.prototype.addReferenz = function(referenz) {
	if (referenz != null) {
		this.references.push(referenz);
	}
};

Produkt.prototype.getAllReferenzenAsJSON = function() {
	var retVal = [];

	$j.each(this.references, function(i,ref){

		if (typeof ref != "undefined") {
			retVal.push({
				url: ref.url,
				linktitle: ref.linktitle
			});
		}

	});

	return retVal;
};

/**
 * Creates a produkt from a given json
 * @param {Object} produktJson
 * @return {Produkt|null} A new Produkt instance including all produkte or null if any validation failed.
 */
Produkt.inflateFromJSON = function(produktJson) {
	var produkt = new Produkt(produktJson.pid, produktJson.url, produktJson.mediaUrl, produktJson.name, produktJson.shortName, produktJson.description, produktJson.bzqFilter);
	if (produkt instanceof Produkt) {
		try {
			var references = produktJson.references;

			if (typeof produktJson.references === 'string') {
				references = $j.parseJSON(produktJson.references);
			}

			$j.each(references, function(i,refJson){
				produkt.addReferenz(new Referenz(refJson.url, refJson.linktitle));
			});
		}
		catch (e) {
			log('Failed to parse references for produkt', produktJson.pid, e);
		}

		produkt.bzqFilter = produktJson.bzqFilter;
	}

	if (window.merkzettelDaten.debug) log('inflated Produkt', produktJson, produkt);
	return produkt;
};


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 * A reference inside a product
 * @param {String} url
 * @param {String} linktitle
 * @constructor
 * @class Represents a Referenz
 * @return {Referenz|null} Returns the new object or null if the validation failed.
 * TODO validation
 */
export const Referenz = function(url, linktitle) {
	this.url = url;
	this.linktitle = linktitle;
};

Referenz.prototype.url = null;
Referenz.prototype.linktitle = null;


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


/**
 * Initializes the data.
 */
export const MerkzettelDatenClass = function() {
	/** Enable debug outputs */
	this.debug = false;

	this.initialized = false;
	this.merkzettel = null;
	this.overlay = null;
};

/**
 * Notification about a new product which has been added to a merkzettel.
 * UserInfo:
 *  produkt - The added produkt.
 *  merkzettel - The merkzettel the produkt was added to.
 * @type {string}
 */
MerkzettelDatenClass.NOTIFICATION_PRODUKT_ADDED = 'MerkzettelProduktAdded';

/**
 * Notification about a product which has been removed from a merkzettel.
 * UserInfo:
 *  produkt - The removed produkt.
 *  merkzettel - The merkzettel the produkt was removed from.
 * @type {string}
 */
MerkzettelDatenClass.NOTIFICATION_PRODUKT_REMOVED = 'MerkzettelProduktRemoved';

/**
 * Generic notification after merkzettel itself have been added/removed/updates.
 * i.e. multiple changes or you don't care about individual updates
 * @type {string}
 */
MerkzettelDatenClass.NOTIFICATION_MERKZETTEL_UPDATE = 'MerkzettelUpdated';

/*
 * Initialize the whole shebang!
 */
MerkzettelDatenClass.prototype.init = function() {
	var self = this;
	if (self.debug) log('MerkzettelDatenClass init()');

	CMJSContext.NotificationCenter.defaultCenter.addObserver(self, 'onAuthenticationChange', window.authentication.NOTIFICATION_STATE_CHANGED, window.authentication);
	window.authentication.init().done(this.loadMerkzettel.bind(this));
};

MerkzettelDatenClass.prototype.onAuthenticationChange = function() {
	this.loadMerkzettel();
};

/**
 * Gets all information from the database/backend and stores them locally
 */
MerkzettelDatenClass.prototype.loadMerkzettel = function() {
	var self = this;

	var allMerkzettelRequest = $j.ajax({
		type: 'POST',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: {
			module			: 'Merkzettel/AjaxMerkzettelData',
			languageCode	: CMJSContext.page.properties.languageCode,
			action			: 'all'
		}
	});

	allMerkzettelRequest.done(function(data){
		if (self.debug) log('allMerkzettelRequest done', data);

		if (data === null) {
			// ich habe keinen Merkzettel :-p
			self.merkzettel = {};
			if (self.debug) log('allMerkzettelRequest done', 2);

		}
		else if (typeof data === 'object') {
			if (self.debug) log('allMerkzettelRequest done', 3);

			self.merkzettel = Merkzettel.inflateFromJSON(data);
			if (self.debug) log('allMerkzettelRequest done', 4);

		}

		if (self.debug) log('MerkzettelData', 'readyPreNotify');
		self.initialized = true;
		self.triggerUpdateNotification();
		if (self.debug) log('MerkzettelData', 'readyPostNotify');

	});

	allMerkzettelRequest.fail(function(){
		if (self.debug) log('Failed to get MerkzettelDaten');
	});
};

MerkzettelDatenClass.prototype.addProdukt = function(product) {
	if (this.merkzettel instanceof Merkzettel) {
		this.merkzettel._addProdukt(product, false);
	}
	else {
		this.merkzettel = new Merkzettel();
		this.merkzettel._addProdukt(product, true);
	}
};

MerkzettelDatenClass.prototype.triggerUpdateNotification = function() {
	if (this.debug) log('triggerUpdateNotification');
	CMJSContext.NotificationCenter.defaultCenter.postNotification(MerkzettelDatenClass.NOTIFICATION_MERKZETTEL_UPDATE, this, {});
	this.updateMenuEntry();
};

/**
 * Creates a fresh overlay container with the given title.
 * Place content in .overlay-middle
 */
MerkzettelDatenClass.prototype.getOverlayContainer = function(title) {
	if (this.overlay) {
		window.closeOverlay(); /// ????? WO KOMMT DAS HER???
		this.overlay.remove();
	}
	if ($j('#mz-overlay-container').length > 0) {
		$j('#mz-overlay-container').remove();
	}
	this.overlay = $j('<div id="mz-overlay-container" class="overlay-container">\
		<div class="overlay-dimmer"></div>\
		<div class="overlay wide">\
			<div class="overlay-top">\
				<h2>' + title + '</h2>\
				<div class="close-overlay"></div>\
			</div>\
			<div class="overlay-middle clearfix">\
			\
			</div>\
		</div>\
	</div>').appendTo('#huelle2');

	return this.overlay;
};

/**
 * Soll eine URL zur Merkzettelübersicht liefern.
 * Wenn der Parameter gesetzt ist, muss ein Parameter übergeben werden, so dass direkt der richtige Merkzettel auf der Übersichtsseite offen ist.
 * Der Link ist markt und sprachabhängig...
 * @param {String} action
 */
MerkzettelDatenClass.prototype.getHrefToMerkzettelOverview = function(action) {
	let retVal = CMJSContext.page.properties.merkzettelURL;

	if (action) {
		retVal += (retVal.indexOf('?') === -1 ? '?' : '&') + 'action=' + action;
	}

	return retVal;
};



MerkzettelDatenClass.prototype.updateMenuEntry = function() {
    const menuMerkzettel = jQuery('.mein_fsb_nav a[data-context="merkzettel"]');
	if (menuMerkzettel.length < 1) {
		if (window.merkzettelDaten.debug) console.log('MZD', 'kein #menu-merkzettel a gefunden');
		return;
	}

	let span = jQuery('span', menuMerkzettel);
	if (span.length < 1) {
		span = jQuery('<span></span>');
		span.appendTo(menuMerkzettel);
	}

	span.text(' (' + (this.merkzettel instanceof Merkzettel ? this.merkzettel.numberOfProdukte() : 0) + ')');
};
