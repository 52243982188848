'use strict';

CMJSContext.LL({

	'de': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Zurück zum Merkzettel',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Zurück',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Ansprechpartner',
		'MZ.button.dealer': 'Bezugsquellen',
		'MZ.button.sendMerkzettel': 'Weiterleiten',
		'MZ.button.saveProdukt': 'Auf Merkzettel',
		'MZ.button.sammeldownload': 'Sammeldownload',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Artikel weiterempfehlen',
		'MZ.recommend.label.recipient': 'E-Mail Empfänger',
		'MZ.recommend.error.recipient': 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
		'MZ.recommend.label.sender': 'Ihre E-Mail',
		'MZ.recommend.error.sender': 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
		'MZ.recommend.label.subject': 'Betreff',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Bitte geben Sie einen Betreff ein.',
		'MZ.recommend.label.your-message': 'Ihre Mitteilung',
		'MZ.recommend.value.your-message': 'Hallo,\ndiese(n) Artikel habe ich bei FSB entdeckt und dabei an Sie/Dich gedacht.',
		'MZ.recommend.error.your-message': 'Bitte geben Sie eine Nachricht ein.',
		'MZ.recommend.error.captcha': 'Bitte füllen Sie das Captcha aus.',
		'MZ.recommend.button.send': 'Absenden',
		'MZ.recommend.successmessage': 'Ihre Email wurde erfolgreich versendet.',
		'MZ.recommend.successbuttonlabel': 'Schließen',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'Mein Merkzettel',
		'MZ.multiview.no-produkte': 'Keine Einträge vorhanden',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'Sie wollen Ihren Merkzettel dauerhaft speichern? Dann melden Sie sich bitte mit Ihren LOGIN(Zugangsdaten) an. Sofern Sie noch keinen persönlichen Zugang haben, müssen Sie sich zunächst REGISTER(registrieren).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Kontakt',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB Fachhändler',

		'MZ.bzq.form.your-products': 'Ihre Produkte',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Tür- und Fensterbeschläge',
		'MZ.bzq.cat.ezk': 'Zutrittsmanagement',
		'MZ.bzq.cat.sani': 'Barrierefreies Wohnen',

		'MZ.bzq.srch.title': 'Händler:in finden',
		'MZ.bzq.prod.title': 'Produkte',
		'MZ.bzq.prod.amount.error': 'Bitte geben Sie für jedes Produkt die Menge an.',
		'MZ.bzq.form.title': 'Anfrage absenden',
		'MZ.bzq.form.productselection': 'Ihre Auswahl enthält folgende Produkte',
		'MZ.bzq.form.amount': 'Menge',
		'MZ.bzq.success.title': 'Vielen Dank',
		'MZ.bzq.success.info': 'Ihre Anfage wurde versendet.',
		'MZ.bzq.button': 'Weiter',
		'MZ.bzq.prod.no-produkte': 'Sie haben keine Produkte mehr in der Liste. Um den Prozess neu zu beginnen gehen Sie bitte zurück auf die vorherige Seite.',

		'MZ.bzq.de.weiche.online.label': 'Online-Händler',
		'MZ.bzq.de.weiche.online.intro': 'Mit wenigen Klicks zur Klinke: Unsere Online-Partner verbinden die Vorteile des Einkaufs per Internet mit telefonischer Beratung auf Wunsch. Schneller und bequemer können Sie unsere Produkte nicht in der Hand halten.',
		'MZ.bzq.de.weiche.offline.label': 'Händler vor Ort',
		'MZ.bzq.de.weiche.offline.intro': 'Sie bevorzugen die persönliche Beratung durch einen unserer Partner in Ihrer Umgebung? Recherchieren Sie mit wenigen Mausklicks einen FSB Fachhändler vor Ort.'
	},
	'en': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Back to wishlist',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Back',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Contact details',
		'MZ.button.dealer': 'Where to buy',
		'MZ.button.sendMerkzettel': 'Forward',
		'MZ.button.saveProdukt': 'Add to wishlist',
		'MZ.button.sammeldownload': 'Collective download',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Tell a friend',
		'MZ.recommend.label.recipient': 'Email recipient(s)',
		'MZ.recommend.error.recipient': 'Please enter a valid email address.',
		'MZ.recommend.label.sender': 'Your email',
		'MZ.recommend.error.sender': 'Please enter a valid email address.',
		'MZ.recommend.label.subject': 'Subject',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Please enter a subject.',
		'MZ.recommend.label.your-message': 'Your message',
		'MZ.recommend.value.your-message': 'Hallo,\nI\'ve just discovered this item at FSB which I thought might interest you.',
		'MZ.recommend.error.your-message': 'Please enter a message.',
		'MZ.recommend.error.captcha': 'Please complete the CAPTCHA.',
		'MZ.recommend.button.send': 'Send',
		'MZ.recommend.successmessage': 'Your message has been successfully sent.',
		'MZ.recommend.successbuttonlabel': 'Close',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'My wishlist',
		'MZ.multiview.no-produkte': 'No products entered on this wishlist.',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'To save your wishlist, please LOGIN(login) or REGISTER(register).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contact',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB trade stockists',

		'MZ.bzq.form.your-products': 'Your products',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Door and window hardware',
		'MZ.bzq.cat.ezk': 'Access management',
		'MZ.bzq.cat.sani': 'Barrier-free living',

		'MZ.bzq.srch.title': 'Search for stockist',
		'MZ.bzq.prod.title': 'Products',
		'MZ.bzq.prod.amount.error': 'Please indicate the quantity of each product required.',
		'MZ.bzq.form.title': 'Send enquiry',
		'MZ.bzq.form.productselection': 'Your selection contains the following products',
		'MZ.bzq.form.amount': 'Quantity',
		'MZ.bzq.success.title': 'Thank you',
		'MZ.bzq.success.info': 'Your enquiry has been dispatched.',
		'MZ.bzq.button': 'Continue',
		'MZ.bzq.prod.no-produkte': 'You have removed all the products from your list. To restart the process, please return to the previous page.',

		'MZ.bzq.de.weiche.online.label': 'Online Stockists',
		'MZ.bzq.de.weiche.online.intro': 'Quick-click route to handles: our partners combines the benefits of shopping on the web with sales advice by telephone to order. There’s no speedier, more convenient way of getting hold of our products.',
		'MZ.bzq.de.weiche.offline.label': 'Stockists Locally',
		'MZ.bzq.de.weiche.offline.intro': 'Maybe you prefer face-to-face sales advice from one of our partners in your vicinity. A few clicks of the mouse will take you to your local FSB trade stockist.'
	},
	'en-us': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Back to wishlist',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Back',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Contact details',
		'MZ.button.dealer': 'Where to buy',
		'MZ.button.sendMerkzettel': 'Forward',
		'MZ.button.saveProdukt': 'Add to wishlist',
		'MZ.button.sammeldownload': 'Collective download',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Tell a friend',
		'MZ.recommend.label.recipient': 'Email recipient(s)',
		'MZ.recommend.error.recipient': 'Please enter a valid email address.',
		'MZ.recommend.label.sender': 'Your email',
		'MZ.recommend.error.sender': 'Please enter a valid email address.',
		'MZ.recommend.label.subject': 'Subject',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Please enter a subject.',
		'MZ.recommend.label.your-message': 'Your message',
		'MZ.recommend.value.your-message': 'Hello,\nI\'ve just discovered this item at FSB which I thought might interest you.\n\n\n',
		'MZ.recommend.error.your-message': 'Please enter a message.',
		'MZ.recommend.error.captcha': 'Please complete the CAPTCHA.',
		'MZ.recommend.button.send': 'Send',
		'MZ.recommend.successmessage': 'Your message has been successfully sent.',
		'MZ.recommend.successbuttonlabel': 'Close',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'My wishlist',
		'MZ.multiview.no-produkte': 'No products entered on this wishlist.',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'To save your wishlist, please LOGIN(login) or REGISTER(register).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contact',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB Retailer',

		'MZ.bzq.form.your-products': 'Your products',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Door and window hardware',
		'MZ.bzq.cat.ezk': 'Access management',
		'MZ.bzq.cat.sani': 'Barrier-free living',

		'MZ.bzq.srch.title': 'Search for retailer',
		'MZ.bzq.prod.title': 'Products',
		'MZ.bzq.prod.amount.error': ' Please indicate the quantity of each product required.',
		'MZ.bzq.form.title': 'Send inquiry',
		'MZ.bzq.form.productselection': 'Your selection contains the following products',
		'MZ.bzq.form.amount': 'Quantity',
		'MZ.bzq.success.title': 'Thank you',
		'MZ.bzq.success.info': 'Your inquiry has been sent.',
		'MZ.bzq.button': 'Continue',
		'MZ.bzq.prod.no-produkte': 'You have removed all the products from your list. To restart the process, please return to the previous page.',

		'MZ.bzq.de.weiche.online.label': 'Online Retailers',
		'MZ.bzq.de.weiche.online.intro': 'Quick-click route to handles: our partners combines the benefits of shopping on the web with sales advice by telephone to order. There’s no speedier, more convenient way of getting hold of our products.',
		'MZ.bzq.de.weiche.offline.label': 'Retailers Locally',
		'MZ.bzq.de.weiche.offline.intro': 'Maybe you prefer face-to-face sales advice from one of our partners in your vicinity. A few clicks of the mouse will take you to your local FSB Retailer.'
	},
	'fr': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Retourner au bloc-notes',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Retourner',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Interlocuteur',
		'MZ.button.dealer': 'Points de vente',
		'MZ.button.sendMerkzettel': 'Faire suivre',
		'MZ.button.saveProdukt': 'Ajouter à la liste des favoris',
		'MZ.button.sammeldownload': 'Téléchargement regroupé',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Recommander un(des) article(s)',
		'MZ.recommend.label.recipient': 'Destinataire de l\'e-mail',
		'MZ.recommend.error.recipient': 'Veuillez entrer une adresse e-mail valide',
		'MZ.recommend.label.sender': 'Votre adresse e-mail',
		'MZ.recommend.error.sender': 'Veuillez entrer une adresse e-mail valide',
		'MZ.recommend.label.subject': 'Objet',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Merci d\'entrer un objet.',
		'MZ.recommend.label.your-message': 'Votre message',
		'MZ.recommend.value.your-message': 'Bonjour,\nj\'ai déniché ce(s) article(s) chez FSB et j\'ai pensé à vous/toi.',
		'MZ.recommend.error.your-message': 'Merci d\'entrer un message.',
		'MZ.recommend.error.captcha': 'Merci de remplir le captcha',
		'MZ.recommend.button.send': 'Envoyer',
		'MZ.recommend.successmessage': 'Votre e-mail a  été envoyé avec succès.',
		'MZ.recommend.successbuttonlabel': 'Fermer',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'Mes liste de favoris',
		'MZ.multiview.no-produkte': 'Aucune inscription dans les listes de favoris',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'Pour mémoriser votre bloc-notes de manière permanente, LOGIN(identifiez-vous) ou REGISTER(enregistrez-vous).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contact',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'Revendeurs',

		'MZ.bzq.form.your-products': 'Vos produits',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Garnitures de porte et de fenêtre',
		'MZ.bzq.cat.ezk': 'Gestion électronique d’accès',
		'MZ.bzq.cat.sani': 'Solutions d\'aide à la mobilité',

		'MZ.bzq.srch.title': 'Trouver un point de vente',
		'MZ.bzq.prod.title': 'Produits',
		'MZ.bzq.prod.amount.error': 'Veuillez indiquer la quantité souhaitée pour chaque produit',
		'MZ.bzq.form.title': 'Transmettre la demande',
		'MZ.bzq.form.productselection': 'Votre sélection contient les produits suivants',
		'MZ.bzq.form.amount': 'Quantité',
		'MZ.bzq.success.title': 'Merci',
		'MZ.bzq.success.info': 'Votre demande a été transmise',
		'MZ.bzq.button': 'Continuer',
		'MZ.bzq.prod.no-produkte': 'Vous avez supprimé tous les produits inscrits sur votre liste. Pour lancer le processus une nouvelle fois, veuillez retourner à la page précédente.',

		'MZ.bzq.de.weiche.online.label': 'Commerçant en ligne',
		'MZ.bzq.de.weiche.online.intro': 'Accéder en peu de clics à la béquille de votre choix : notre partenaire tuergriff-shop.de conjugue les avantages de l\'achat via Internet au conseil téléphonique à la demande. Impossible d\'avoir nos produits en main de maniéré plus rapide ni plus commode.',
		'MZ.bzq.de.weiche.offline.label': 'Commerçant sur place',
		'MZ.bzq.de.weiche.offline.intro': 'Vous préférez bénéficier d\'un conseil personnalisé auprès de l\'un de nos partenaires à proximité de chez vous ? Quelques clics vous permettront de trouver sur place un revendeur FSB. '
	},
	'nl': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Terug naar de bladwijzer',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Terug',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Contact',
		'MZ.button.dealer': 'Leveranciers',
		'MZ.button.sendMerkzettel': 'Doorsturen',
		'MZ.button.saveProdukt': 'Op het verlanglijstje plaatsen',
		'MZ.button.sammeldownload': 'Groepsdownload',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Artikel aanbevelen',
		'MZ.recommend.label.recipient': 'E-mail ontvanger',
		'MZ.recommend.error.recipient': 'Het e-mailadres is ongeldig.',
		'MZ.recommend.label.sender': 'Uw e-mail',
		'MZ.recommend.error.sender': 'Het e-mailadres is ongeldig.',
		'MZ.recommend.label.subject': 'Onderwerp',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Vul de betreft-regel in.',
		'MZ.recommend.label.your-message': 'Uw bericht',
		'MZ.recommend.value.your-message': 'Hallo,\nDit artikel/deze artikels heb ik bij FSB ontdekt en daarbij heb ik aan u/jou gedacht.',
		'MZ.recommend.error.your-message': 'Schrijf een bericht.',
		'MZ.recommend.error.captcha': 'Vul de captcha in.',
		'MZ.recommend.button.send': 'Verzenden',
		'MZ.recommend.successmessage': 'Uw e-mail werd met succes verzonden.',
		'MZ.recommend.successbuttonlabel': 'Sluiten',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'Mijn verlanglijstje',
		'MZ.multiview.no-produkte': 'Dit verlanglijstje is leeg',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'Om uw bladwijzer permanent op te slaan, moet u zich LOGINaanmelden) of REGISTERregistreren).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contact',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB-vakhandelaars',

		'MZ.bzq.form.your-products': 'Uw producten',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Deur- en vensterbeslagen',
		'MZ.bzq.cat.ezk': 'Toegangsmanagement',
		'MZ.bzq.cat.sani': 'Barrièrevrij wonen',

		'MZ.bzq.srch.title': 'Handelaar zoeken',
		'MZ.bzq.prod.title': 'Producten',
		'MZ.bzq.prod.amount.error': 'Geef voor elk product de hoeveelheid aan.',
		'MZ.bzq.form.title': 'Aanvraag verzenden',
		'MZ.bzq.form.productselection': 'Uw selectie bevat volgende producten',
		'MZ.bzq.form.amount': 'Hoeveelheid',
		'MZ.bzq.success.title': 'Hartelijk dank',
		'MZ.bzq.success.info': 'Uw aanvraag is verzonden.',
		'MZ.bzq.button': 'Verder',
		'MZ.bzq.prod.no-produkte': 'You have removed all the products from your list. To restart the process, please return to the previous page.',

		'MZ.bzq.de.weiche.online.label': 'Online-handelaar',
		'MZ.bzq.de.weiche.online.intro': 'In slechts enkele klikken naar de klink: onze partner tuergriff-shop.de verbindt de voordelen van de aankoop via het internet met telefonisch advies indien gewenst. Sneller en comfortabeler kunnen onze producten niet in uw hand liggen.',
		'MZ.bzq.de.weiche.offline.label': 'Handelaar ter plaatse',
		'MZ.bzq.de.weiche.offline.intro': 'Verkiest u het persoonlijke advies door een van onze partners in uw buurt? Zoek met enkele muiskliks ter plaatse een FSB-handelaar.'
	},
	'it': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Torna al blocco note',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Indietro',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Contatto per',
		'MZ.button.dealer': 'Fonti di approvvigionamento',
		'MZ.button.sendMerkzettel': 'Inoltrare',
		'MZ.button.saveProdukt': 'Su notepad',
		'MZ.button.sammeldownload': 'Download collettivo',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Consiglia questo articolo ad un amico',
		'MZ.recommend.label.recipient': 'Destinatario e-mail',
		'MZ.recommend.error.recipient': 'Inserisci un indirizzo e-mail valido.',
		'MZ.recommend.label.sender': 'Il tuo indirizzo e-mail',
		'MZ.recommend.error.sender': 'Inserisci un indirizzo e-mail valido.',
		'MZ.recommend.label.subject': 'Soggetto',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Per favore, inserisci un oggetto.',
		'MZ.recommend.label.your-message': 'Il tuo messaggio',
		'MZ.recommend.value.your-message': 'Salve, questo(i) articolo(i) che ho trovato su FSB e stava pensando a te.',
		'MZ.recommend.error.your-message': 'Inserisci un messaggio.',
		'MZ.recommend.error.captcha': 'Per favore, compila il captcha.',
		'MZ.recommend.button.send': 'Dispaccio',
		'MZ.recommend.successmessage': 'La tua email è stata inviata con successo.',
		'MZ.recommend.successbuttonlabel': 'Chiudere',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'Il mio blocco note',
		'MZ.multiview.no-produkte': 'Nessuna voce disponibile',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'Vuoi salvare il tuo blocco note in modo permanente? Poi effettua il login con il tuo LOGIN (dati di accesso). Se non si dispone ancora di un accesso personale, è necessario registrarsi come REGISTRATORE.',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contattateci',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'Rivenditore specializzato FSB',

		'MZ.bzq.form.your-products': 'I tuoi prodotti',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Ferramenta per porte e finestre',
		'MZ.bzq.cat.ezk': 'Gestione degli accessi',
		'MZ.bzq.cat.sani': 'Vivere senza barriere architettoniche',

		'MZ.bzq.srch.title': 'Trova un rivenditore',
		'MZ.bzq.prod.title': 'Prodotti',
		'MZ.bzq.prod.amount.error': 'Si prega di indicare la quantità per ogni prodotto.',
		'MZ.bzq.form.title': 'Inviare la richiesta',
		'MZ.bzq.form.productselection': 'La tua selezione contiene i seguenti prodotti',
		'MZ.bzq.form.amount': 'Quantitativo',
		'MZ.bzq.success.title': 'Grazie mille.',
		'MZ.bzq.success.info': 'La vostra richiesta è stata inviata.',
		'MZ.bzq.button': 'Di più',
		'MZ.bzq.prod.no-produkte': 'Non ci sono altri prodotti nella lista. Per riavviare il processo, tornare alla pagina precedente.',

		'MZ.bzq.de.weiche.online.label': 'Commerciante online',
		'MZ.bzq.de.weiche.online.intro': 'Con pochi clic alla clinica: i nostri partner online combinano i vantaggi degli acquisti via Internet con la consulenza telefonica su richiesta. Non puoi tenere i nostri prodotti in mano più velocemente e comodamente.',
		'MZ.bzq.de.weiche.offline.label': 'Rivenditore locale',
		'MZ.bzq.de.weiche.offline.intro': 'Preferisci la consulenza personale di uno dei nostri partner della tua zona? Cercate un rivenditore specializzato FSB sul posto con pochi clic del mouse.'
	},
	'es': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Volver al bloc de notas',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Espalda',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Contacto para',
		'MZ.button.dealer': 'Fuentes de aprovisionamiento',
		'MZ.button.sendMerkzettel': 'Adelante',
		'MZ.button.saveProdukt': 'En el bloc de notas',
		'MZ.button.sammeldownload': 'Descarga colectiva',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'Recomendar este artículo a un amigo',
		'MZ.recommend.label.recipient': 'Destinatario de correo electrónico',
		'MZ.recommend.error.recipient': 'Por favor, introduzca una dirección de correo electrónico válida.',
		'MZ.recommend.label.sender': 'Su E-Mail',
		'MZ.recommend.error.sender': 'Por favor, introduzca una dirección de correo electrónico válida.',
		'MZ.recommend.label.subject': 'Súbdito',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Por favor, introduzca un asunto.',
		'MZ.recommend.label.your-message': 'Su mensaje',
		'MZ.recommend.value.your-message': 'Hola, este artículo(s) lo encontré en FSB y estaba pensando en ti.',
		'MZ.recommend.error.your-message': 'Por favor, introduzca un mensaje.',
		'MZ.recommend.error.captcha': 'Por favor, rellene el captcha.',
		'MZ.recommend.button.send': 'Despacho',
		'MZ.recommend.successmessage': 'Su correo electrónico ha sido enviado correctamente.',
		'MZ.recommend.successbuttonlabel': 'Cerrar',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'Mi cuaderno de notas',
		'MZ.multiview.no-produkte': 'No hay entradas disponibles',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': '¿Desea guardar su bloc de notas de forma permanente? A continuación, inicie sesión con su LOGIN (datos de acceso). Si aún no tiene acceso personal, primero debe registrarse como REGISTRO.',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'Contáctenos',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'Distribuidor especializado de FSB',

		'MZ.bzq.form.your-products': 'Sus productos',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Herrajes para puertas y ventanas',
		'MZ.bzq.cat.ezk': 'Gestión de acceso',
		'MZ.bzq.cat.sani': 'Vida sin barreras',

		'MZ.bzq.srch.title': 'Buscar un distribuidor',
		'MZ.bzq.prod.title': 'Productos',
		'MZ.bzq.prod.amount.error': 'Por favor, indique la cantidad de cada producto.',
		'MZ.bzq.form.title': 'Enviar consulta',
		'MZ.bzq.form.productselection': 'Su selección contiene los siguientes productos',
		'MZ.bzq.form.amount': 'Cuantía',
		'MZ.bzq.success.title': 'Muchísimas gracias.',
		'MZ.bzq.success.info': 'Su solicitud ha sido enviada.',
		'MZ.bzq.button': 'Más',
		'MZ.bzq.prod.no-produkte': 'No hay más productos en la lista. Para reiniciar el proceso, vuelva a la página anterior.',

		'MZ.bzq.de.weiche.online.label': 'Comerciante online',
		'MZ.bzq.de.weiche.online.intro': 'Con sólo unos pocos clics a la clínica: Nuestros socios en línea combinan las ventajas de comprar por Internet con el asesoramiento telefónico a petición. No puede tener nuestros productos en sus manos más rápido o más cómodamente.',
		'MZ.bzq.de.weiche.offline.label': 'Concesionario local',
		'MZ.bzq.de.weiche.offline.intro': '¿Prefiere el asesoramiento personal de uno de nuestros socios en su zona? Busque un distribuidor especializado de FSB in situ con sólo unos pocos clics del ratón.'
	},
	'ru': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': 'Back to wishlist',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': 'Back',

		// Buttonbezeichnungen
		'MZ.button.contact': 'Представитель',
		'MZ.button.dealer': 'ценовой запрос',
		'MZ.button.sendMerkzettel': 'дальше отправлять',
		'MZ.button.saveProdukt': 'на списке пожеланий',
		'MZ.button.sammeldownload': 'сборная загрузка',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': 'продукт далее советовать',
		'MZ.recommend.label.recipient': 'E-Mail получатель',
		'MZ.recommend.error.recipient': 'Пожалуйста, дайте действительный электронный адрес.',
		'MZ.recommend.label.sender': 'Ваша E-Mail',
		'MZ.recommend.error.sender': 'Пожалуйста, дайте действительный электронный адрес.',
		'MZ.recommend.label.subject': 'предмет',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': 'Please enter a subject.',
		'MZ.recommend.label.your-message': 'Ваше сообщение',
		'MZ.recommend.value.your-message': 'Здравствуйте,\nя нашел этот продукт у FSB и подумал при этом о Вас/ тебе.',
		'MZ.recommend.error.your-message': 'Please enter a message.',
		'MZ.recommend.error.captcha': 'Please complete the CAPTCHA.',
		'MZ.recommend.button.send': 'отправлять',
		'MZ.recommend.successmessage': 'Ваша сообщение отправлено.',
		'MZ.recommend.successbuttonlabel': 'Close',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': 'мой список пожеланий',
		'MZ.multiview.no-produkte': 'никаких записей',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': 'To save your wishlist, please LOGIN(login) or REGISTER(register).',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': 'контакт',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB поставщик',

		'MZ.bzq.form.your-products': 'Your products',
		// Produktbereiche
		'MZ.bzq.cat.besch': 'Фурнитура для дверей и окон',
		'MZ.bzq.cat.ezk': 'Электронное управление доступом',
		'MZ.bzq.cat.sani': 'Комфортная жизнь бес барьеров',

		'MZ.bzq.srch.title': 'поставщик находить',
		'MZ.bzq.prod.title': 'Продукция',
		'MZ.bzq.prod.amount.error': 'Пожалуйста, укажите для каждого продукта количество.',
		'MZ.bzq.form.title': 'запрос отправлять',
		'MZ.bzq.form.productselection': 'Ваш выбор содержит следующие продукты',
		'MZ.bzq.form.amount': 'количество',
		'MZ.bzq.success.title': 'спасибо',
		'MZ.bzq.success.info': 'Ваш запрос отправлен.',
		'MZ.bzq.button': 'дальше',
		'MZ.bzq.prod.no-produkte': 'У Вас больше нет продуктов в списке. Пожалуйста, идите назад на предыдущую сторону, чтобы начинать процесс по-новому.',

		'MZ.bzq.de.weiche.online.label': 'Online Stockists',
		'MZ.bzq.de.weiche.online.intro': 'Quick-click route to handles: our partners tuergriff-shop.de combine the benefits of shopping on the web with sales advice by telephone to order. There’s no speedier, more convenient way of getting hold of our products.',
		'MZ.bzq.de.weiche.offline.label': 'Stockists Locally',
		'MZ.bzq.de.weiche.offline.intro': 'Maybe you prefer face-to-face sales advice from one of our partners in your vicinity. A few clicks of the mouse will take you to your local FSB trade stockist.'
	},
	'zh': {
		// Zurück-Link von Anspreachpartner- Bezugsquellensuche zu Merkzettel
		'MZ.button.backToMZ': '返回收藏单',
		// Zurück-Button im Bezugsquellen-Formular
		'MZ.bzq.button.back': '返回',

		// Buttonbezeichnungen
		'MZ.button.contact': '联络人',
		'MZ.button.dealer': '哪里购买',
		'MZ.button.sendMerkzettel': '转发',
		'MZ.button.saveProdukt': '添加到收藏单',
		'MZ.button.sammeldownload': '集体下载',

		// Merkzettel empfehlen (Formular)
		'MZ.recommend.title': '推荐此款产品',
		'MZ.recommend.label.recipient': '邮件收件人',
		'MZ.recommend.error.recipient': '请输入一个有效的电子邮件地址。',
		'MZ.recommend.label.sender': '您的邮箱',
		'MZ.recommend.error.sender': '请输入一个有效的电子邮件地址。',
		'MZ.recommend.label.subject': '主题',
		'MZ.recommend.subject': 'FSB',
		'MZ.recommend.error.subject': '请输入一个主题。',
		'MZ.recommend.label.your-message': '您的留言',
		'MZ.recommend.value.your-message': '您好，\n文章是我在FSB上发现并想与您分享。',
		'MZ.recommend.error.your-message': '请输入信息。',
		'MZ.recommend.error.captcha': '请填写验证码。',
		'MZ.recommend.button.send': '发送',
		'MZ.recommend.successmessage': '您的电子邮件已成功发送。',
		'MZ.recommend.successbuttonlabel': '关闭',

		// Seite: Mein Merkzettel
		'MZ.multiview.title': '我的收藏夹',
		'MZ.multiview.no-produkte': '收藏夹没有产品',
		// Hinweistext: LOGIN(text) für Link zur Login-Formular; REGISTER(text) für Link zur Registrierung
		'MZ.multiview.savehint': '您要永久保存收藏夹吗？ 那请使用您的登录名（访问数据）登录。 如果您尚无访问权限，则必须先注册。',

		// Überschrift Ansprachpartnersuche
		'MZ.asp.title': '联系方式',
		// Überschrift Bezugsquellensuche
		'MZ.bzq.title': 'FSB 经销商',

		'MZ.bzq.form.your-products': '您的产品',
		// Produktbereiche
		'MZ.bzq.cat.besch': '门窗五金',
		'MZ.bzq.cat.ezk': '门禁管理',
		'MZ.bzq.cat.sani': '无障碍生活',

		'MZ.bzq.srch.title': '寻找经销商',
		'MZ.bzq.prod.title': '产品',
		'MZ.bzq.prod.amount.error': '请输入每种产品的数量。',
		'MZ.bzq.form.title': '发送请求',
		'MZ.bzq.form.productselection': '您的选择包含以下产品',
		'MZ.bzq.form.amount': '数量',
		'MZ.bzq.success.title': '非常感谢',
		'MZ.bzq.success.info': '您的请求已发送。',
		'MZ.bzq.button': '继续',
		'MZ.bzq.prod.no-produkte': '您列表中没有更多产品。 想要重新启动，请返回上一页。',

		'MZ.bzq.de.weiche.online.label': '在线经销商',
		'MZ.bzq.de.weiche.online.intro': '快速购买方式：我们的网络合作伙伴通过在线订购结合电话咨询为您服务。 没有比这更快，更便捷的方式来获取我们的产品。',
		'MZ.bzq.de.weiche.offline.label': '本地经销商',
		'MZ.bzq.de.weiche.offline.intro': '想要获取专业人士的咨询？您只需单击几下鼠标，即可搜索您所在地FSB的经销商。'
	}
});