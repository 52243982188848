'use strict';

import ReactDOM from 'react-dom';
import SearchActions from '@search/actions/SearchActions';
import SearchConstants from '@search/constants/SearchConstants';
import getURLParameter from '@lib/urlParameter';

import '@search/constants/Locale.js';

import SiteSearch from '@search/components/SiteSearch.jsx';
import PageNotFoundSearch from '@search/components/PageNotFoundSearch.jsx';

let targetElement = document.getElementById('pagesearch');
if (targetElement) {
	SearchActions._init(SearchConstants.MODE_PAGE, CMJSContext.page.properties.displayMode, CMJSContext.page.properties.ajaxBase, CMJSContext.page.properties.searchURL, CMJSContext.page.properties.categories, CMJSContext.page.properties.responsive);

	let query = getURLParameter('query');
	if (query) {
		SearchActions.query(query);
	}

	let facet = getURLParameter('facet');
	if (facet) {
		SearchActions.filter(facet);
	}
	ReactDOM.render(<SiteSearch />, targetElement);
}
else {
	let notFoundElement = document.getElementById('pagenotfoundsearch');

	if (notFoundElement) {
		ReactDOM.render(<PageNotFoundSearch />, notFoundElement);
	}
}

let windowResized;
let windowResizedCallback = function() {
	let responsiveDisplayMode = 'standard';
	if (window.innerWidth < 768) {
		responsiveDisplayMode = 'mobile';
	}
	SearchActions.updateDisplayMode(responsiveDisplayMode);
};
jQuery(window).on('resize orientationChanged', function(){
	clearTimeout(windowResized);
	windowResized = setTimeout(windowResizedCallback, 100);
});
