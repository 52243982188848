'use strict';
/*global loadRecaptcha, grecaptcha*/

const MerkzettelMailing = function () {
	this.debug = true;

	this.overlay = null;

	this.produkt = null;

	this.grecaptchaId = -1;
};

/**
 * Send a whole merkzettel!
 * @param {Merkzettel} merkzettel
 */
MerkzettelMailing.prototype.sendMerkzettel = function() {
	this.createOverlay();
};

/**
 * Send a single produkt
 * @param {Produkt} produkt
 */
MerkzettelMailing.prototype.sendProdukt = function(produkt) {
	this.produkt = produkt;
	if (produkt) {
		this.createOverlay();
	}
};

MerkzettelMailing.prototype.createOverlay = function() {
	var self = this;

	if ($j('#mzmmodal').length > 0) {
		$j('#mzmmodal').remove();
	}

	var overlayHtml = '\
	<div id="mzmmodal" class="modal" role="dialog"><div class="modal-dialog" role="document">\
		<div class="modal-content">\
			<div class="panel">\
				<div class="modal-header">\
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\
					<h4 class="modal-title">' + CMJSContext.L('MZ.recommend.title') + '</h4>\
				</div>\
				<div class="modal-body">\
					<div id="mzmmodal-form" class="form-horizontal">\
						<div class="of-content">\
							<div class="of-elementlist">\
								<div class="form-group of-textfield">\
									<label for="mzmmodal-recipient" class="col-sm-4 control-label">' + CMJSContext.L('MZ.recommend.label.recipient') + ' <span class="oblig">*</span></label>\
									<div class="col-sm-8">\
										<input id="mzmmodal-recipient" type="text" name="mzmmodal-recipient" class="form-control" value="">\
										<div id="mzmmodal-recipient-error" class="alert alert-danger" role="alert" style="display: none">' + CMJSContext.L('MZ.recommend.error.recipient') + '</div>\
									</div>\
								</div>';
	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		overlayHtml += '		<div class="form-group of-textfield">\
									<label for="mzmmodal-sender" class="col-sm-4 control-label">' + CMJSContext.L('MZ.recommend.label.sender') + ' <span class="oblig">*</span></label>\
									<div class="col-sm-8">\
										<input id="mzmmodal-sender" type="text" name="mzmmodal-sender" class="form-control" value="">\
										<div id="mzmmodal-sender-error" class="alert alert-danger" role="alert" style="display: none">' + CMJSContext.L('MZ.recommend.error.sender') + '</div>\
									</div>\
								</div>';
	}

	var subject = '';
	if (self.produkt) {
		subject = CMJSContext.L('MZ.recommend.subject') + ' ' + self.produkt.name;
	}
	else {
		subject = CMJSContext.L('MZ.recommend.subject')
	}
	overlayHtml += '			<div class="form-group of-textfield">\
									<label for="mzmmodal-subject" class="col-sm-4 control-label">' + CMJSContext.L('MZ.recommend.label.subject') + ' <span class="oblig">*</span></label>\
									<div class="col-sm-8">\
										<input id="mzmmodal-subject" type="text" name="mzmmodal-subject" class="form-control" value="' + subject + '">\
										<div id="mzmmodal-subject-error" class="alert alert-danger" role="alert" style="display: none">' + CMJSContext.L('MZ.recommend.error.subject') + '</div>\
									</div>\
								</div>\
								<div class="form-group of-textfield">\
									<label for="mzmmodal-message" class="col-sm-4 control-label">' + CMJSContext.L('MZ.recommend.label.your-message') + ' <span class="oblig">*</span></label>\
									<div class="col-sm-8">\
										<textarea id="mzmmodal-message" class="form-control" name="mzmmodal-message" rows="5">' + CMJSContext.L('MZ.recommend.value.your-message') + '</textarea>\
										<div id="mzmmodal-message-error" class="alert alert-danger" role="alert" style="display: none">' + CMJSContext.L('MZ.recommend.error.your-message') + '</div>\
									</div>\
								</div>';
	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		overlayHtml += '		<div class="form-group of-captcha">\
									<div class="col-sm-4"></div>\
									<div class="col-sm-8">\
										<div id="mzmmodal-grecaptcha"></div>\
										<div id="mzmmodal-grecaptcha-error" class="alert alert-danger" style="display: none;" role="alert" style="display: none">' + CMJSContext.L('MZ.recommend.error.captcha') + '</div>\
									</div>\
								</div>';
	}
	overlayHtml += '		</div>\
						</div>\
					</div>\
					<div id="mzmmodal-success" style="display: none;">' + CMJSContext.L('MZ.recommend.successmessage') + '</div>\
				</div>\
				<div class="modal-footer of-panelactions">\
					<button id="mzmmodal-submit" class="of-panelactions-right btn btn-primary of-nextaction" type="submit" value="Ja" name="elem3570_whoami_button_submit">' + CMJSContext.L('MZ.recommend.button.send') + '</button>\
				</div>\
			</div>\
		</div> \
	</div></div>';

	self.overlay = $j(overlayHtml).appendTo($j('body'));

	$j('#mzmmodal-submit', self.overlay).on('click', function(){
		self.onFormSubmit();
		return false;
	});

	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		loadRecaptcha().done(function(){
			self.grecaptchaId = grecaptcha.render(
				'mzmmodal-grecaptcha',
				{
					sitekey: '6LcRXUkUAAAAAAtGX5wzfIGDZW3bQgjzEkbwFZwj' // fsb-service@salient.de
				}
			);
		});
	}


	$j(self.overlay).modal();
};

MerkzettelMailing.prototype.onFormSubmit = function() {
	var self = this;

	$j('.alert', self.overlay).hide();

	var hasValidationErrors = false;
	var recieverEmail = $j('#mzmmodal-recipient', self.overlay).val();
	if (!self.isEmailValid(recieverEmail)) {
		$j('#mzmmodal-recipient-error', self.overlay).show();
		hasValidationErrors = true;
	}

	var senderEmail = 'notUsed';
	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		senderEmail = $j('#mzmmodal-sender', self.overlay).val();
		if (senderEmail) {
			if (!self.isEmailValid(senderEmail)) {
				$j('#mzmmodal-sender-error', self.overlay).show();
				hasValidationErrors = true;
			}
		}
		else {
			$j('#mzmmodal-sender-error', self.overlay).show();
			hasValidationErrors = true;
		}
	}

	var subject = $j('#mzmmodal-subject', self.overlay).val();
	if (subject.length === 0) {
		$j('#mzmmodal-subject-error', self.overlay).show();
		hasValidationErrors = true;
	}
	var message = $j('#mzmmodal-message', self.overlay).val();
	if (message.length === 0) {
		$j('#mzmmodal-message-error', self.overlay).show();
		hasValidationErrors = true;
	}

	var captchaResponse = 'notUsed';
	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		captchaResponse = grecaptcha.getResponse(this.grecaptchaId);
		if (captchaResponse.length === 0) {
			$j('#mzmmodal-grecaptcha-error', self.overlay).show();
			hasValidationErrors = true;
		}
	}

	if (!recieverEmail || !senderEmail || !subject || !message) {
		$j('#recommend-error', self.overlay).show();
		hasValidationErrors = true;
	}

	if (hasValidationErrors) {
		return false;
	}

	$j('#mzmmodal-form', self.overlay).hide();
	$j('#mzmmodal-success', self.overlay).show();
	$j('#mzmmodal-submit').text(CMJSContext.L('MZ.recommend.successbuttonlabel')).off('click').on('click', function(){
		self.overlay.modal('toggle');
	});

	var data = {
		module			: 'Merkzettel/AjaxMailActions',
		languageCode	: CMJSContext.page.properties.languageCode,
		recipient		: recieverEmail,
		subject			: subject,
		message			: message,
		merkzettelID	: window.merkzettelDaten.merkzettel.merkzettelID
	};

	if (self.produkt) {
		data['url']			= self.produkt.url;
		data['mediaUrl']	= self.produkt.mediaUrl;
		data['name']		= self.produkt.name;
		data['description']	= self.produkt.description;
		data['references']	= self.produkt.references;
	}

	if (window.authentication.state !== window.authentication.AUTHENTICATED) {
		data['sender'] = senderEmail;
		data['captcha'] = captchaResponse;
	}

	$j.ajax({
		type: 'POST',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: data
	});
};

MerkzettelMailing.prototype.isEmailValid = function(email) {
	var self = this;
	var isValid = false;

	if (email) {
		if (email.indexOf(',') !== -1) {
			var emailarray = email.split(',');
			isValid = true;
			$j.each(emailarray, function(i,e){
				isValid = isValid && self.isEmailValid(e);
			});
		}
		else {
			isValid =
					email.indexOf('@') !== -1
				&&	email.indexOf('@') == email.lastIndexOf('@')
				&&	email.lastIndexOf(".") >= email.length - 4;
		}
	}

	return isValid;
};

export default MerkzettelMailing;
