'use strict';

CMJSContext.LL({

	'de': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Zurück',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Merken',
		'aartikel.to_product': 'Zum Produkt',
		'aartikel.i_of_n': ' von ',

		// Select-Felder
		'select.please_choose': 'Bitte wählen...',
		'select.no_match': 'Keine Treffer',

		// Groups – Gruppen aus CRM
		'endkunde': 'Endkunde:in',
		'architekt_planer': 'Architekt:in / TGA Fachplaner:in',
		'investor': 'Investor:in',
		'fachhaendler': 'Fachhändler:in',
		'verarbeiter_handwerker': 'Verarbeiter:in / Handwerker:in',

		// Old Groups and Subgroups
		'architekt': 'Architekt:in',
		'generalunternehmer': 'Generalunternehmer:in',
		'bauherr_bautraeger_betreiber': 'Bauherr:in / Bauträger:in / Betreiber:in',
		'facilitymanager': 'Facilitymanager:in',
		'industriekunde': 'Industriekunde:in',
		'journalist': 'Journalist:in',
		'tga_fachplaner': 'TGA Fachplaner:in',
		'sonstiges': 'Sonstiges',
		'baubeschlag': 'Baubeschlag',
		'elektronik': 'Elektronik',
		'sicherheitstechnik': 'Sicherheitstechnik',
		'elektrotechnik': 'Elektrotechnik',
		'sanitaertechnik': 'Sanitärtechnik',
		'anlagengruppenuebergreifend': 'Anlagengruppenübergreifend',
		'aussentueren': 'Aussentüren',
		'innentueren': 'Innentüren',
		'fenster': 'Fenster',
		'fassade': 'Fassade',
		'sanitaer': 'Sanitär',
		'gewerkeuebergreifend': 'Gewerkeübergreifend',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Beschläge',
		'asp_berater_elektronik': 'Elektronisches Zutrittsmanagement',
		'asp_keyaccount_elektronik': 'KeyAccount Elektronik',
		'asp_berater_sanitaer': 'Sanitär',
		'asp_zentr_inland': 'Vertrieb National',
		'asp_zentr_export': 'Vertrieb International',
		'asp_zentr_elektronik': 'Vertrieb Elektronisches Zutrittsmanagement',
		'asp_zentr_sanitaer': 'Vertrieb Sanitäre Ausstattungen ',
		'asp_zentr_pm_beschlag': 'Produktmanagement',
		'asp_zentr_pm_elektronik': 'Produktmanagement EZM',
		'asp_zentr_pm_sanitaer': 'Produktmanagement Sanitär',
		'asp_zentr_architektur': 'Architekturservice',
		'asp_zentr_marketing': 'Marketing + PR',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Personal',
		'asp_zentr_einkauf': 'Einkauf',
		'asp_zentr_edv': 'EDV',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Servicehotlines',
		'asp_category_obj_archi': 'Objektberatung Architektur',
		'asp_category_betreuung': 'Fachhandelsbetreuung',
		'asp_category_investorenberatung': 'Investorenberatung',
		'asp_category_gebietsverkaufsleiter': 'Gebietsverkaufsleitung',
		'asp_category_innendienst': 'Vertriebsinnendienst',
		'asp_category_zentral': 'Zentrale Ansprechpartner',
		'asp_category_innendienst2022': 'Innendienst',
		'asp_category_aussendienst2022': 'Außendienst',
		'asp_category_contact2022': 'Kontakt',

		// Cookie Hinweis
		'cookie_hint_headline': 'Verwendung von Cookies',
		'cookie_hint': 'Um die Webseite optimal gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen erhalten Sie in den Cookie-Hinweisen',

		// Digitaler Katalog
		'dk_patience_please': 'Bitte haben Sie einen Moment Geduld.'
	},
	'en': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Back',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Add to wishlist',
		'aartikel.to_product': 'To the product',
		'aartikel.i_of_n': 'from',

		// Select-Felder
		'select.please_choose': 'Please select…',
		'select.no_match': 'No results',

		// Groups – Gruppen aus CRM
		'endkunde': 'End-customer',
		'architekt_planer': 'Architect / Planner',
		'investor': 'Investor',
		'fachhaendler': 'Trade stockist',
		'verarbeiter_handwerker': 'Craftsman',

		// Old Groups and Subgroups
		'architekt': 'Architect',
		'generalunternehmer': 'General contractor',
		'bauherr_bautraeger_betreiber': 'Building owner / Building contractor / Operator',
		'facilitymanager': 'Facility manager',
		'industriekunde': 'Industry customer',
		'journalist': 'Journalist',
		'tga_fachplaner': 'Technical building equipment planner',
		'sonstiges': 'Other',
		'baubeschlag': 'Door and window hardware',
		'elektronik': 'Electronics',
		'sicherheitstechnik': 'Security systems',
		'elektrotechnik': 'Electrical engineering',
		'sanitaertechnik': 'Sanitary systems',
		'anlagengruppenuebergreifend': 'Cross-facility-group',
		'aussentueren': 'External doors',
		'innentueren': 'Internal doors',
		'fenster': 'Windows',
		'fassade': 'Facades',
		'sanitaer': 'Sanitary',
		'gewerkeuebergreifend': 'Crott-trade',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Hardware',
		'asp_berater_elektronik': 'Electronic Access Management ',
		'asp_keyaccount_elektronik': 'KeyAccount Electronic',
		'asp_berater_sanitaer': 'Sanitary',
		'asp_zentr_inland': 'Domestic Sales',
		'asp_zentr_export': 'International Sales',
		'asp_zentr_elektronik': 'Key account electronics',
		'asp_zentr_sanitaer': 'Key account barrier-free products',
		'asp_zentr_pm_beschlag': 'Product Management Hardware',
		'asp_zentr_pm_elektronik': 'Product Management EZM',
		'asp_zentr_pm_sanitaer': 'Product Management Sanitary',
		'asp_zentr_architektur': 'Key Account Architecture',
		'asp_zentr_marketing': 'Marketing + Public Relations',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Human Resources',
		'asp_zentr_einkauf': 'Purchasing',
		'asp_zentr_edv': 'IT Department',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Service hotline',
		'asp_category_obj_archi': 'Architectural Service',
		'asp_category_betreuung': 'Specialized Trade Support',
		'asp_category_investorenberatung': 'Investment consultant',
		'asp_category_gebietsverkaufsleiter': 'Areas Sales Management',
		'asp_category_innendienst': 'Internal Sales',
		'asp_category_zentral': 'Central Contact person',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': 'Contact',

		// Cookie Hinweis
		'cookie_hint_headline': 'Use of cookies',
		'cookie_hint': 'We use cookies in order to optimally design and continuously improve our website. If you continue using the website you agree to the usage of cookies. Further information can be withdrawn from the cookie notice',

		// Digitaler Katalog
		'dk_patience_please': 'Please be patient.'
	},
	'en-us': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Back',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Add to wishlist',
		'aartikel.to_product': 'Product Image Creator',
		'aartikel.i_of_n': 'from',

		// Select-Felder
		'select.please_choose': 'Please select…',
		'select.no_match': 'No results',

		// Groups – Gruppen aus CRM
		'endkunde': 'End-customer',
		'architekt_planer': 'Architect / Planner',
		'investor': 'Investor',
		'fachhaendler': 'Retailer',
		'verarbeiter_handwerker': 'Craftsman',

		// Old Groups and Subgroups
		'architekt': 'Architect',
		'generalunternehmer': 'General contractor',
		'bauherr_bautraeger_betreiber': 'Building owner / Building contractor / Operator',
		'facilitymanager': 'Facility manager',
		'industriekunde': 'Industry customer',
		'journalist': 'Journalist',
		'tga_fachplaner': 'Technical building equipment planner',
		'sonstiges': 'Other',
		'baubeschlag': 'Door and window hardware',
		'elektronik': 'Electronics',
		'sicherheitstechnik': 'Security systems',
		'elektrotechnik': 'Electrical engineering',
		'sanitaertechnik': 'Sanitary systems',
		'anlagengruppenuebergreifend': 'Cross-facility-group',
		'aussentueren': 'External doors',
		'innentueren': 'Internal doors',
		'fenster': 'Windows',
		'fassade': 'Facades',
		'sanitaer': 'Sanitary',
		'gewerkeuebergreifend': 'Cross trades',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Hardware',
		'asp_berater_elektronik': 'Electronic Access Management ',
		'asp_keyaccount_elektronik': 'KeyAccount Electronic',
		'asp_berater_sanitaer': 'Sanitary',
		'asp_zentr_inland': 'Domestic Sales',
		'asp_zentr_export': 'International Sales',
		'asp_zentr_elektronik': 'Key account electronics',
		'asp_zentr_sanitaer': 'Key account barrier-free products',
		'asp_zentr_pm_beschlag': 'Sales / Customer Service',
		'asp_zentr_pm_elektronik': 'Product Management EZM',
		'asp_zentr_pm_sanitaer': 'Product Management Sanitary',
		'asp_zentr_architektur': 'Technical',
		'asp_zentr_marketing': 'Marketing',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Human Resources',
		'asp_zentr_einkauf': 'Purchasing',
		'asp_zentr_edv': 'IT Department',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Service hotline',
		'asp_category_obj_archi': 'Architectural Service',
		'asp_category_betreuung': 'Sales Representative',
		'asp_category_investorenberatung': 'Investment consultant',
		'asp_category_gebietsverkaufsleiter': 'Areas Sales Management',
		'asp_category_innendienst': 'Internal Sales',
		'asp_category_zentral': 'FSB North America Office Contacts',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': 'Contact',

		// Cookie Hinweis
		'cookie_hint_headline': 'Use of cookies',
		'cookie_hint': 'We use cookies in order to optimally design and continuously improve our website. If you continue using the website you agree to the usage of cookies. Further information can be withdrawn from the cookie notice',

		// Digitaler Katalog
		'dk_patience_please': 'Please be patient.'
	},
	'fr': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Arrière',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Retenir',
		'aartikel.to_product': 'Vers le produit',
		'aartikel.i_of_n': 'de',

		// Select-Felder
		'select.please_choose': 'Merci de sélectionner',
		'select.no_match': 'Aucun résultat',

		// Groups – Gruppen aus CRM
		'endkunde': 'Client final',
		'architekt_planer': 'Architecte / Planificateur',
		'investor': 'Investisseur',
		'fachhaendler': 'Revendeur',
		'verarbeiter_handwerker': 'Exécutants / artisans',

		// Old Groups and Subgroups
		'architekt': 'Architecte',
		'generalunternehmer': 'Promoteur',
		'bauherr_bautraeger_betreiber': 'Maître d\'ouvrage / Entrepreneur en bâtiment / Exploitant',
		'facilitymanager': 'Gestionnaire de la technique de bâtiments',
		'industriekunde': 'Client industriel',
		'journalist': 'Journaliste',
		'tga_fachplaner': 'Planificateur en équipement technique du bâtiment',
		'sonstiges': 'Autres',
		'baubeschlag': 'Garnitures de bâtiments',
		'elektronik': 'Électronique',
		'sicherheitstechnik': 'Technique de sécurité',
		'elektrotechnik': 'Électrotechnique',
		'sanitaertechnik': 'Technique sanitaire',
		'anlagengruppenuebergreifend': 'Approche transversale du groupe d\'équipements',
		'aussentueren': 'Portes extérieures',
		'innentueren': 'Portes intérieures',
		'fenster': 'Fenêtres',
		'fassade': 'Façade',
		'sanitaer': 'Sanitaire',
		'gewerkeuebergreifend': 'Activité transversale aux différents corps de métiers',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Garnitures',
		'asp_berater_elektronik': 'Gestion électronique d’accès',
		'asp_keyaccount_elektronik': 'KeyAccount Électronique',
		'asp_berater_sanitaer': 'Sanitaires',
		'asp_zentr_inland': 'Service commercial Allemagne',
		'asp_zentr_export': 'Service export',
		'asp_zentr_elektronik': 'Responsable électronique',
		'asp_zentr_sanitaer': 'Responsable sanitaire',
		'asp_zentr_pm_beschlag': 'La gestion des produits garnitures',
		'asp_zentr_pm_elektronik': 'La gestion des produits EZM',
		'asp_zentr_pm_sanitaer': 'La gestion des produits sanitaires',
		'asp_zentr_architektur': 'Responsable Architecture',
		'asp_zentr_marketing': 'Marketing + Relations publiques',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Ressources humaines',
		'asp_zentr_einkauf': 'Achats',
		'asp_zentr_edv': 'Informatique',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Service d\'assistance',
		'asp_category_obj_archi': 'Conseil pour collectivités architecture',
		'asp_category_betreuung': 'Support de commerce spécialisé',
		'asp_category_investorenberatung': 'Conseil en investissement',
		'asp_category_gebietsverkaufsleiter': 'Direction de secteur ',
		'asp_category_innendienst': 'Ventes internes ',
		'asp_category_zentral': 'Interlocutuers',
		'asp_category_innendienst2022': 'Service clientèle',
		'asp_category_aussendienst2022': 'Commerciaux',
		'asp_category_contact2022': 'Contact',

		// Cookie Hinweis
		'cookie_hint_headline': 'utilisation de cookies',
		'cookie_hint': 'Lors de la consultation de note site, des informations relatives à la navigation de votre terminal sur notre site sont susceptibles d\'être enregistrées dans des fichiers "Cookies" installés sur votre terminal, sous réserve des choix que vous auriez exprimés concernant les Cookies et que vous pouvez modifier à tout moment',

		// Digitaler Katalog
		'dk_patience_please': 'Merci de patienter un instant.'
	},
	'nl': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Terug',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Onthouden',
		'aartikel.to_product': 'Naar het product',
		'aartikel.i_of_n': 'van',

		// Select-Felder
		'select.please_choose': 'Maak uw keuze…',
		'select.no_match': 'Maak uw keuze…',

		// Groups – Gruppen aus CRM
		'endkunde': 'Eindklant',
		'architekt_planer': 'Architect / Planner',
		'investor': 'Investeerder',
		'fachhaendler': 'Vakhandelaar',
		'verarbeiter_handwerker': 'Bewerkers / Vakmensen',

		// Old Groups and Subgroups
		'architekt': 'Architect',
		'generalunternehmer': 'Algemene aannemer',
		'bauherr_bautraeger_betreiber': 'Bouwheer / Opdrachtgever / Exploitant',
		'facilitymanager': 'Facilitymanager',
		'industriekunde': 'Industriële klant',
		'journalist': 'Journalist',
		'tga_fachplaner': 'Vakexpert TGU-planning',
		'sonstiges': 'Andere',
		'baubeschlag': 'Beslag',
		'elektronik': 'Elektronica',
		'sicherheitstechnik': 'Veiligheidstechniek',
		'elektrotechnik': 'Elektrotechniek',
		'sanitaertechnik': 'Sanitaire technieken',
		'anlagengruppenuebergreifend': 'Installatiegroepoverstijgend',
		'aussentueren': 'Buitendeuren',
		'innentueren': 'Binnendeuren',
		'fenster': 'Ramen',
		'fassade': 'Gevel',
		'sanitaer': 'Sanitair',
		'gewerkeuebergreifend': 'Sectoroverstijgend',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Beslag',
		'asp_berater_elektronik': 'Elektronisch toegangsmanagement',
		'asp_keyaccount_elektronik': 'KeyAccount Elektronica',
		'asp_berater_sanitaer': 'Sanitair',
		'asp_zentr_inland': 'Verkoop Duitsland',
		'asp_zentr_export': 'Verkoop Internationaal',
		'asp_zentr_elektronik': 'Key Account Elektronica',
		'asp_zentr_sanitaer': 'Key Account Sanitair',
		'asp_zentr_pm_beschlag': 'Product Management Beslag',
		'asp_zentr_pm_elektronik': 'Product Management EZM',
		'asp_zentr_pm_sanitaer': 'Product Management Sanitair',
		'asp_zentr_architektur': 'Key Account Architectuur',
		'asp_zentr_marketing': 'Marketing + Public Relations',
		'asp_zentr_online': 'Online Marketing',
		'asp_zentr_personal': 'Personeel',
		'asp_zentr_einkauf': 'Inkoop',
		'asp_zentr_edv': 'Afdeling IT',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Service hotline',
		'asp_category_obj_archi': 'Object advies architectuur',
		'asp_category_betreuung': 'Opvang van professionele handel',
		'asp_category_investorenberatung': 'Investeerder consulting',
		'asp_category_gebietsverkaufsleiter': 'Area verkoop lijn',
		'asp_category_innendienst': 'Interne verkopen',
		'asp_category_zentral': 'Contactpersoon',
		'asp_category_innendienst2022': 'Binnendienst',
		'asp_category_aussendienst2022': 'Buitendienst',
		'asp_category_contact2022': 'Contact',

		// Cookie Hinweis
		'cookie_hint_headline': 'Gebruik van cookies',
		'cookie_hint': 'Om de website optimaal te gebruiken en voortdurend te verbeteren, maken wij gebruik van cookies. Door gebruik te maken van deze website, gaat u akkoord met het gebruik van cookies. Meer informatie is beschikbaar in de instructies voor cookies',

		// Digitaler Katalog
		'dk_patience_please': 'Wees alstublieft geduldig.'
	},
	'it': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Indietro',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Ricordare',
		'aartikel.to_product': 'Prodotto',
		'aartikel.i_of_n': 'da ',

		// Select-Felder
		'select.please_choose': 'Scegliere.....',
		'select.no_match': 'Nessun riscontro',

		// Groups – Gruppen aus CRM
		'endkunde': 'Cliente finale',
		'architekt_planer': 'Architetto / Progettista',
		'investor': 'Investitore',
		'fachhaendler': 'Rivenditore specializzato',
		'verarbeiter_handwerker': 'Trasformatore / Artigiano',

		// Old Groups and Subgroups
		'architekt': 'Architetto',
		'generalunternehmer': 'General contractor',
		'bauherr_bautraeger_betreiber': 'Proprietario / sviluppatore / operatore',
		'facilitymanager': 'Facilitymanager',
		'industriekunde': 'Cliente industriale',
		'journalist': 'Giornalista',
		'tga_fachplaner': 'Pianificatore tecnico TGA',
		'sonstiges': 'Altro',
		'baubeschlag': 'Ferramenta per l\'edilizia',
		'elektronik': 'Elettronica',
		'sicherheitstechnik': 'Ingegneria della sicurezza',
		'elektrotechnik': 'Elettrotecnica',
		'sanitaertechnik': 'Ingegneria sanitaria',
		'anlagengruppenuebergreifend': 'Attraverso gruppi di piante',
		'aussentueren': 'Porte esterne',
		'innentueren': 'Porte interne',
		'fenster': 'Finestrini',
		'fassade': 'Prospetto',
		'sanitaer': 'Igiene',
		'gewerkeuebergreifend': 'Interdisciplinare',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Allestimenti',
		'asp_berater_elektronik': 'Gestione elettronica degli accessi',
		'asp_keyaccount_elektronik': 'Elettronica KeyAccount',
		'asp_berater_sanitaer': 'Igiene',
		'asp_zentr_inland': 'Distribuzione Nazionale',
		'asp_zentr_export': 'Vendite internazionali',
		'asp_zentr_elektronik': 'Vendite Gestione elettronica degli accessi',
		'asp_zentr_sanitaer': 'Vendite Attrezzature sanitarie ',
		'asp_zentr_pm_beschlag': 'Gestione del prodotto',
		'asp_zentr_pm_elektronik': 'Gestione del prodotto EZM',
		'asp_zentr_pm_sanitaer': 'Gestione del prodotto Sanitario',
		'asp_zentr_architektur': 'Servizio di architettura',
		'asp_zentr_marketing': 'Marketing + Public Relations',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Personale',
		'asp_zentr_einkauf': 'Acquisti',
		'asp_zentr_edv': 'Dipartimento IT',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Hotline di servizio',
		'asp_category_obj_archi': 'Architettura della consulenza oggettistica',
		'asp_category_betreuung': 'Supporto al dettaglio',
		'asp_category_investorenberatung': 'Consulenza agli investitori',
		'asp_category_gebietsverkaufsleiter': 'Gestione delle vendite per area',
		'asp_category_innendienst': 'Vendite interne',
		'asp_category_zentral': 'Referente centrale',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': 'Contattateci',

		// Cookie Hinweis
		'cookie_hint_headline': 'Utilizzo dei cookie',
		'cookie_hint': 'Utilizziamo i cookie per progettare in modo ottimale e migliorare continuamente il sito web. Continuando a utilizzare il sito web, l\'utente acconsente all\'uso dei cookie. Ulteriori informazioni si trovano nelle note dei cookie.',

		// Digitaler Katalog
		'dk_patience_please': 'Per favore, sii paziente per un momento.'
	},
	'es': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Espalda',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Recuerda',
		'aartikel.to_product': 'Producto',
		'aartikel.i_of_n': 'antes de ',

		// Select-Felder
		'select.please_choose': 'Por favor, elija....',
		'select.no_match': 'Sin resultados',

		// Groups – Gruppen aus CRM
		'endkunde': 'Destinatario final',
		'architekt_planer': 'Arquitecto / Planificador',
		'investor': 'Inversor',
		'fachhaendler': 'Distribuidor especializado',
		'verarbeiter_handwerker': 'Procesador / Artesano',

		// Old Groups and Subgroups
		'architekt': 'Arquitecto',
		'generalunternehmer': 'Contratista general',
		'bauherr_bautraeger_betreiber': 'Propietario / desarrollador / operador',
		'facilitymanager': 'Facilitymanager',
		'industriekunde': 'Cliente industrial',
		'journalist': 'Periodista',
		'tga_fachplaner': 'Planificador técnico de TGA',
		'sonstiges': 'Otros',
		'baubeschlag': 'Equipo de construcción',
		'elektronik': 'Electrónica',
		'sicherheitstechnik': 'Ingeniería de seguridad',
		'elektrotechnik': 'Ingeniería eléctrica',
		'sanitaertechnik': 'Ingeniería sanitaria',
		'anlagengruppenuebergreifend': 'En todos los grupos de plantas',
		'aussentueren': 'Puertas exteriores',
		'innentueren': 'Puertas de interior',
		'fenster': 'Ventanillas',
		'fassade': 'Fachada',
		'sanitaer': 'Higienización',
		'gewerkeuebergreifend': 'Interdisciplinario',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Herrajes',
		'asp_berater_elektronik': 'Gestión de acceso electrónico',
		'asp_keyaccount_elektronik': 'KeyAccount Electronics',
		'asp_berater_sanitaer': 'Higienización',
		'asp_zentr_inland': 'Distribución Nacional',
		'asp_zentr_export': 'Distribución Nacional',
		'asp_zentr_elektronik': 'Gestión del acceso electrónico a las ventas',
		'asp_zentr_sanitaer': 'Venta de equipos sanitarios ',
		'asp_zentr_pm_beschlag': 'Administración de productos',
		'asp_zentr_pm_elektronik': 'Gestión de productos EZM',
		'asp_zentr_pm_sanitaer': 'Gestión de productos sanitarios',
		'asp_zentr_architektur': 'Servicio de arquitectura',
		'asp_zentr_marketing': 'Marketing + Public Relations',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Pentagrama',
		'asp_zentr_einkauf': 'De compras',
		'asp_zentr_edv': 'Departamento de informática',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Líneas de servicio',
		'asp_category_obj_archi': 'Arquitectura de Consultoría de Objetos',
		'asp_category_betreuung': 'Apoyo minorista',
		'asp_category_investorenberatung': 'Consultoría de inversores',
		'asp_category_gebietsverkaufsleiter': 'Dirección de ventas',
		'asp_category_innendienst': 'Ventas internas',
		'asp_category_zentral': 'Principal ',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': 'Contáctenos',

		// Cookie Hinweis
		'cookie_hint_headline': 'Uso de cookies',
		'cookie_hint': 'Utilizamos cookies con el fin de diseñar de forma óptima y mejorar continuamente el sitio web. Al continuar utilizando el sitio web, usted da su consentimiento para el uso de cookies. Puede encontrar más información en las notas de las cookies.',

		// Digitaler Katalog
		'dk_patience_please': 'Por favor, tenga paciencia por un momento.'
	},
	'ru': {
		// Zurück im Mobile-Menü
		'mdc.back': 'Back',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': 'Add to wishlist',
		'aartikel.to_product': 'To the product',
		'aartikel.i_of_n': 'from',

		// Select-Felder
		'select.please_choose': 'Please select…',
		'select.no_match': 'No results',

		// Groups – Gruppen aus CRM
		'endkunde': 'End-customer',
		'architekt_planer': 'Architect / Planner',
		'investor': 'Investor',
		'fachhaendler': 'Trade stockist',
		'verarbeiter_handwerker': 'Fabricator / tradesperson',

		// Old Groups and Subgroups
		'architekt': 'Architect',
		'generalunternehmer': 'General contractor',
		'bauherr_bautraeger_betreiber': 'Builder\'s client / Building contractor / Operator',
		'facilitymanager': 'Facility manager',
		'industriekunde': 'Industry customer',
		'journalist': 'Journalist',
		'tga_fachplaner': 'Technical building equipment planner',
		'sonstiges': 'Other',
		'baubeschlag': 'Builder\'s hardware',
		'elektronik': 'Electronics',
		'sicherheitstechnik': 'Security systems',
		'elektrotechnik': 'Electrical engineering',
		'sanitaertechnik': 'Sanitary systems',
		'anlagengruppenuebergreifend': 'Cross-facility-group',
		'aussentueren': 'External doors',
		'innentueren': 'Internal doors',
		'fenster': 'Windows',
		'fassade': 'Facades',
		'sanitaer': 'Sanitary',
		'gewerkeuebergreifend': 'Crott-trade',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': 'Hardware',
		'asp_berater_elektronik': 'Electronic Access Management ',
		'asp_keyaccount_elektronik': 'KeyAccount Electronic',
		'asp_berater_sanitaer': 'Sanitary',
		'asp_zentr_inland': 'Domestic Sales',
		'asp_zentr_export': 'International Sales',
		'asp_zentr_elektronik': 'Key account electronics',
		'asp_zentr_sanitaer': 'Key account barrier-free products',
		'asp_zentr_pm_beschlag': 'Product Management Hardware',
		'asp_zentr_pm_elektronik': 'Product Management EZM',
		'asp_zentr_pm_sanitaer': 'Product Management Sanitary',
		'asp_zentr_architektur': 'Key Account Architecture',
		'asp_zentr_marketing': 'Marketing + Public Relations',
		'asp_zentr_online': 'Online-Marketing',
		'asp_zentr_personal': 'Human Resources',
		'asp_zentr_einkauf': 'Purchasing',
		'asp_zentr_edv': 'IT Department',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': 'Service hotline',
		'asp_category_obj_archi': 'Object Consulting Architecture',
		'asp_category_betreuung': 'Specialized Trade Support',
		'asp_category_investorenberatung': 'Investment consultant',
		'asp_category_gebietsverkaufsleiter': 'Areas Sales Management',
		'asp_category_innendienst': 'Internal Sales',
		'asp_category_zentral': 'Central Contact person',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': 'контакт',

		// Cookie Hinweis
		'cookie_hint_headline': 'Use of cookies',
		'cookie_hint': 'We use cookies in order to optimally design and continuously improve our website. If you continue using the website you agree to the usage of cookies. Further information can be withdrawn from the cookie notice',

		// Digitaler Katalog
		'dk_patience_please': 'Please be patient.'
	},
	'zh': {
		// Zurück im Mobile-Menü
		'mdc.back': '返回',

		// Ausgewählte Artikel / Architektour Artikel
		'aartikel.merken': '收藏',
		'aartikel.to_product': '去产品页',
		'aartikel.i_of_n': '从',

		// Select-Felder
		'select.please_choose': '请选择。。。',
		'select.no_match': '没有结果',

		// Groups – Gruppen aus CRM
		'endkunde': '最终客户',
		'architekt_planer': '建筑事务所 / 策划人',
		'investor': '投资者',
		'fachhaendler': '经销商',
		'verarbeiter_handwerker': '施工者 / 工匠',

		// Old Groups and Subgroups
		'architekt': '建筑师',
		'generalunternehmer': '总承包商',
		'bauherr_bautraeger_betreiber': '业主 / 建筑承包商 / 运营商',
		'facilitymanager': '设施管理',
		'industriekunde': '工业客户',
		'journalist': '记者',
		'tga_fachplaner': '建筑设备技术专业规划师',
		'sonstiges': '其他',
		'baubeschlag': '建筑五金',
		'elektronik': '电子',
		'sicherheitstechnik': '防盗设备',
		'elektrotechnik': '电子设备',
		'sanitaertechnik': '卫浴设备',
		'anlagengruppenuebergreifend': '组合设备',
		'aussentueren': '入户门',
		'innentueren': '室内门',
		'fenster': '窗户',
		'fassade': '外墙',
		'sanitaer': '卫浴',
		'gewerkeuebergreifend': '组合贸易',

		// Ansprechpartnersuche – Funktionen
		'asp_obj_fachhandel': '五金',
		'asp_berater_elektronik': '电子门禁系统',
		'asp_keyaccount_elektronik': '电子产品销售',
		'asp_berater_sanitaer': '卫浴',
		'asp_zentr_inland': '国内销售',
		'asp_zentr_export': '国际销售',
		'asp_zentr_elektronik': '电子产品销售',
		'asp_zentr_sanitaer': '卫浴产品销售',
		'asp_zentr_pm_beschlag': '产品管理',
		'asp_zentr_pm_elektronik': '电子门禁系统产品管理',
		'asp_zentr_pm_sanitaer': '卫浴产品管理',
		'asp_zentr_architektur': '建筑设计服务',
		'asp_zentr_marketing': '市场营销 + 公关',
		'asp_zentr_online': '网络市场营销',
		'asp_zentr_personal': '人事',
		'asp_zentr_einkauf': '采购',
		'asp_zentr_edv': 'IT 部门',

		// Ansprechpartnersuche – Kategorien
		'asp_category_hotline': '服务热线',
		'asp_category_obj_archi': '建筑设计咨询',
		'asp_category_betreuung': '代理商支持',
		'asp_category_investorenberatung': '投资咨询',
		'asp_category_gebietsverkaufsleiter': '区域销售经理',
		'asp_category_innendienst': '内部销售',
		'asp_category_zentral': '主要联络人',
		'asp_category_innendienst2022': 'Customer service',
		'asp_category_aussendienst2022': 'Sales reps',
		'asp_category_contact2022': '联系方式',

		// Cookie Hinweis
		'cookie_hint_headline': '使用 cookies',
		'cookie_hint': '为了优化网站并不断进行改进，我们使用cookie。 继续使用本网站即表示您同意使用cookie。 有关更多信息，请参见cookie注释。',

		// Digitaler Katalog
		'dk_patience_please': '请耐心等待。'
	}
});