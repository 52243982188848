'use strict';

// Dependencies
import { Component } from 'react';
import SearchConstants from '../../constants/SearchConstants';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';

// SubComponents
import Facette from './Facette.jsx';
import PropTypes from 'prop-types';

export default class Facetten extends Component {

	render() {

		if (this.props.q === null || this.props.q.length === 0) {
			return null;
		}

		let facetten = this.props.pageCategories.map(facet => {
			if (facet.displayInFacets && (this.props.displayMode !== 'mobile' || facet.displayInMobile)) {

				let matches = 0;
				if (facet.path === SearchConstants.FACET_ALL) {
					matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
					matches += parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
				}
				else if (facet.path === SearchConstants.FACET_SELEKTOR) {
					matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
				}
				else {
					matches = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + facet.path + '.numFound')) || 0;
				}

				if (matches === 0) {
					return null;
				}
				else {
					return <Facette key={'facette_' + facet.path} facetKey={facet.path} facetName={facet.title} {...this.props} />;
				}
			}
		});

		return (
			<div className='pagesearch__facetts'>
				{facetten}
			</div>
		);
	}
}
Facetten.propTypes = {
	q: PropTypes.string,
	pageCategories: PropTypes.object,
	displayMode: PropTypes.string
};

