/* eslint-disable */
//
// Modified to use CMJSContext.page.properties.googleMapsKey as default api key
//

/*
 * JavaScript - loadRecaptcha(  )
 *
 * Stolen from loadGoogleMaps Script :D
 */
window.loadRecaptcha = (function($) {

	var now = $.now(),

		promise;

	return function() {

		if( promise ) { return promise; }

		//Create a Deferred Object
		var	deferred = $.Deferred(),

			//Declare a resolve function, pass grecaptcha for the done functions
			resolve = function () {
				deferred.resolve( window.grecaptcha ? window.grecaptcha : false );
			},

			//global callback name
			callbackName = "loadRecaptcha_" + ( now++ );

		//If grecaptcha exists, then Recaptcha was probably already loaded
		if( window.grecaptcha ) {
			resolve();

		// try pure jQuery Ajax technique to load the Recaptcha API in Async.
		} else {
			//Declare the global callback
			window[callbackName] = function( ) {
				resolve();

				//Delete callback
				setTimeout(function() {
					try{
						delete window[callbackName];
					} catch( e ) {}
				}, 20);
			};

			//Can't use the jXHR promise because 'script' doesn't support 'callback=?'
			$.ajax({
				dataType: 'script',
				url: 'https://www.google.com/recaptcha/api.js?render=explicit&onload='+callbackName
			});

		}

		promise = deferred.promise();

		return promise;
	};

}(jQuery));
