var ArtikelU = function(element) {

	// the stored life choice
	this.lifeChoice = null;
	// slider state
	this.activeSlide = 0;
	this.slides = [];
	this.slideWidth = -1;


	this.element = jQuery(element);

	// Get the data or die
	var id = element.id;
	this.data = window[id + '_artikelDaten'];
	if (!Array.isArray(this.data) || this.data.length < 1) {
		return;
	}

	// bind on resize
	jQuery(window).bind('resize', this.rethinkLifeChoice.bind(this));
	jQuery(window).bind('orientationchange', this.rethinkLifeChoice.bind(this));

	// and init initially
	this.rethinkLifeChoice();

};

ArtikelU.prototype.rethinkLifeChoice = function() {
	// check window width and choose desktop or mobile
	var lifeChoice = window.innerWidth < 768 ? 'mobile' : 'desktop';

	// if lifeChoice changes clean and init
	if (lifeChoice !== this.lifeChoice) {
		// quickly set the lifeChoice to prevent multiple calls at once
		this.lifeChoice = lifeChoice;
		// clean up any residue
		this.element.empty();

		if (this.lifeChoice === 'mobile') {
			this.chooseMobile();
		}
		else {
			this.chooseDesktop();
		}
	}
	else if (this.lifeChoice === 'mobile') {
		this.mobileOnResize();
	}
	else {
		this.desktopOnResize();
	}
};

ArtikelU.prototype.chooseMobile = function() {
	var self = this;

	this.lifeChoice = 'mobile';

	// Define all the elements
	this.slider = $j('<div class="mod_artikelu_slider"></div>');
	this.slideWrapper = $j('<div class="mod_artikelu_slide_wrapper"></div>').appendTo(this.slider);
	this.sliderLeft = $j('<div class="mod_artikelu_left"></div>');
	this.sliderCounterI = $j('<span class="mod_artikelu_i"></span>');
	this.sliderCounterN = $j('<span class="mod_artikelu_n"></span>');
	this.sliderRight = $j('<div class="mod_artikelu_right"></div>');

	// Basic events
	this.sliderLeft.on('click', this.slideLeft.bind(this));
	this.sliderRight.on('click', this.slideRight.bind(this));
	this.slider.swipe({
		allowPageScroll: 'auto',
		swipeRight: function () {
			self.slideLeft();
		},
		swipeLeft: function () {
			self.slideRight();
		}
	});

	// initial data update pass
	this.selectedArtikel = this.data[0];
	this.mobileUpdateSlider();

	// Construct the HTML and add it to the page
	$j('<div class="mod_artikelu--mobile"></div>').append(
		this.slider,
		$j('<div class="mod_artikelu_slideactions"></div>').append(
			this.sliderLeft,
			$j('<div class="mod_artikelu_iofn"></div>').append(
				this.sliderCounterI,
				CMJSContext.L('artikelu.i_of_n'),
				this.sliderCounterN
			),
			this.sliderRight
		)
	).appendTo(this.element);

	this.mobileOnResize();
};

ArtikelU.prototype.mobileUpdateSlider = function() {
	var self = this;

	self.activeSlide = 0;
	self.slides = [];


	var slide;
	this.data.forEach(function(elem) {
		slide = $j('<div class="mod_artikelu_slide"></div>');
		slide.appendTo(self.slideWrapper);
		self.slides.push(slide);

		$j('<div class="mod_artikelu_mobmain"></div>').append(
			'<div class="mod_artikelu_hl">' + elem.modellbezeichnung + '</div>',
			'<div class="mod_artikelu_cat">' + elem.kategorie + '</div>',
			'<div class="mod_artikelu_img">' + self.imgHtml(elem.image) + '</div>',
			'<p class="mod_artikelu_intro">' + elem.text + '</p>',
			'<div class="mod_artikelu_btns"><a href="#" class="mod_artikelu_btn--mz link-button-light">' + CMJSContext.L('artikelu.merken') + '</a><a href="#" class="mod_artikelu_btn--link link-button-dark">' + CMJSContext.L('artikelu.to_product') + '</a></div>'
		)
		.appendTo(
			slide
		);
	});
	if (self.slides.length > 1) {
		var firstSlide = self.slides[0].clone();
		firstSlide.appendTo(self.slideWrapper);
		self.slides.push(firstSlide);
	}

	this.sliderCounterI.text(this.activeSlide + 1);
	this.sliderCounterN.text(this.slides.length - 1);

	this.slideTo(0);
};

ArtikelU.prototype.mobileOnResize = function() {
	this.slideWidth = Math.ceil(this.slider.width()) + 1;

	var self = this;
	this.slides.forEach(function(slide, i) {
		slide.css({
			width: self.slideWidth,
			left: self.slideWidth * i
		});
	});

	this.slideWrapper.css({
		width: this.slideWidth * this.slides.length,
		left: - this.activeSlide * this.slideWidth
	});
};



ArtikelU.prototype.chooseDesktop = function() {
	var self = this;

	this.lifeChoice = 'desktop';


	// Define all the elements
	this.mainHL = $j('<div class="mod_artikelu_hl"></div>');
	this.mainCat = $j('<div class="mod_artikelu_cat"></div>');
	this.mainIntro = $j('<p class="mod_artikelu_intro"></p>');
	this.btnMerken = $j('<a href="#" class="mod_artikelu_btn--mz link-button-light">' + CMJSContext.L('artikelu.merken') + '</a>');
	this.btnProdukt = $j('<a href="#" class="mod_artikelu_btn--link link-button-dark">' + CMJSContext.L('artikelu.to_product') + '</a>');
	this.mainImg = $j('<div class="col-sm-6 mod_artikelu_img"><picture></picture></div>');

	this.slider = $j('<div class="mod_artikelu_slider"></div>');
	this.slideWrapper = $j('<div class="mod_artikelu_slide_wrapper"></div>').appendTo(this.slider);
	this.sliderLeft = $j('<div class="mod_artikelu_left"></div>');
	this.bullets = $j('<div class="mod_artikelu_bullets"></div>');
	this.sliderRight = $j('<div class="mod_artikelu_right"></div>');

	// Basic events
	this.sliderLeft.on('click', this.slideLeft.bind(this));
	this.sliderRight.on('click', this.slideRight.bind(this));
	this.slider.swipe({
		allowPageScroll: 'auto',
		swipeRight: function () {
			self.slideLeft();
		},
		swipeLeft: function () {
			self.slideRight();
		}
	});

	// initial data update pass
	this.selectedArtikel = this.data[0];
	this.desktopUpdateMain();
	this.desktopUpdateSlider();


	// Construct the HTML and add it to the page
	$j('<div class="row mod_artikelu--desktop"></div>').append(
		$j('<div class="col-md-8"></div>').append(
			$j('<div class="mod_artikelu_main"></div>').append(
				$j('<div class="row"></div>').append(
					$j('<div class="col-sm-6"></div>').append(
						this.mainHL,
						this.mainCat,
						this.mainIntro,
						$j('<div class="mod_artikelu_btns"></div>').append(
							this.btnMerken,
							this.btnProdukt
						)
					),
					this.mainImg
				)
			)
		),
		$j('<div class="col-md-4 mod_artikelu_grid"></div>').append(
			this.slider,
			$j('<div class="mod_artikelu_slideactions"></div>').append(
				this.sliderLeft,
				this.bullets,
				this.sliderRight
			)
		)
	).appendTo(this.element);

	this.desktopOnResize();
};

/**
 * Updates the main view with the contents of this.selectedArtikel
 */
ArtikelU.prototype.desktopUpdateMain = function() {
	// this.selectedArtikel
	// replace text
	this.mainHL.text(this.selectedArtikel.modellbezeichnung);
	// replace text
	this.mainCat.text(this.selectedArtikel.kategorie);
	// replace text
	this.mainIntro.text(this.selectedArtikel.text);
	// hide/show
	if (this.selectedArtikel.merkzettel) { this.btnMerken.show(); } else { this.btnMerken.hide(); }
	// hide/show
	if (this.selectedArtikel.url) { this.btnProdukt.show(); } else { this.btnProdukt.hide(); }
	// replace inner html
	this.mainImg.html(this.imgHtml(this.selectedArtikel.image));
};

ArtikelU.prototype.desktopUpdateSlider = function() {
	var self = this;

	self.activeSlide = 0;
	self.slides = [];

	var slide;
	this.data.forEach(function(elem, i) {
		if (i % 6 === 0) {
			slide = $j('<div class="mod_artikelu_slide"></div>');
			slide.appendTo(self.slideWrapper);
			var slideIndex = self.slides.length;
			self.slides.push(slide);

			$j('<div class="mod_artikelu_bullet"></div>')
				.appendTo(self.bullets)
				.on('click', function() {
					self.slideTo(slideIndex);
				});
		}
		var artikelteaser = $j('<div class="mod_artikelu_teaser"></div>').append(
			'<div class="mod_artikelu_teaserimg">' + self.imgHtml(elem.teaserImage) + '</div>',
			'<div class="mod_artikelu_teaserhl">' + elem.modellbezeichnung + '</div>',
			'<div class="mod_artikelu_teasercat">' + elem.kategorie + '</div>'
		).appendTo(
			slide
		).on('click', function() {
			self.selectedArtikel = elem;

			$j('.mod_artikelu_teaser').removeClass('mod_artikelu_teaser--active');
			$j(this).addClass('mod_artikelu_teaser--active');

			self.desktopUpdateMain();
		});

		if (elem === self.selectedArtikel) {
			artikelteaser.addClass('mod_artikelu_teaser--active');
		}

	});

	if (self.slides.length > 1) {
		var firstSlide = self.slides[0].clone();
		firstSlide.appendTo(self.slideWrapper);
		self.slides.push(firstSlide);
	}

	this.desktopOnResize();

	this.slideTo(0);
};

ArtikelU.prototype.desktopOnResize = function() {
	// Fix some stuff after adding the dom elements
	// set slide Width manually
	this.slideWidth = Math.ceil(this.slider.width() + 0.01);

	var self = this;
	this.slides.forEach(function(slide, i) {
		slide.css({
			width: self.slideWidth,
			left: self.slideWidth * i
		});
	});

	this.slideWrapper.css({
		width: this.slideWidth * this.slides.length,
		left: - this.activeSlide * this.slideWidth
	});
};





ArtikelU.prototype.slideTo = function(slideIndex) {
	var self = this;

	if (self.slideWidth === -1 && typeof self.slideWrapper === "undefined") {
		return;
	}

	// if more than one jump => go there instantly
	if (Math.abs(slideIndex - this.activeSlide) > 1) {
		slideIndex = Math.min(Math.max(0, slideIndex), this.slides.length - 1);
		self.slideWrapper.stop().css({left: - self.slideWidth * slideIndex});
	}
	// Normalfall
	else if (slideIndex >= 0 && slideIndex < this.slides.length) {
		self.slideWrapper.stop().animate({left: - self.slideWidth * slideIndex}, 1000);
	}
	// Overflow nach oben um 1 von activeSlide = this.slides.length - 1 auf this.slides.length
	else if (slideIndex >= this.slides.length) {
		slideIndex = 1; // Implizite definition
		self.slideWrapper.stop().css({left: 0}); // Sprung nach ganz links
		self.slideWrapper.stop().animate({left: - self.slideWidth}, 1000); // rutschen auf Nr. 1
	}
	// Overflow nach unten um 1
	else if (slideIndex < 0) {
		slideIndex = this.slides.length - 2; // Implizite definition
		self.slideWrapper.stop().css({left: - self.slideWidth * (this.slides.length - 1)}); // Sprung nach ganz rechts
		self.slideWrapper.stop().animate({left: - self.slideWidth * slideIndex}, 1000); // rutschen auf letztes echtes Element von hinten
	}

	this.activeSlide = slideIndex;

	if (this.bullets) {
		this.bullets.find('.mod_artikelu_bullet--active').removeClass('mod_artikelu_bullet--active');
		this.bullets.find('.mod_artikelu_bullet').eq((this.activeSlide % (this.slides.length - 1))).addClass('mod_artikelu_bullet--active');
	}
	if (this.sliderCounterI) {
		this.sliderCounterI.text((this.activeSlide % (this.slides.length - 1)) + 1);
	}
};

ArtikelU.prototype.slideLeft = function() {
	this.slideTo(this.activeSlide - 1);
};

ArtikelU.prototype.slideRight = function() {
	this.slideTo(this.activeSlide + 1);
};



ArtikelU.prototype.imgHtml = function(imagesizes) {
	var html = '<picture>';

	html += '<source media="(min-width: 1200px)" data-srcset="' + imagesizes.lg + ' 1x, ' + imagesizes.lg2x + ' 2x">';
	html += '<source media="(min-width: 1024px)" data-srcset="' + imagesizes.md + ' 1x, ' + imagesizes.md2x + ' 2x">';
	html += '<source media="(min-width: 768px)" data-srcset="' + imagesizes.sm + ' 1x, ' + imagesizes.sm2x + ' 2x">';
	html += '<source media="(max-width: 767px)" data-srcset="' + imagesizes.xs + ' 1x, ' + imagesizes.xs2x + ' 2x">';
	html += '<img border="0" alt=" " class="lazyload img-responsive of-image"  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="' + imagesizes.lg + '">';


	html += '</picture>';
	return html;
};

// init all the occurences (only 1... ;-))
jQuery('.mod_artikelu').each(function initArtikelUModule(){
	new ArtikelU(this);
});
