/* table wrapper hinzufuegen für scrollen auf mobilen geraeten */
jQuery('table').wrap('<div class="table-wrapper"></div>');


/* Filter in of-pdownloads und of-artikeluebersicht */
jQuery('.of-artikeluebersicht .nav-pills a, .of-pdownloads .nav-pills a').on('click', function() {
	var parent = jQuery(this).parents('.filter-open');
	parent.removeClass('filter-open');
	parent.find('.selectedval').html(jQuery(this).text() + '<span class="ic"></span>');
});


/* dk, produktmatrix hover über bild, modelnamen unterstreichen */
jQuery( ".results-element .bild" ).hover(
	function() {
		jQuery( this ).parent().parent().find('.modell-default span').addClass('modelname_hover');
	}, function() {
		jQuery('.modell-default span').removeClass('modelname_hover');
	}
);


