'use strict';

// Dependencies
import { Component } from 'react';
import SearchActions from '../../actions/SearchActions';
import SearchConstants from '../../constants/SearchConstants';
import valueForKeyPath from '../../../_react_common/valueForKeyPath';

// SubComponents
import Bereichsergebnisse from './Bereichsergebnisse.jsx';
import SelektorErgebnisse from './SelektorErgebnisse.jsx';
import Hinweistext from '../Common/Hinweistext.jsx';
import PropTypes from 'prop-types';

export default class Results extends Component {
    _onBackClick = () => {
		SearchActions.filter(SearchConstants.FACET_ALL);
	};

    render() {

		let bereiche = [];

		this.props.pageCategories.forEach(facet => {
			if (facet.displayInResults) {
				if ((this.props.displayMode !== 'mobile' && this.props.activeFacet === SearchConstants.FACET_ALL) || this.props.activeFacet === facet.path) {
					if (facet.path === SearchConstants.FACET_FAQ) {
						bereiche.push(<Hinweistext key={'res_' + SearchConstants.FACET_FAQ} {...this.props} />);
					}
					else if (facet.path === SearchConstants.FACET_SELEKTOR) {
						bereiche.push(<SelektorErgebnisse key={'res_' + SearchConstants.FACET_SELEKTOR} {...this.props} />);
					}
					else {
						bereiche.push(<Bereichsergebnisse key={'res_' + facet.path} facetKey={facet.path} facetName={facet.title} {...this.props} />);
					}
				}
			}
		});

		let mobileHeadline;
		if (this.props.displayMode === 'mobile') {
			let matchesCMS = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
			let matchesSelektor = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;

			let numResults = matchesCMS + matchesSelektor;
			if (numResults > 0) {
				mobileHeadline = <div className='pagesearch__ergebnisse__back' onClick={this._onBackClick}>{CMJSContext.L('srch.facet.all')}</div>;
			}
		}

		let noResults;
		if (!this.props.results) {
			noResults = <p>{CMJSContext.L('srch.no_results.p')}</p>;
		}

		let marginTop = 0;

		if (this.props.displayMode === 'mobile' && this.props.activeFacet !== SearchConstants.FACET_ALL) {
			this.props.pageCategories.forEach(facet => {
				if (facet.displayInFacets && (this.props.displayMode !== 'mobile' || facet.displayInMobile)) {

					let matches = 0;
					if (facet.path === SearchConstants.FACET_ALL) {
						matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
						matches += parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
					}
					else if (facet.path === SearchConstants.FACET_SELEKTOR) {
						matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
					}
					else {
						matches = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + facet.path + '.numFound')) || 0;
					}

					if (matches > 0) {
						marginTop = marginTop + 38.857142857;
					}
				}
			});
		}


		return (
			<div className='pagesearch__ergebnisse' style={{'marginTop': -marginTop + 'px'}}>
				{noResults}
				{mobileHeadline}
				{bereiche}
			</div>
		);
	}
}

Results.propTypes = {
	q: PropTypes.string,
	pageCategories: PropTypes.object,
	displayMode: PropTypes.string,
	activeFacet: PropTypes.string,
	results: PropTypes.object
};
