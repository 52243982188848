'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';
import cN from 'classnames';
import SearchActions from '../../actions/SearchActions';
import Mustache from 'mustache';

// SubComponents
import SeitenErgebnis from '../Common/SeitenErgebnis.jsx';

export default class QuickResultsArea extends Component {
    _onMouseOver = () => {
		SearchActions.resultMouseOver(this.props.facetKey, null);
		event.stopPropagation();
	};

    _onMouseOut = () => {
		SearchActions.resultMouseOut(this.props.facetKey, null);
		event.stopPropagation();
	};

    render() {
		let ergebnisse = valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + this.props.facetKey + '.docs') || [];
		let numResults = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + this.props.facetKey + '.numFound')) || 0;

		// skip if no results
		if (ergebnisse.length === 0) {
			return null;
		}

		// trim display to size.
		ergebnisse = ergebnisse.slice(0, this.props.displayedResultParts.showPageResults);

		// render single result entry
		let ergebnisTags = ergebnisse.map((ergebnis, i) => {
			return <SeitenErgebnis key={ergebnis.id} i={i + 1} seite={ergebnis} {...this.props}/>;
		});

		let headline = this.props.facetName + ' ' + Mustache.render(CMJSContext.L('srch.num_results.' + this.props.displayMode), {numResults: numResults});
		let resultLink = this.props.pageSearchResults + (this.props.pageSearchResults.indexOf('?') > -1 ? '&' : '?') + 'query=' + encodeURIComponent(this.props.q) + '&facet=' + this.props.facetKey;

		return (
			<div
				className={cN(
					'quicksearch__results__area quicksearch__results__area--page',
					{
						'resulthighlight': true,
						'resulthighlight--dimmed': this.props.highlightedFacet !== null,
						'resulthighlight--highlighted': this.props.highlightedFacet === this.props.facetKey
					}
				)}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				<a href={resultLink} className='quicksearch__results__headline'>{headline}</a>
				{ergebnisTags}
			</div>
		);
	}
}

QuickResultsArea.propTypes = {
	displayMode: PropTypes.string,
	q: PropTypes.string,
	facetKey: PropTypes.string.isRequired,
	facetName: PropTypes.string.isRequired,
	displayedResultParts: PropTypes.object,
	pageSearchResults: PropTypes.string,
	highlightedFacet: PropTypes.string
};
