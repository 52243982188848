'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchConstants from '../../constants/SearchConstants';
import SearchActions from '../../actions/SearchActions';

// SubComponents
import ResponsiveImage from './ResponsiveImage.jsx';

const modellProdukte = [
	'produkt0100', // Garnituren
	'produkt_ergo_e300', // Ergo E300
	'produkt_metric', // Metric
	'produkt_ergo_a100', // Ergo A100
	'produkt_turnhallenmuscheln', // Turnhallenb.
	'produkt_panikbeschlaege', // Panikstangen
	'produkt_m500', // EZK M500
	'produkt_f_griffe' // Fingerscang.
];
const modellOnlyProdukte = [
	'produkt_m500_glastuer',
	'produkt_m500_rahmentuer',
	'produkt_m500_vollblatt'
];

export default class ProduktErgebnis extends Component {
    _onMouseOver = () => {
		event.stopPropagation();
		SearchActions.resultMouseOver(SearchConstants.FACET_SELEKTOR, this.props.produkt.id);
	};

    _onMouseOut = () => {
		event.stopPropagation();
		SearchActions.resultMouseOut(SearchConstants.FACET_SELEKTOR, this.props.produkt.id);
	};

    render() {
		let url = CMJSContext.page.properties.katalogURL + (!CMJSContext.page.properties.katalogURL.endsWith('/') ? '/' : '');
		if (this.props.produkt.url && modellOnlyProdukte.indexOf(this.props.produkt.produkt) < 0) {
			url += 'art.jsp?a=' + this.props.produkt.url + '&m=' + window.WhoAmI.getMarkt();
		}
		else if (this.props.produkt.produkt && this.props.produkt.modellbezeichnung_url) {
			let isModell = modellProdukte.indexOf(this.props.produkt.produkt) >= 0 || modellOnlyProdukte.indexOf(this.props.produkt.produkt) >= 0;
			url += (isModell ? 'mod.jsp' : 'art.jsp') + '?a=' + this.props.produkt.modellbezeichnung_url + '&m=' + window.WhoAmI.getMarkt();
		}

		return (
			<a href={url} key={this.props.produkt.id} className='produktergebnis produktergebnis--artikel produktergebnis--page' data-s={this.props.produkt.score} data-b={this.props.produkt.boost} data-o={this.props.produkt.order}>
				<div className='produktergebnis__beschreibung'>
					<div className='produktergebnis__modellnummer'>{this.props.produkt.artikelnummer ? this.props.produkt.artikelnummer : this.props.produkt.modellbezeichnung}</div>
					<div className='produktergebnis__kategorie'>{this.props.produkt.kategorieSingular_text}</div>
				</div>
				<ResponsiveImage className='produktergebnis__thumb' bildname={this.props.produkt.bildname} />
			</a>
		);
	}
}

ProduktErgebnis.propTypes = {
	produkt: PropTypes.object.isRequired,
	mode: PropTypes.string,
	displayMode: PropTypes.string,
	highlightedFacet: PropTypes.string,
	highlightedResult: PropTypes.string
};
