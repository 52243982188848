'use strict';

// Dependencies
import { Component } from 'react';
import SearchConstants from '../../constants/SearchConstants';
import SearchActions from '../../actions/SearchActions';
import valueForKeyPath from '../../../_react_common/valueForKeyPath';
import cN from 'classnames';
import PropTypes from 'prop-types';

export default class Hinweistext extends Component {
    _onMouseOver = () => {
		SearchActions.resultMouseOver(SearchConstants.FACET_FAQ, null);
		event.stopPropagation();
	};

    _onMouseOut = () => {
		SearchActions.resultMouseOut(SearchConstants.FACET_FAQ, null);
		event.stopPropagation();
	};

    _toggleText = () => {
		if (this.props.displayMode === 'mobile') {
			SearchActions.toggleHinweisContent();
		}
	};

    render() {
		let ergebnisse = valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + SearchConstants.FACET_FAQ + '.docs') || [];

		if (ergebnisse.length === 0) {
			return null;
		}

		let hinweis = ergebnisse[0];

		let text;
		if (this.props.displayHinweisContent) {
			text = <div className='hinweistext__content' dangerouslySetInnerHTML={{__html: hinweis.fsbHinweistext}} />;
		}

		return (
			<div
				className={cN(
					'hinweistext',
					{
						'hinweistext--accordeon': this.props.displayMode === 'mobile',
						'hinweistext--open': this.props.displayHinweisContent,
						'resulthighlight': true,
						'resulthighlight--dimmed': this.props.highlightedFacet !== undefined && this.props.highlightedFacet !== null,
						'resulthighlight--highlighted': this.props.highlightedFacet === SearchConstants.FACET_FAQ
					}
				)}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				<div className='hinweistext__headline' onClick={this._toggleText}>{hinweis.fsbHinweistitel}</div>
				{text}
			</div>
		);
	}
}
Hinweistext.propTypes = {
	displayMode: PropTypes.string,
	displayHinweisContent: PropTypes.bool,
	highlightedFacet: PropTypes.string
};
