/*
function initAkkordeons() {
	jQuery('.of-pcontainer--akkordeon').each(function() {
		"use strict";
		var akkordeon = jQuery(this);
		var headline = akkordeon.children('h3');
		var childContainer = akkordeon.children('.childParagraphsContainer')

		headline.off('click.akkordeon').on('click.akkordeon', function() {
			// Alle Geschwister-Akkordeons schließen
			akkordeon.siblings('.of-pcontainer--akkordeon').removeClass('of-pcontainer--akkordeon-open')
				.children('.childParagraphsContainer').css('height', 0);

			if (akkordeon.hasClass('of-pcontainer--akkordeon-open')) {
				akkordeon.removeClass('of-pcontainer--akkordeon-open');
				childContainer.css('height', 0);
			}
			else {
				akkordeon.addClass('of-pcontainer--akkordeon-open');
				updateHeight();
			}

		});

		var updateHeight = function() {
			if (akkordeon.hasClass('of-pcontainer--akkordeon-open')) {
				childContainer.css('height', childContainer.get(0).scrollHeight);
			}
		};

		jQuery(window).off('resize.akkordeon').on('resize.akkordeon', updateHeight);
		jQuery(window).off('orientationchange.akkordeon').on('orientationchange.akkordeon', updateHeight);
		window.hyphenationDone.then(updateHeight);
		updateHeight();
	});
}

initAkkordeons();
*/

window.initAkkordeons = function() {
	jQuery('.of-pcontainer--akkordeon').each(function() {
		"use strict";
		var akkordeon =  jQuery(this);
		var akkordeon_content = akkordeon.find('> .childParagraphsContainer');
		var headline =  akkordeon.children('h3');

		//init animation "öffnen"
		if (akkordeon.hasClass('of-pcontainer--akkordeon-open')) {
			akkordeon_content.stop().slideDown();
            // eslint-disable-next-line no-undef
			if (typeof window.checkFilterWidth === "function") window.checkFilterWidth(); // Um Filter in Akkorderons zu fixen...
		}
		else {
			akkordeon_content.hide();
		}

		headline.off('click.akkordeon').on('click.akkordeon', function() {

			// CSS Alle Geschwister-Akkordeons schließen
			akkordeon.siblings('.of-pcontainer--akkordeon').removeClass('of-pcontainer--akkordeon-open');
			akkordeon.toggleClass('of-pcontainer--akkordeon-open');

			// JS Alle Geschwister-Akkordeons schließen
			akkordeon.siblings('.of-pcontainer--akkordeon').find('> .childParagraphsContainer').stop().slideUp();
			akkordeon_content.stop().slideToggle();
            // eslint-disable-next-line no-undef
			if (typeof window.checkFilterWidth === "function") window.checkFilterWidth(); // Um Filter in Akkorderons zu fixen...

		});
	});
}

window.initAkkordeons();
