'use strict';

/*

*/


import AppDispatcher from '../_react_common/dispatchers/AppDispatcher';
import NavigationConstants from './NavigationConstants';
import Evemit from 'evemit';

// Storeage objects

/**
 * Displaymode der Seite für Mobile/Desktop-Unterscheidung
 * Werte: DISPLAY_MODE_DESKTOP DISPLAY_MODE_MOBILE
 */
let _displayMode = null;

/**
 * The Menu contents
 */
let _navContent = null;

/**
 * The active (uncollapsed item)
 * @type {null}
 * @private
 */
let _activeMenuItem = null;

// Merge our store with Node's Event Emitter
let NavigationStore = Object.assign(new Evemit(), {

	getState: function() {
		return {
			displayMode: _displayMode,
            navContent: _navContent,
            activeMenuItem: _activeMenuItem,
		};
	},

	// Register dispatcher callback
	dispatchToken: AppDispatcher.register(function(action) {
        // Define what to do for certain actions
        switch (action.actionType) {
            case NavigationConstants.ACTION_INIT:
                _displayMode = action.displayMode;
                _navContent = action.navContent;
                _activeMenuItem = null;
                NavigationStore.emit('change');
                break;

            case NavigationConstants.ACTION_UPDATE_DISPLAY_MODE:
                _displayMode = action.displayMode;
                NavigationStore.emit('change');
                break;

            case NavigationConstants.ACTION_TOGGLE_MENU_ITEM:
                if (action.item === _activeMenuItem) {
                    _activeMenuItem = null;
                }
                else {
                    _activeMenuItem = action.item;
                }
                NavigationStore.emit('change');
                break;
        }

		return true;
	})
});
export default NavigationStore;
