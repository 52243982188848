(function() {
	const modules = jQuery('.Module_LesCouleurs');

	if (modules.length && modules.length > 0) {

        modules.each(function () {
			const module = jQuery(this);

            const imageStackFGLoad = module.find('.mod-lc__imagestack--fg--load');
            const imageStackFG = module.find('.mod-lc__imagestack--fg--fix');
            const imageStackBG = module.find('.mod-lc__imagestack');

            // Image changing
            imageStackFGLoad.on('load', function() {
                imageStackFGLoad.animate({opacity: 1}, 200, 'swing', function() {
                    imageStackFG.attr('src', imageStackFGLoad[0].src);
                });
            });
            imageStackFG.on('load', function() {
                imageStackFGLoad.css('opacity', 0);
            });

            const selectionFG = module.find('.mod-lc__selection--fg');
            const selectionBG = module.find('.mod-lc__selection--bg');
            const currentFG = selectionFG.find('.mod-lc__selection__current');
            const currentBG = selectionBG.find('.mod-lc__selection__current');
            const buttonsFG = selectionFG.find('.mod-lc__selection__item');
            const buttonsBG = selectionBG.find('.mod-lc__selection__item');

            let selectedFGColor = imageStackFG.attr('data-lc-color');
            let selectedBGColor = imageStackBG.attr('data-lc-color');
            function setFGColor(color) {
                selectedFGColor = color;
                currentFG.attr('data-lc-color', color);
                imageStackFGLoad.css({'opacity': 0, 'display': 'block'});
                imageStackFGLoad.attr('src', '/modules/res/les-couleurs/fg/' + color + '.png');
                imageStackFG.attr('data-lc-color', color);
            }

            function setBGColor(color) {
                selectedBGColor = color;
                currentBG.attr('data-lc-color', color);
                imageStackBG.attr('data-lc-color', color);
            }

            buttonsFG.on('click', function () {
                const button = jQuery(this);
                const color = button.data('lc-color');

                buttonsFG.removeClass('mod-lc__selection__item--active');
                button.addClass('mod-lc__selection__item--active');

                setFGColor(color);
            });
            buttonsBG.on('click', function () {
                const button = jQuery(this);
                const color = button.data('lc-color');

                buttonsBG.removeClass('mod-lc__selection__item--active');
                button.addClass('mod-lc__selection__item--active');

                setBGColor(color);
            });

            buttonsFG.on('mouseenter', function () {
                const button = jQuery(this);
                buttonsFG.removeClass('mod-lc__selection__item--active');
                button.addClass('mod-lc__selection__item--active');
            });
            buttonsFG.on('mouseleave', function () {
                buttonsFG.removeClass('mod-lc__selection__item--active');
                buttonsFG.filter('[data-lc-color="' + selectedFGColor + '"]').addClass('mod-lc__selection__item--active');
            });
            buttonsBG.on('mouseenter', function () {
                const button = jQuery(this);
                buttonsBG.removeClass('mod-lc__selection__item--active');
                button.addClass('mod-lc__selection__item--active');
            });
            buttonsBG.on('mouseleave', function () {
                buttonsBG.removeClass('mod-lc__selection__item--active');
                buttonsBG.filter('[data-lc-color="' + selectedBGColor + '"]').addClass('mod-lc__selection__item--active');
            });
		});

        // Modernizr.touchevents

		const resizeFullpager = () => {
			if (window.innerWidth >= 1024 ) { // aka Tablet Quer
				jQuery('.of-pfullpager').css({height: window.innerHeight + 'px'});
			}
			else {
				jQuery('.of-pfullpager').css({height:''});
			}
		}

		jQuery(window).bind('resize', resizeFullpager);
		jQuery(window).bind('orientationchange', resizeFullpager);
    }
})();
