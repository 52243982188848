import clampNumTextlines from '@lib/zzclampNumTextlines';
import '@lib/Hyphenator';
/*global Hyphenator*/

if (typeof window.merkzettelLoader === 'undefined'){
    window.merkzettelLoader = jQuery.Deferred();
}

const AArtikel = function(element) {
    this.debug = false;

    if (this.debug) console.log('new AArtikel');

    // the stored life choice
    this.lifeChoice = null;
    // slider state
    this.activeSlide = 0;
    this.slides = [];
    this.slideWidth = -1;
    this.timerDuration = 5000;
    this.timerElement = null;

    // For Desktop
    this.mzProduktActions = null;

    this.srcRegex = new RegExp(/(9999|2400)/g);
    this.mimeRegex = new RegExp(/mime=jpg/g);

    this.element = jQuery(element);

    // Get the data or die
    var id = element.id;
    this.data = window[id + '_artikelDaten'];
    if (!Array.isArray(this.data) || this.data.length < 1) {
        // No data? Hide parent!
        element.closest('.of-pcontainer').remove();
        return;
    }

    // bind on resize
    jQuery(window).bind('resize', this.rethinkLifeChoice.bind(this));
    jQuery(window).bind('orientationchange', this.rethinkLifeChoice.bind(this));

    // and init initially
    this.rethinkLifeChoice();

};

AArtikel.prototype.startTimer = function() {
    var self = this;
    if (this.timerElement && this.timerDuration > 0) {
        this.timerElement
            .show()
            .animate(
                {
                    width: '100%'
                },
                {
                    duration: this.timerDuration,
                    easing: 'linear',
                    complete: function() {
                        // hide and reset
                        self.timerElement.animate({ opacity: 0 }, 250, function() {
                            self.timerElement.width(0);
                            self.timerElement.css('opacity', 1);
                        });
                        self.slideRight();
                        setTimeout(function() {
                            self.startTimer();
                        }, 1000);
                    }
                }
            );
    }
}

AArtikel.prototype.stopTimer = function() {
    if (this.timerElement) {
        this.timerElement.stop().width(0);
    }
}

AArtikel.prototype.rethinkLifeChoice = function() {
    // check window width and choose desktop or mobile
    var lifeChoice = window.innerWidth < 768 ? 'mobile' : 'desktop';

    // if lifeChoice changes clean and init
    if (lifeChoice !== this.lifeChoice) {
        // quickly set the lifeChoice to prevent multiple calls at once
        this.lifeChoice = lifeChoice;
        // clean up any residue
        this.element.empty();

        if (this.lifeChoice === 'mobile') {
            this.chooseMobile();
        }
        else {
            this.chooseDesktop();
        }
    }
    else if (this.lifeChoice === 'mobile') {
        this.mobileOnResize();
    }
    else {
        this.desktopOnResize();
    }
};

AArtikel.prototype.chooseMobile = function() {
    var self = this;

    this.lifeChoice = 'mobile';

    // Define all the elements
    this.slider = $j('<div class="mod_aartikel_slider"></div>');
    this.slideWrapper = $j('<div class="mod_aartikel_slide_wrapper"></div>').appendTo(this.slider);
    this.sliderLeft = $j('<div class="mod_aartikel_left"></div>');
    this.sliderCounterI = $j('<span class="mod_aartikel_i"></span>');
    this.sliderCounterN = $j('<span class="mod_aartikel_n"></span>');
    this.sliderRight = $j('<div class="mod_aartikel_right"></div>');
    this.timerElement = $j('<div class="mod_aartikel_timer"></div>');

    // Basic events
    if (this.data.length > 1) {
        this.sliderLeft.on('click', function() {
            self.stopTimer();
            self.slideLeft();
        });
        this.sliderRight.on('click', function() {
            self.stopTimer();
            self.slideRight();
        });
        this.slider.swipe({
            allowPageScroll: 'auto',
            swipeRight: function () {
                self.stopTimer();
                self.slideLeft();
            },
            swipeLeft: function () {
                self.stopTimer();
                self.slideRight();
            }
        });
    }

    // initial data update pass
    this.selectedArtikel = this.data[0];
    this.mobileUpdateSlider();

    // Construct the HTML and add it to the page
    $j('<div class="mod_aartikel--mobile"></div>').append(
        this.slider,
        (this.data.length > 1) ?
        $j('<div class="mod_aartikel_slideactions"></div>').append(
            this.timerElement,
            this.sliderLeft,
            $j('<div class="mod_aartikel_iofn"></div>').append(
                this.sliderCounterI,
                CMJSContext.L('aartikel.i_of_n'),
                this.sliderCounterN
            ),
            this.sliderRight
        ) : ''
    ).appendTo(this.element);

    this.mobileOnResize();
    window.hyphenationDone.then(function() {
        Hyphenator.hyphenate(self.slider.get(0), CMJSContext.page.properties.languageCode);

        self.mobileOnResize();
    });
};

AArtikel.prototype.mobileUpdateSlider = function() {
    var self = this;

    self.activeSlide = 0;
    self.slides = [];


    var slide;
    this.data.forEach(function(elem) {
        slide = $j('<div class="mod_aartikel_slide"></div>');
        slide.appendTo(self.slideWrapper);
        self.slides.push(slide);

        var introText = jQuery('<p class="mod_aartikel_intro">' + elem.text + '</p>');
        var url = CMJSContext.page.properties.katalogURL + (!CMJSContext.page.properties.katalogURL.endsWith('/') ? '/' : '') + elem.url;
        var slidebtns = jQuery('<div class="mod_aartikel_btns" style="position: absolute; bottom: 29px"><a id="' + elem.mzid + '" href="#" class="mod_aartikel_btn--mz link-button-light">' + CMJSContext.L('aartikel.merken') + '</a><a href="' + url + '" class="mod_aartikel_btn--link link-button-dark">' + CMJSContext.L('aartikel.to_product') + '</a></div>');
        var img = jQuery('<div class="mod_aartikel_img" style="margin-bottom: 48px; margin-top: 30px;">' + self.imgHtml(elem.bildurl) + '</div>');
        $j('<div class="mod_aartikel_mobmain" style="padding-bottom:63px"></div>').append(
            '<div class="mod_aartikel_hl">' + elem.modellbezeichnung + '</div>',
            '<div class="mod_aartikel_cat">' + elem.kategorie + '</div>',
            img,
            introText,
            slidebtns
        )
        .appendTo(
            slide
        );

        img.find('img').on('load', self.mobileOnResize.bind(self));


        window.merkzettelLoader.done(function(){
            var jsProperties = {
                htmlElement: slidebtns.find('#' + elem.mzid),
                htmlClassNotAdded: '', // class will be removed when the product is on the mz and added if not
                htmlClassAdded: 'merkzettel-is-added', // class will be added when the product is on the mz and removed if not

                pid : elem.mzid,
                //url to the page about the product
                url : CMJSContext.page.properties.katalogURL + (!CMJSContext.page.properties.katalogURL.endsWith('/') ? '/' : '') + elem.url,
                //image to be displayed in the merkzettel (156*170px)
                mediaUrl : elem.bildurl,
                //name of the product usesd as headline
                name : elem.modellbezeichnung,

                description : elem.kategorie,
                //optional additional references
                references : elem.downloads,
                //Optional a category for Bezugsquellensuche. One of:
                //	bzq_baubeschlag,
                //	bzq_sanitaer
                //	bzq_ezk
                bzqFilter : elem.bzqFilter
            };
            var pageProperties = {};

            CMJSContext.Element.destroy('mpa-' + elem.mzid);
            var pa = CMJSContext.Element.factory('MerkzettelProduktActions', 'mpa-' + elem.mzid, jsProperties, pageProperties);
            if (pa !== null) {
                pa.willInit();
            }
        });
    });
    if (self.slides.length > 1) {
        var firstSlide = self.slides[0].clone(true);
        firstSlide.appendTo(self.slideWrapper);
        self.slides.push(firstSlide);
    }

    this.sliderCounterI.text(this.activeSlide + 1);
    this.sliderCounterN.text(this.slides.length - 1);

    this.slideTo(0);

    // Reset timer
    this.stopTimer();
    if (this.slides.length > 1) {
        setTimeout(function() {
            self.startTimer();
        }, self.timerDuration);
    }
};

AArtikel.prototype.mobileOnResize = function() {
    this.slideWidth = Math.ceil(this.slider.width()) + 1;

    var self = this;

    // Höhe neu bestimmen
    var maxHeight = 0;
    this.slides.forEach(function(slide) {
        var mobmain = slide.find('.mod_aartikel_mobmain');
        mobmain.css({'height':'auto'});
        var height = mobmain.outerHeight();
        if (height > maxHeight) maxHeight = height;
    });

    // Breite neu bestimmen und Höhe setzen
    this.slides.forEach(function(slide, i) {
        slide.find('.mod_aartikel_mobmain').css({'height':maxHeight});
        slide.css({
            width: self.slideWidth,
            left: self.slideWidth * i
        });
    });

    this.slideWrapper.css({
        width: this.slideWidth * this.slides.length,
        left: - this.activeSlide * this.slideWidth
    });
};



AArtikel.prototype.chooseDesktop = function() {
    var self = this;

    this.lifeChoice = 'desktop';


    // Define all the elements
    this.mainHL = $j('<div class="mod_aartikel_hl"></div>');
    this.mainCat = $j('<div class="mod_aartikel_cat"></div>');
    this.mainIntro = $j('<p class="mod_aartikel_intro"></p>');
    this.btnMerken = $j('<a href="#" class="mod_aartikel_btn--mz link-button-light">' + CMJSContext.L('aartikel.merken') + '</a>');
    this.btnProdukt = $j('<a href="#" class="mod_aartikel_btn--link link-button-dark">' + CMJSContext.L('aartikel.to_product') + '</a>');
    this.mainImg = $j('<div class="col-sm-6 mod_aartikel_img mod_aartikel_main_img"><picture></picture></div>');

    this.slider = $j('<div class="mod_aartikel_slider"></div>');
    this.slideWrapper = $j('<div class="mod_aartikel_slide_wrapper"></div>').appendTo(this.slider);
    this.sliderLeft = $j('<div class="mod_aartikel_left"></div>');
    this.bullets = $j('<div class="mod_aartikel_bullets"></div>');
    this.sliderRight = $j('<div class="mod_aartikel_right"></div>');
    this.timerElement = $j('<div class="mod_aartikel_timer"></div>');

    // Basic events
    if (this.data.length > 6) {
        this.sliderLeft.on('click', function() {
            self.stopTimer();
            self.slideLeft();
        });
        this.sliderRight.on('click', function() {
            self.stopTimer();
            self.slideRight();
        });
        this.slider.swipe({
            allowPageScroll: 'auto',
            swipeRight: function () {
                self.slideLeft();
            },
            swipeLeft: function () {
                self.slideRight();
            }
        });
    }

    // initial data update pass
    this.selectedArtikel = this.data[0];
    this.desktopUpdateMain();
    this.desktopUpdateSlider();

    // Construct the HTML and add it to the page
    $j('<div class="row mod_aartikel--desktop"></div>').append(
        $j('<div class="col-md-8"></div>').append(
            $j('<div class="mod_aartikel_main"></div>').append(
                $j('<div class="row"></div>').append(
                    $j('<div class="col-sm-6 mod_aartikel_main_content"></div>').append(
                        this.mainHL,
                        this.mainCat,
                        this.mainIntro,
                        $j('<div class="mod_aartikel_btns"></div>').append(
                            this.btnMerken,
                            this.btnProdukt
                        )
                    ),
                    this.mainImg
                )
            )
        ),
        $j('<div class="col-md-4 mod_aartikel_grid"></div>').append(
            this.slider,
            (this.data.length > 6) ? $j('<div class="mod_aartikel_slideactions"></div>').append(
                this.timerElement,
                this.sliderLeft,
                this.bullets,
                this.sliderRight
            ) : ''
        )
    ).appendTo(this.element);

    window.hyphenationDone.then(function() {
        Hyphenator.hyphenate(self.slider.get(0), CMJSContext.page.properties.languageCode);

        self.desktopOnResize();
    });

    this.desktopOnResize();
};

/**
 * Updates the main view with the contents of this.selectedArtikel
 */
AArtikel.prototype.desktopUpdateMain = function() {
    if (this.debug) console.log('desktopUpdateMain', this.selectedArtikel);
    // this.selectedArtikel
    // replace text
    this.mainHL.text(this.selectedArtikel.modellbezeichnung);
    // replace text
    this.mainCat.text(this.selectedArtikel.kategorie);
    // replace text
    this.mainIntro.text(this.selectedArtikel.text);
    this.mainIntro.removeData('text');
    clampNumTextlines(this.mainIntro, 7, true, 360);

    // hide/show Merkzettel
    if (this.debug) console.log('desktopUpdateMain', this.selectedArtikel.mzid, this.selectedArtikel.url, this.selectedArtikel.bildurl, this.selectedArtikel.bzqFilter, this.selectedArtikel.mzid && this.selectedArtikel.url && this.selectedArtikel.bildurl && this.selectedArtikel.bzqFilter)
    if (this.selectedArtikel.mzid && this.selectedArtikel.url && this.selectedArtikel.bildurl && this.selectedArtikel.bzqFilter) {
        var self = this;
        window.merkzettelLoader.done(function(){
            var jsProperties = {
                htmlElement: self.btnMerken,
                htmlClassNotAdded: '', // class will be removed when the product is on the mz and added if not
                htmlClassAdded: 'merkzettel-is-added', // class will be added when the product is on the mz and removed if not

                pid : self.selectedArtikel.mzid,
                //url to the page about the product
                url : CMJSContext.page.properties.katalogURL + (!CMJSContext.page.properties.katalogURL.endsWith('/') ? '/' : '') + self.selectedArtikel.url,
                //image to be displayed in the merkzettel (156*170px)
                mediaUrl : self.selectedArtikel.bildurl,
                //name of the product usesd as headline
                name : self.selectedArtikel.modellbezeichnung,

                description : self.selectedArtikel.kategorie,
                //optional additional references
                references : self.selectedArtikel.downloads,
                //Optional a category for Bezugsquellensuche. One of:
                //	bzq_baubeschlag,
                //	bzq_sanitaer
                //	bzq_ezk
                bzqFilter : self.selectedArtikel.bzqFilter
            };
            var pageProperties = {};

            if (self.mzProduktActions !== null) {
                CMJSContext.Element.destroy(self.mzProduktActions.elemId);
            }
            // Sicher ist sicher :-)
            CMJSContext.Element.destroy('mpa-' + self.selectedArtikel.mzid);
            self.mzProduktActions = CMJSContext.Element.factory('MerkzettelProduktActions', 'mpa-' + self.selectedArtikel.mzid, jsProperties, pageProperties);
            if (self.mzProduktActions !== null) {
                self.mzProduktActions.willInit();
            }

            self.btnMerken.show();
        });
    } else { this.btnMerken.hide(); }
    // hide/show
    if (this.selectedArtikel.url) {
        this.btnProdukt.attr('href', CMJSContext.page.properties.katalogURL + (!CMJSContext.page.properties.katalogURL.endsWith('/') ? '/' : '') + this.selectedArtikel.url).show();
    }
    else { this.btnProdukt.hide(); }
    // replace inner html
    this.mainImg.html(this.imgHtml(this.selectedArtikel.bildurl));
};

AArtikel.prototype.desktopUpdateSlider = function() {
    var self = this;

    self.activeSlide = 0;
    self.slides = [];

    var slide;
    this.data.forEach(function(elem, i) {
        if (i % 6 === 0) {
            slide = $j('<div class="mod_aartikel_slide"></div>');
            slide.appendTo(self.slideWrapper);
            var slideIndex = self.slides.length;
            self.slides.push(slide);

            $j('<div class="mod_aartikel_bullet"></div>')
                .appendTo(self.bullets)
                .on('click', function() {
                    self.slideTo(slideIndex);
                });
        }

        var pngUrl = elem.bildurl.replace('.jpg', '.png');

        var artikelteaser = $j('<div class="mod_aartikel_teaser"></div>').append(
            '<div class="mod_aartikel_teaserimg">' + self.imgHtml(pngUrl, true) + '</div>',
            '<div class="mod_aartikel_teaserhl">' + elem.modellbezeichnung + '</div>',
            '<div class="mod_aartikel_teasercat">' + elem.kategorie + '</div>'
        ).appendTo(
            slide
        ).on('click', function() {
            self.selectedArtikel = elem;

            $j('.mod_aartikel_teaser').removeClass('mod_aartikel_teaser--active');
            $j(this).addClass('mod_aartikel_teaser--active');

            self.desktopUpdateMain();
        });

        if (elem === self.selectedArtikel) {
            artikelteaser.addClass('mod_aartikel_teaser--active');
        }

    });

    if (self.slides.length > 1) {
        var firstSlide = self.slides[0].clone(true);
        firstSlide.appendTo(self.slideWrapper);
        self.slides.push(firstSlide);
    }

    // Reset timer
    self.stopTimer();
    if (self.slides.length > 1) {
        setTimeout(function() {
            self.startTimer();
        }, self.timerDuration);
    }

    this.desktopOnResize();

    this.slideTo(0);
};

AArtikel.prototype.desktopOnResize = function() {
    // Fix some stuff after adding the dom elements
    // set slide Width manually
    this.slideWidth = Math.ceil(this.slider.width() + 0.01);

    var self = this;
    this.slides.forEach(function (slide, i) {
        slide.css({
            width: self.slideWidth,
            left: self.slideWidth * i
        });
    });

    this.slideWrapper.css({
        width: this.slideWidth * this.slides.length,
        left: -this.activeSlide * this.slideWidth
    });

    // Restrict display of category to two lines of text
    var artikelCats = $j('.mod_aartikel_teasercat');
    if (artikelCats.length > 0) {
        // Only allow two lines
        artikelCats.each(function (i, e) {
            var je = jQuery(e);
            clampNumTextlines(je, 2, true, 30);
        });
    }
    if (this.mainIntro != null) {
        clampNumTextlines(this.mainIntro, 7, true, 360);
    }
};

AArtikel.prototype.slideTo = function(slideIndex) {
    var self = this;

    if (self.slideWidth === -1 && typeof self.slideWrapper === "undefined") {
        return;
    }

    // if more than one jump => go there instantly
    if (Math.abs(slideIndex - this.activeSlide) > 1) {
        slideIndex = Math.min(Math.max(0, slideIndex), this.slides.length - 1);
        self.slideWrapper.stop().css({left: - self.slideWidth * slideIndex});
    }
    // Normalfall
    else if (slideIndex >= 0 && slideIndex < this.slides.length) {
        self.slideWrapper.stop().animate({left: - self.slideWidth * slideIndex}, 1000);
    }
    // Overflow nach oben um 1 von activeSlide = this.slides.length - 1 auf this.slides.length
    else if (slideIndex >= this.slides.length) {
        slideIndex = 1; // Implizite definition
        self.slideWrapper.stop().css({left: 0}); // Sprung nach ganz links
        self.slideWrapper.stop().animate({left: - self.slideWidth}, 1000); // rutschen auf Nr. 1
    }
    // Overflow nach unten um 1
    else if (slideIndex < 0) {
        slideIndex = this.slides.length - 2; // Implizite definition
        self.slideWrapper.stop().css({left: - self.slideWidth * (this.slides.length - 1)}); // Sprung nach ganz rechts
        self.slideWrapper.stop().animate({left: - self.slideWidth * slideIndex}, 1000); // rutschen auf letztes echtes Element von hinten
    }

    this.activeSlide = slideIndex;

    if (this.bullets) {
        this.bullets.find('.mod_aartikel_bullet--active').removeClass('mod_aartikel_bullet--active');
        this.bullets.find('.mod_aartikel_bullet').eq((this.activeSlide % (this.slides.length - 1))).addClass('mod_aartikel_bullet--active');
    }
    if (this.sliderCounterI) {
        this.sliderCounterI.text((this.activeSlide % (this.slides.length - 1)) + 1);
    }
};

AArtikel.prototype.slideLeft = function() {
    this.slideTo(this.activeSlide - 1);
};

AArtikel.prototype.slideRight = function() {
    this.slideTo(this.activeSlide + 1);
};


AArtikel.prototype.imgHtml = function(bildurl, teaserImg) {
    if (document.location.host === 'fsb4.ddev.site') {
        bildurl = 'https://www.fsb.de' + bildurl;
    }

    var html = '<picture>';

    var lg = teaserImg ? 121 : 362;
    var md = teaserImg ? 101 : 305;
    var sm = teaserImg ? 119 : 338;
    var xs = teaserImg ? 119 : 701;

    if (bildurl.match(this.mimeRegex)) {
        bildurl = bildurl.replace(this.mimeRegex, 'mime=png');
    }

    html += '<source media="(min-width: 1200px)" data-srcset="' + bildurl.replace(this.srcRegex, lg) + ' 1x, ' + bildurl.replace(this.srcRegex, 2 * lg) + ' 2x">';
    html += '<source media="(min-width: 1024px)" data-srcset="' + bildurl.replace(this.srcRegex, md) + ' 1x, ' + bildurl.replace(this.srcRegex, 2 * md) + ' 2x">';
    html += '<source media="(min-width: 768px)" data-srcset="' + bildurl.replace(this.srcRegex, sm) + ' 1x, ' + bildurl.replace(this.srcRegex, 2 * sm) + ' 2x">';
    html += '<source media="(max-width: 767px)" data-srcset="' + bildurl.replace(this.srcRegex, xs)  + ' 1x, ' + bildurl.replace(this.srcRegex, 2 * xs) + ' 2x">';
    html += '<img alt=" " class="lazyload img-responsive of-image"  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="' + bildurl.replace(this.srcRegex, lg) + '">';


    html += '</picture>';
    return html;
};

// init all the occurences (only 1... ;-))
jQuery('.mod_aartikel').each(function initAArtikelModule(){
    new AArtikel(this);
});
