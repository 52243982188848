'use strict';

import AppDispatcher from '../../_react_common/dispatchers/AppDispatcher';
import SearchConstants from '../constants/SearchConstants.js';
import Evemit from 'evemit';
import valueForKeyPath from '../../_react_common/valueForKeyPath.js';

// Storeage objects


let _showDebug = false;

/**
 * Gibt an, wo die Suche eingesetzt ist
 * Werte: menu / page( / 404)
 */
let _mode = null;
/**
 * Displaymode der Seite für Mobile/Desktop-Unterscheidung
 * Werte: standard/mobile
 */
let _displayMode = null;
/**
 * Responsive mode
 * Werte: resp-1024 oder resp-1280. Siehe SearchConstants!
 */
let _responsiveMode = null;
/**
 * Url der Suchergebnisseite
 */
let _pageSearchResults = null;
/**
 * Anzeigeeinstellungen zum Begrenzen der Darstellung bei flachen Browsern
 */
let _displayedResultParts = {
	showHinweisTexte: true,
	showProductImages: true,
	showHeadlines: true,
	showPageResults: SearchConstants.QUICK_RESULTS_NUM_PAGES,
	enableResultScrolling: false
};



/**
 * Gibt an, ob die Suchbox geöffnet ist
 * Für mode=page immer true
 * Wert: boolean
 */
let _inputOpen = false;
/**
 * Für Page-Darstellung
 * Leer = ungefiltert
 * Ansonsten auf Key der entsprechenden Kategorie gesetzt
 * Initial aus Query-String auslesn
 */
let _activeFacet = SearchConstants.FACET_ALL;
/**
 * Für Page-Darstellung
 * boolean, ob modelle als Liste dargestellt werden sollen oder nicht
 */
let _modelleAsList = false;
/**
 * Aktueller Suchbegriff
 * Initial aus Query-String auslesn
 */
let _q = null;
/**
 * Werden gerade Suchergebnisse gelasen?
 * boolean
 */
let _loading = false;
/**
 * Aktuelle Suchergebnisse
 * Objekt mit Solr-Ergebnissen.
 *
 * Mögliche Ergebnisse:
 * - Genereller Fehlerfall => Als 'Keine Ergebnisse' behandeln
 * { error: 1 }
 * - Im Normalfall erhält man folgende Struktur:
 * {
 *   timings: { (Statistik) },
 *   data: {
 *     q: '1010',
 *     mode: 'modell',
 *     query_selector: 'ist_modell:true AND modellbezeichnung:*1010* AND sprache:de AND markt:de',
 *     query_cms: 'sprache:de AND markt:de',
 *     result_selector: (Ergebnisse),
 *     result_cms: (Ergebnisse)
 *   }
 * }
 *
 * Wobei (Ergebnisse) folgendes sein können:
 * - Fehlerfall: null
 * - Sonst - responseHeader enthält Query-Informationen von Solr, response die Ergebnissdaten
 * Wichtig ist, dass CMS und Produktdaten unterschiedliche Ergebnisse liefern;
 * vom CMS gibt es noch eine Gruppierung nach Seitenbereichen => Siehe <Debug /> Komponente
 * {
 *   responseHeader: {
 *     status: 0,
 *     QTime: 1,
 *     params: {
 *       q: 'ist_modell:true AND modellbezeichnung:*1010* AND sprache:de AND markt:de',
 *       wt: 'json',
 *       rows: '10'
 *     }
 *   },
 *   response: {
 *     numFound: 0,
 *     start: 0,
 *     docs: [ ]
 *   }
 * }
 */
let _results = null;

/**
 * Whether or not the content of Hinweistexts is displayed - Accordeon function for mobile
 * @type {boolean}
 */
let _displayHinweisContent = true;


function resetDisplayedResultParts() {
	if (_displayMode === 'mobile') {
		_displayedResultParts = {
			showHinweisTexte: true,
			showProductImages: false,
			showHeadlines: true,
			showPageResults: 0,
			enableResultScrolling: false
		};
	}
	else {
		_displayedResultParts = {
			showHinweisTexte: true,
			showProductImages: true,
			showHeadlines: true,
			showPageResults: SearchConstants.QUICK_RESULTS_NUM_PAGES,
			enableResultScrolling: false
		};
	}
}

function setResults(results) {
	let cleanedResults = results;

	let resultGroups = valueForKeyPath(cleanedResults, 'data.result_cms.grouped.fsbKategorie.groups');
	if (resultGroups !== undefined) {
		let resultDict = {};

		resultGroups.forEach(group => {
			resultDict[group.groupValue] = group.doclist;
		});

		cleanedResults.data.result_cms.grouped.fsbKategorie.groups = resultDict;
	}

	_results = cleanedResults;
	resetDisplayedResultParts();
}


/*
 * List of available Page Categories (Mensch + Architektur, Produkte...)
*/
let _pageCategories = [];
function setPageCategories(categories) {
	let cleanedCategories = [];

	cleanedCategories.push({
		path: SearchConstants.FACET_ALL,
		title: CMJSContext.L('srch.facet.' + SearchConstants.FACET_ALL),
		displayInFacets: true,
		displayInResults: false,
		displayInMobile: false
	});
	cleanedCategories.push({
		path: SearchConstants.FACET_FAQ,
		displayInFacets: false,
		displayInResults: true,
		displayInMobile: true
	});
	cleanedCategories.push({
		path: SearchConstants.FACET_SELEKTOR,
		title: CMJSContext.L('srch.facet.' + SearchConstants.FACET_SELEKTOR),
		displayInFacets: true,
		displayInResults: true,
		displayInMobile: true
	});

	categories.forEach(cat => {
		let path = cat.path;
		let displayName = cat.title.replace(/^FSB\s.\s/, '');
		cleanedCategories.push({
			path: path,
			title: displayName,
			displayInFacets: true,
			displayInResults: true,
			displayInMobile: true
		});
	});

	cleanedCategories.push({
		path: SearchConstants.FACET_OTHER,
		title: CMJSContext.L('srch.facet.' + SearchConstants.FACET_OTHER),
		displayInFacets: true,
		displayInResults: true,
		displayInMobile: true
	});

	_pageCategories = cleanedCategories;
}


/**
 * The url which provides the Backend for search queries
 */
let _ajaxEndpoint = null;

// Merge our store with Node's Event Emitter
let SearchStore = Object.assign(new Evemit(), {

	setMode: function(newMode) {
		_mode = newMode;
	},

	getState: function() {
		return {
			mode: _mode,
			displayMode: _displayMode,
			responsiveMode: _responsiveMode,
			pageSearchResults: _pageSearchResults,
			inputOpen: _inputOpen,
			activeFacet: _activeFacet,
			modelleAsList: _modelleAsList,
			q: _q,
			loading: _loading,
			results: _results,
			pageCategories: _pageCategories,
			displayedResultParts: _displayedResultParts,
			displayHinweisContent: _displayHinweisContent,
			showDebug: _showDebug
		};
	},

	getAjaxEndpoint: function() {
		return _ajaxEndpoint;
	},

	// Register dispatcher callback
	dispatchToken: AppDispatcher.register(function(action) {

		// Define what to do for certain actions
		switch(action.actionType) {
			case SearchConstants.ACTION_UPDATE_DISPLAY_MODE:
				_displayMode = action.displayMode;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_INIT:
				_mode = action.mode;
				_displayMode = action.displayMode;
				_responsiveMode = action.responsiveMode;
				_ajaxEndpoint = action.ajaxEndpoint;
				_pageSearchResults = action.pageSearchResults;
				if (_mode === SearchConstants.MODE_MENU) {
                    _inputOpen = true;
                }
				setPageCategories(action.pageCategories);
				resetDisplayedResultParts();
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_RESP_CHANGE:
				_responsiveMode = action.responsiveMode;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_RESULTS:
				setResults(action.results);
				_loading = action.loading;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_FOCUS_INPUT:
				_inputOpen = true;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_CLOSE_INPUT:
				_inputOpen = false;
				_q = null;
				_results = null;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_QUERY:
				_activeFacet = SearchConstants.FACET_ALL;
				_q = action.q;
				_loading = action.loading;
				if (!_loading) {
					_results = null;
				}

				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_LOAD_MORE:
				_loading = action.loading;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_FILTER:
				_activeFacet = action.facetKey;
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_REDUCE_QUICK_RESULTS_HEIGHT:
				/*
				let x = {
					showHinweisTexte: true,
					showProductImages: true,
					showHeadlines: true,
					showPageResults: SearchConstants.QUICK_RESULTS_NUM_PAGES
				};
				*/

				if (_displayedResultParts.showPageResults > 0) {
					_displayedResultParts.showPageResults--;
					SearchStore.emit('change');
				}
				else if (_displayedResultParts.showHinweisTexte) {
					_displayedResultParts.showHinweisTexte = false;
					SearchStore.emit('change');
				}
				// else if (_displayedResultParts.showProductImages) {
				// 	_displayedResultParts.showProductImages = false;
				// 	SearchStore.emit('change');
				// }
				else if (_displayedResultParts.showHeadlines) {
					_displayedResultParts.showHeadlines = false;
					SearchStore.emit('change');
				}
				else if (!_displayedResultParts.enableResultScrolling) {
					_displayedResultParts.enableResultScrolling = true;
					SearchStore.emit('change');
				}

				break;

			case SearchConstants.ACTION_RESET_QUICK_RESULTS_HEIGHT:
				resetDisplayedResultParts();
				SearchStore.emit('change');
				break;

			case SearchConstants.ACTION_TOGGLE_HINWEIS_CONTENT:
				_displayHinweisContent = !_displayHinweisContent;
				SearchStore.emit('change');
				break;
		}

		return true;
	})
});
export default  SearchStore;
