'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import FSBLPBlockNavigationVTeaser from '@nav21/components/FSBLPBlockNavigationVTeaser';
import FSBLPBlockNavigationBanner from '@nav21/components/FSBLPBlockNavigationBanner';

class MobileNavigation extends Component {

    constructor(props, context) {
        super(props, context);

        let tree = {
            elemid: 'root',
            children: this.computeTree(props.navContent)
        };


        this.state = {
            tree: tree,
            selection: ['root']
        };
    }

    computeTree(navContent) {
        if (Array.isArray(navContent)) {
            let columnItems = [];
            navContent.forEach((navItem) => {
                switch (navItem.component) {
                    case "FSBLPBlockNavigationErsteEbene":
                        navItem.title = navItem.navigationTitle;
                        if (navItem.elements && navItem.elements.length > 0) {
                            navItem.children = navItem.elements ? this.computeTree(navItem.elements) : false
                        }
                        else if (navItem.url) {
                            navItem.component = 'reference';
                        }
                        columnItems.push(navItem);
                        break;
                    case "FSBLPBlockNavigationLayoutContainerResponsive": {
                        if (navItem.elements) {
                            columnItems = columnItems.concat(this.computeTree(navItem.elements));
                        }
                        break;
                    }
                    case "FSBLPBlockNavigationStandard": {
                        const nestedColumn = this.computeTree(navItem.references);
                        if (navItem.headline) {
                            navItem.title = navItem.headline;
                            navItem.children = nestedColumn;
                            columnItems.push(navItem);
                        } else {
                            columnItems = columnItems.concat(nestedColumn);
                        }
                        break;
                    }
                    case "FSBLPBlockNavigationUebersicht": {
                        const nestedColumn = this.computeTree(navItem.references);
                        if (navItem.headline) {
                            navItem.title = navItem.headline;
                            navItem.children = nestedColumn;
                            columnItems.push(navItem);
                        } else {
                            columnItems = columnItems.concat(nestedColumn);
                        }
                        break;
                    }
                    case "FSBLPBlockNavigationVTeaser":
                    case "FSBLPBlockNavigationBanner":
                        columnItems.push(navItem);
                        break;
                    default:
                        if (navItem.url) {
                            // => Reference
                            navItem.component = 'reference';
                            navItem.elemid = navItem.url;
                            columnItems.push(navItem);
                        }
                }
            });
            return columnItems;
        }

        return [];
    }

    renderColumn(column, columns) {
        if (!columns) {
            columns = [];
        }

        const columnKey = column.elemid;
        const selectionIndex = this.state.selection.indexOf(columnKey);
        let columnStyleClass = 'nav__mobile__block ';
        if (selectionIndex === this.state.selection.length - 1) {
            columnStyleClass += 'nav__mobile__block--current';
        }
        else if (selectionIndex > -1) {
            columnStyleClass += 'nav__mobile__block--pre';
        }
        else {
            columnStyleClass += 'nav__mobile__block--post';
        }

        columns.push(
            <div key={columnKey} className={columnStyleClass}>
                {selectionIndex !== 0 && this.state.selection.length > 0 && <a className="nav__icon__back" key="back" onClick={(e) => { this.setState({selection: this.state.selection.slice(0, -1)}); e.preventDefault(); return false; }}>{CMJSContext.L('mdc.back')}</a>}
                {column.children.map(child => {
                    if (child.children && child.children.length > 0) {
                        return <a className="nav__icon__fwd" key={child.elemid} onClick={(e) => { this.setState({selection: this.state.selection.concat(child.elemid)}); e.preventDefault(); return false; }}>{child.title}</a>
                    }
                    else if (child.component === 'reference') {
                        const url = child.url;
                        const target = url.indexOf('http') === 0 && !(url.includes('www.fsb.de') || url.includes('stage.fsb.de') || url.includes('www.fsbna.com') || url.includes('stage.fsbna.com') || url.includes('www4.') || url.includes('stage4.')) ? '_blank' : '';
                        return <a key={child.elemid} className={child.styleClass} href={url.replaceAll('&amp;', '&')} target={target}>{child.navigationTitle}</a>
                    }
                    else if (child.component === 'FSBLPBlockNavigationVTeaser') {
                        return <FSBLPBlockNavigationVTeaser key={child.elemid} {...child} />;
                    }
                    else if (child.component === 'FSBLPBlockNavigationBanner') {
                        return <FSBLPBlockNavigationBanner key={child.elemid} {...child} />;
                    }
                })}
            </div>
        );

        column.children.forEach(child => child.children && this.renderColumn(child, columns));
        return columns;
    }

	render() {
        return <div className="nav__mobile__container">{this.renderColumn(this.state.tree)}</div>
	}
}
MobileNavigation.propTypes = {
    navContent: PropTypes.array
};

export default MobileNavigation;
