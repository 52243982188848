'use strict';
/* eslint-disable */

import { Component } from 'react';
import jsonMarkup from 'json-markup';
import SearchActions from '../actions/SearchActions.js';
import valueForKeyPath from '../../_react_common/valueForKeyPath.js';
import AppDispatcher from '../../_react_common/dispatchers/AppDispatcher';
import SearchConstants from '../constants/SearchConstants';

class Debug extends Component {
    _query = event => {
		SearchActions.query(event.target.value);
	};

    _changeLanguage = event => {
		let q = this.props.q;
		CMJSContext.page.properties.languageCode = event.target.value;
		SearchActions.query('reset');
		SearchActions.query(q);
	};

    _changeRegion = event => {
		let q = this.props.q;
		CMJSContext.page.properties.region = event.target.value;
		SearchActions.query('reset');
		SearchActions.query(q);
	};

    _changeDisplayMode = event => {
		let q = this.props.q;
		CMJSContext.page.properties.displayMode = event.target.value;
		SearchActions.query('reset');
		SearchActions.query(q);
	};

    _changeLoading = () => {
		AppDispatcher.dispatch({
			actionType: SearchConstants.ACTION_QUERY,
			q: this.props.q,
			loading: !this.props.loading
		});
	};

    render() {

		if (!this.props.showDebug) {
			return null;
		}

		let loading = 'nein';
		if (this.props.loading) {
			loading = 'ja';
		}

		let spellcheck = valueForKeyPath(this.props, 'results.data.result_cms.spellcheck');

		return (
			<div className='search-debug'>
				<h3>Debug</h3>
				<strong>Modus:</strong> {this.props.mode}<br />
				<strong>DisplayModus:</strong> {this.props.displayMode}<br />
				<strong>Suchergebnissseite:</strong> {this.props.pageSearchResults}<br />
				<strong>Input Open:</strong> {this.props.inputOpen ? 'ja' : 'nein'}<br />
				<strong>Gefiltert nach:</strong> {this.props.filtered}<br />
				<strong>Anzeige Modelle als Liste:</strong> {this.props.modelleAsList ? 'ja' : 'nein'}<br />
				<strong>Suchbegriff:</strong> <input type='text' onChange={this._query} value={this.props.q || ''} /><br />
				<strong>Sprache:</strong> <input type='text' onChange={this._changeLanguage} value={CMJSContext.page.properties.languageCode || ''} /><br />
				<strong>Markt:</strong> <input type='text' onChange={this._changeRegion} value={window.WhoAmI.getMarkt() || ''} /><br />
				<strong>DisplayMode:</strong> <input type='text' onChange={this._changeDisplayMode} value={CMJSContext.page.properties.displayMode || ''} /><br />
				<strong>Loading:</strong> {loading} <input type='checkbox' onChange={this._changeLoading} checked={this.props.loading} /><br />
				<strong>Suchergebnisse:</strong><br />
				<div style={{marginLeft: 70}} dangerouslySetInnerHTML={{__html: jsonMarkup(spellcheck)}} />
				<div style={{marginLeft: 70}} dangerouslySetInnerHTML={{__html: jsonMarkup(this.props.results)}} />
				<style type='text/css'>{`
					.search-debug .json-markup {
						line-height: 17px;
						font-size: 13px;
						font-family: monospace;
						white-space: pre;
					}
					.search-debug .json-markup-key {
						font-weight: bold;
					}
					.search-debug .json-markup-bool {
						color: firebrick;
					}
					.search-debug .json-markup-string {
						color: green;
					}
					.search-debug .json-markup-null {
						color: gray;
					}
					.search-debug .json-markup-number {
						color: blue;
					}
					.search-debug input {
						border: 1px solid red;
					}
				`}
				</style>
			</div>
		);
	}
}

export default  Debug;
