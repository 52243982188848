$j(function(){
	function onFragenGruppeOpen(notification) {
		var selector = notification.userInfo().selector;
		$j(selector).stop().slideDown(300, function() {
			$j(selector).removeClass('fragengruppe-hidden').addClass('fragengruppe-visible')
		});
	}
	function onFragenGruppeClose(notification) {
		var selector = notification.userInfo().selector;
		$j(selector).stop().slideUp(300, function() {
			$j(selector).removeClass('fragengruppe-visible').addClass('fragengruppe-hidden')
		});
	}

	CMJSContext.NotificationCenter.defaultCenter.addObserver(null, onFragenGruppeOpen, 'panel-fragengruppe-open', null);
	CMJSContext.NotificationCenter.defaultCenter.addObserver(null, onFragenGruppeClose, 'panel-fragengruppe-close', null);
});