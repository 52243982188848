(function() {

	var fullPager = jQuery('.of-pfullpager');

	// nur, wenn fullpager da sind. Ein Mix aus fullpagern und startseitenblöcken ist nicht vorgesehen... :-o

	if (fullPager.length && fullPager.length > 1) {
		jQuery('body').addClass('of-languagepage--fullpager')

		fullPager.each(function () {

			var pager = jQuery(this);
			var handle = jQuery('.of-pfullpager-handle', pager);

			handle.on('click', function () {
				jQuery('html, body').stop('scroll').animate({scrollTop: (pager.offset().top + pager.height()), queue: 'scroll'}, 'slow').dequeue('scroll');
			});
		});

		if (!Modernizr.touchevents) {
			var scrollTimer;

			const einrasten = function() {
				clearTimeout(scrollTimer);
				scrollTimer = setTimeout(function () {
					if (jQuery('.of-pfullpager-handle:visible').length === 0) {
						return
					}

					var windowHeight = window.innerHeight;
					var scrollBottom = jQuery(window).scrollTop() + windowHeight;
					jQuery('.of-pfullpager:visible').each(function () {
						var pager = jQuery(this);
						var delta = scrollBottom - (pager.position().top + pager.outerHeight());

						if (-300 < delta && delta < 0) {
							jQuery('html, body').stop('scroll').animate({scrollTop: jQuery(window).scrollTop() - delta, queue: 'scroll'}, 'fast').dequeue('scroll');
							return false; // break .each()
						}
						else if (0 < delta && delta < 256) {
							jQuery('html, body').stop('scroll').animate({scrollTop: jQuery(window).scrollTop() - delta, queue: 'scroll'}, 'fast').dequeue('scroll');
							return false; // break .each()
						}
					});

				}, 250);
			}

			jQuery(document).on('scroll', einrasten);
		}

		const resizeFullpager = () => {
			if (window.innerWidth >= 1024 ) { // aka Tablet Quer
				jQuery('.of-pfullpager').css({height: window.innerHeight + 'px'});
			}
			else {
				jQuery('.of-pfullpager').css({height:''});
			}
		}

		jQuery(window).bind('resize', resizeFullpager);
		jQuery(window).bind('orientationchange', resizeFullpager);


		var teaserGrid = jQuery('.of-pteasergrid:last-child');
		if (teaserGrid.length > 0) {
			const resizeTeaserGrid = () => {
				if (window.innerWidth < 1024) {
					teaserGrid.css('marginBottom', 0);
				}
				else {
					var delta = window.innerHeight - (teaserGrid.outerHeight()  + parseFloat(jQuery('main').css('paddingBottom')) + jQuery('footer').outerHeight());
					if (delta > 0) {
						teaserGrid.css('marginBottom', delta);
					}
					else {
						teaserGrid.css('marginBottom', 0);
					}
				}
			}

			// once all images are loaded... -_-
			var resizeOnceImagesAreLoaded = function() {
				if (teaserGrid.find('img.lazyload').length > 0) {
					setTimeout(resizeOnceImagesAreLoaded, 100);
				}
				else {
					resizeTeaserGrid();
				}
			};
			jQuery(resizeOnceImagesAreLoaded);
			window.hyphenationDone.then(resizeTeaserGrid);
			jQuery(window).bind('resize', resizeTeaserGrid);
		}
	}
})();
