'use strict';

// Dependencies
import { Component } from 'react';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';

// SubComponents
import SearchInput from '../Common/SearchInput.jsx';
import PropTypes from 'prop-types';
import Mustache from 'mustache';

export default class Headline extends Component {

	render() {
		let matchesCMS = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
		let matchesSelektor = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;

		let numResults = matchesCMS + matchesSelektor;


		let h2;

		if (this.props.q !== null && this.props.q.length > 0) {
			if (this.props.displayMode === 'mobile') {
				h2 = <h2>{Mustache.render(CMJSContext.L('srch.page.headline.mobile'), {numResults: numResults, q: this.props.q})}</h2>;
			}
			else {
				h2 = <h2>{Mustache.render(CMJSContext.L('srch.page.headline.standard'), {numResults: numResults, q: this.props.q})}</h2>;
			}
		}

		return <div className='row pagesearch__headlines'>
				<div className="col-sm-8">
					<h1>{CMJSContext.L('srch.page.mainHeadline')}</h1>
					{h2}
				</div>
				<div className="col-sm-4">
					<SearchInput {...this.props} />
				</div>
			</div>;
	}
}

Headline.propTypes = {
	q: PropTypes.string,
	pageCategories: PropTypes.object,
	displayMode: PropTypes.string
};
