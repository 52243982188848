'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import SearchActions from '../../actions/SearchActions';

// SubComponents
import SearchInput from '../Common/SearchInput.jsx';
import QuickResults from './QuickResults.jsx';

export default class QuickSearchOverlay extends Component {
    _closeOverlay = () => {
		SearchActions.closeInput();
	};

    _onTransitionEnd = () => {
		if (!this.props.inputOpen) {
			SearchActions.closeInputDone();
		}
	};

    _onResize = () => {
		SearchActions.resetQuickResultsHeight();
	};

    componentDidMount() {
		window.addEventListener('resize', this._onResize);
	}

    componentWillUnmount() {
		window.removeEventListener('resize', this._onResize);
	}

    render() {
		let className = 'row quicksearch__overlay';

		let results = null;
		if (this.props.inputOpen && this.props.q) {
			results = <QuickResults {...this.props} />;
		}

        /*<div className='quicksearch__close' onClick={this._closeOverlay}></div>*/
		return (
			<div className={className} onTransitionEnd={this._onTransitionEnd}>
                {this.props.displayMode === 'standard' ? <div className='col-sm-4 quicksearch__headline'>{CMJSContext.L('srch')}</div> : ''}
                <div className='col-sm-8 col-xs-12 quicksearch__content' ref={(ref) => this.contentRef = ref}>
                    <SearchInput {...this.props} />
                    {results}
                </div>
			</div>
		);
	}

    componentDidUpdate() {
		/*eslint-disable*/

		// let node = ReactDOM.findDOMNode(this);
        //
        // node.style.height = 'auto';
        //
		// let minHeight = 100;
        //
		// let newHeight = 0;
		// if (this.contentRef) {
		// 	let rect = this.contentRef.getBoundingClientRect();
		// 	newHeight += rect.top + rect.height;
		// }
		// newHeight = Math.max(newHeight, minHeight); // minimum height
        //
		// if (this.props.displayMode === 'standard' && newHeight > window.innerHeight) {
		// 	setTimeout(SearchActions.reduceQuickResultsHeight, 10);
		// }
        //
		// node.style.height = newHeight + 'px';
		/*eslint-enable*/
	}
}

QuickSearchOverlay.propTypes = {
	inputOpen: PropTypes.bool,
	displayMode: PropTypes.string,
	q: PropTypes.string
};
