'use strict';

// Dependencies
import { Component } from 'react';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';
import SearchConstants from '../../constants/SearchConstants';
import QuickResultsHighlightStore from '../../stores/QuickResultsHighlightStore';
import PropTypes from 'prop-types';
import Mustache from 'mustache';

// SubComponents
import QuickResultsProductList from './QuickResultsProductList.jsx';
import QuickResultsArea from './QuickResultsArea.jsx';
import Hinweistext from '../Common/Hinweistext.jsx';

export default class QuickResults extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = this._retrieveStateFromStore();

		QuickResultsHighlightStore.on('change', this._storeChanged);
    }

    componentWillUnmount() {
        QuickResultsHighlightStore.off('change', this._storeChanged);
    }

    _storeChanged = () => {
		this.setState(this._retrieveStateFromStore());
	};

    _retrieveStateFromStore = () => {
		return QuickResultsHighlightStore.getState();
	};

    render() {

		let matchesCMS = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
		let matchesSelektor = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
		let numResults = matchesCMS + matchesSelektor;

		// mit Ergebnissen - Laden überlagert
		if (numResults > 0) {
			let bereiche = [];

			if (this.props.displayedResultParts.showHeadlines) {
				this.props.pageCategories.forEach(facet => {
					if (facet.displayInResults) {
						if (facet.path === SearchConstants.FACET_FAQ) {
							if (this.props.displayedResultParts.showHinweisTexte) {
								bereiche.push(<Hinweistext key={'res_' + SearchConstants.FACET_FAQ} {...this.state} {...this.props} />);
							}
						}
						else if (facet.path === SearchConstants.FACET_SELEKTOR) {
							bereiche.push(<QuickResultsProductList key={'res_' + SearchConstants.FACET_SELEKTOR} {...this.state} {...this.props} />);
						}
						else {
							bereiche.push(<QuickResultsArea key={'res_' + facet.path} facetKey={facet.path} facetName={facet.title} {...this.state} {...this.props} />);
						}
					}
				});
			}
			// Sonst nur Produkte anzeigen
			else if (this.props.displayedResultParts.showProductImages) {
				// Wenns dafür Ergebnisse gibt
				this.props.pageCategories.forEach(facet => {
					if (facet.path === SearchConstants.FACET_SELEKTOR && facet.displayInResults) {
						bereiche.push(<QuickResultsProductList key={'res_' + SearchConstants.FACET_SELEKTOR} {...this.state} {...this.props} />);
					}
				});
			}

			// Link Page-Results
			let alleErgebnisse;
			if (numResults > 1) {
				let text = CMJSContext.L('srch.facet.all') + ' ' + Mustache.render(CMJSContext.L('srch.num_results.' + this.props.displayMode), {numResults: numResults});
				let resultLink = this.props.pageSearchResults + (this.props.pageSearchResults.indexOf('?') > -1 ? '&' : '?') + 'query=' + encodeURIComponent(this.props.q);
				let resultLinkClass = 'quicksearch__all-results resulthighlight';
				if (this.state.highlightedFacet !== null) {
					resultLinkClass += ' resulthighlight--dimmed';
				}
				alleErgebnisse = <a className={resultLinkClass} href={resultLink}>{text}</a>;
			}

			// If loading
			let className = 'quicksearch__results' + (this.props.loading ? ' quicksearch__results--loading' : '');
			let loading = !this.props.loading || <div className='quicksearch__loader'><div className='quicksearch__loader__icon'></div></div>;

			return (
				<div className={className} ref={(ref) => this.scrollAreaElement = ref}>
					{bereiche}
					{alleErgebnisse}
					{loading}
				</div>
			);
		}
		// ohne Ergebnissen und ohne Laden (i.e. q.length < 3)
		else if (!this.props.results && !this.props.loading) {
			return null;
		}
		// Keine Ergebnisse und Laden (i.e. bei der Eingabe des 3. Buchstabens)
		else if (!this.props.results) {
			return (
				<div className='quicksearch__results quicksearch__results--loading'>
					<div className='quicksearch__loader'><div className='quicksearch__loader__icon'></div></div>
				</div>
			);
		}
		// Keine Ergebnisse gefunden
		else {
			let noResultloading = !this.props.loading || <div className='quicksearch__loader'><div className='quicksearch__loader__icon'></div></div>;

			return (
				<div className='quicksearch__results quicksearch__results--empty'>
					{CMJSContext.L('srch.no_results')}
					<p>{CMJSContext.L('srch.no_results.p')}</p>
					{noResultloading}
				</div>
			);
		}
	}

    componentDidUpdate() {
		let scrollNode = this.scrollAreaElement;
		if (!scrollNode) {
			return;
		}

		// Remove scrolling
		if (!this.props.displayedResultParts.enableResultScrolling) {
			scrollNode.style.overflowX = 'visible';
			scrollNode.style.overflowY = 'visible';
			scrollNode.style.height = 'auto';
			scrollNode.style.marginRight = '0px';
		}
		// Add Scrolling
		else {
			let rect = scrollNode.getBoundingClientRect();
			if (rect.top + rect.height > window.innerHeight) {
				scrollNode.style.overflowX = 'hidden';
				scrollNode.style.overflowY = 'scroll';
				scrollNode.style.height = (window.innerHeight - rect.top) + 'px';
				scrollNode.style.marginRight = '-' + window.scrollbarwidth + 'px';
			}
		}
	}
}

QuickResults.propTypes = {
	displayedResultParts: PropTypes.object,
	pageCategories: PropTypes.array,
	pageSearchResults: PropTypes.string,
	displayMode: PropTypes.string,
	q: PropTypes.string,
	loading: PropTypes.bool,
	results: PropTypes.object
};
