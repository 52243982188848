'use strict';

// Dependencies
import { Component } from 'react';
import SearchConstants from '../../constants/SearchConstants';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';
import cN from 'classnames';
import SearchActions from '../../actions/SearchActions';
import Mustache from 'mustache';

// SubComponents
import ProduktErgebnis from '../Common/ProduktErgebnis.jsx';
import PropTypes from 'prop-types';

export default class QuickResultsProductList extends Component {
    _onMouseOver = () => {
		SearchActions.resultMouseOver(SearchConstants.FACET_SELEKTOR, null);
		event.stopPropagation();
	};

    _onMouseOut = () => {
		SearchActions.resultMouseOut(SearchConstants.FACET_SELEKTOR, null);
		event.stopPropagation();
	};

    render() {
		let ergebnisse = valueForKeyPath(this.props, 'results.data.result_selector.response.docs') || [];
		let numResults = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;

		// skip if no results
		if (numResults === 0) {
			return null;
		}

		ergebnisse = ergebnisse.slice(0, SearchConstants.QUICK_RESULTS_NUM_PROD);

		// render single result entry
		let ergebnisTags = null;
		if (this.props.displayedResultParts.showProductImages || ergebnisse.length === 1) {
			ergebnisTags = ergebnisse.map(produkt => {
				return <ProduktErgebnis key={produkt.id || produkt.solr_id} produkt={produkt}{...this.props}/>;
			});
		}
		let ergebnis = null;
		if (ergebnisTags !== null) {
			ergebnis = (
				<div className='produktergebnisse clearfix'>
					{ergebnisTags}
				</div>
			);
		}

		let headline = CMJSContext.L('srch.facet.' + SearchConstants.FACET_SELEKTOR) + ' ' + Mustache.render(CMJSContext.L('srch.num_results.' + this.props.displayMode), {numResults: numResults});
		let resultLink = this.props.pageSearchResults + (this.props.pageSearchResults.indexOf('?') > -1 ? '&' : '?') + 'query=' + encodeURIComponent(this.props.q) + '&facet=' + SearchConstants.FACET_SELEKTOR;

		return (
			<div
				className={cN(
					'quicksearch__results__area quicksearch__results__area--selector',
					{
						'resulthighlight': true,
						'resulthighlight--dimmed': this.props.highlightedFacet !== null,
						'resulthighlight--highlighted': this.props.highlightedFacet === SearchConstants.FACET_SELEKTOR
					}
				)}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				<a href={resultLink} className='quicksearch__results__headline'>{headline}</a>
				{ergebnis}
			</div>
		);
	}
}
QuickResultsProductList.propTypes = {
	displayMode: PropTypes.string,
	q: PropTypes.string,
	displayedResultParts: PropTypes.object,
	pageSearchResults: PropTypes.string,
	highlightedFacet: PropTypes.string
};
