'use strict';

// Dependencies
import {Component} from 'react';
import QuickSearchOverlay from '@search/components/QuickSearch/QuickSearchOverlay';
import SearchStore from '@search/stores/SearchStore';

class NavigationSearchWrapper extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = this._retrieveStateFromStore();

        SearchStore.on('change', this._storeChanged);
    }

    componentWillUnmount() {
        SearchStore.off('change', this._storeChanged);
    }

    _storeChanged = () => {
        this.setState(this._retrieveStateFromStore());
    }

    _retrieveStateFromStore() {
        return SearchStore.getState();
    }

    render() {
        return <QuickSearchOverlay {...this.state} />;
    }

}

NavigationSearchWrapper.propTypes = {};

export default NavigationSearchWrapper;

