import Mustache from 'mustache/mustache.js';

const ASP = function(element, injected) {
	var self = this;
	this.debug = false;
	this.injected = injected;

	if (!element) {
		if (self.debug) log("no element passed to ASP!");
		return;
	}

    if (!(element instanceof jQuery)) {
        element = jQuery(element);
    }

	this.categories = ['aussendienst2022', 'innendienst2022', 'contact2022'];
    this.oldCategories = ['hotline', 'obj_archi', 'betreuung', 'investorenberatung', 'gebietsverkaufsleiter', 'innendienst', 'zentral'];

	this.countriesWithPLZ	 = ["DE","AT"];
	this.countriesWithAgencyArea = ["US"];
	this.showPLZ = false;
	this.showAgencyArea = false;

	this.template_asp_result = '';

	if (injected) {
		if (self.debug) log("injecting ASP form via ajax");
		element
			.load(
				'/modules/res/ajax.php',
				{
					'id' : 'ajaxasp',
					'languageCode' : CMJSContext.page.properties.languageCode,
					'module' : 'Aussendienst/AnsprechpartnerForm'
				},
				function(){
					self.init(element);
				}
			);
	}
	else {
		self.init(element);
	}
};

ASP.prototype.init = function(element) {
	var self = this;

    this.template_asp_result = element.find('#tpl-asp-result').innerHTML;
    if (!this.template_asp_result) {
        const elem2 = document.getElementById('tpl-asp-result');
        if (elem2) {
            this.template_asp_result = elem2.innerHTML;
        }
    }

    if (!(element instanceof jQuery)) {
        element = jQuery(element);
    }

	this.formContainer = element.find('form');
	this.resultContainer = element.find('.asp-results');
	this.resultListContainer = this.resultContainer.find('.asp-result-cats');
	this.resultEmptyContainer = this.resultContainer.find('.asp-no-results');
	if (self.debug) log('init ASP', this.formContainer, this.resultContainer, this.resultListContainer);

	// init form stuff
	this.initFormHandler();

	const countryField = jQuery('#asp_country');
	if (countryField.val()) {
		countryField.trigger('change');
	}


	if (this.injected) {
		jQuery('select', this.formContainer).select2();
	}

};

ASP.prototype.initFormHandler = function() {
	var self = this;

	// Je nach Länderauswahl:
	self.formContainer.find('#asp_country').change(function() {
		self.formContainer.find('#asp_country_error').hide();

		if (self.debug) log("countries changed...");
		//self.resultContainer.hide();

		// Entfernung prüfen
		var value = $j(this).val();
		self.showPLZ = jQuery.inArray(value, self.countriesWithPLZ) >= 0;
		self.showAgencyArea = jQuery.inArray(value, self.countriesWithAgencyArea) >= 0;

		if (self.debug) log('selected country', value, self.countriesWithPLZ, jQuery.inArray(value, this.countriesWithPLZ), jQuery.inArray(value, self.countriesWithAgencyArea));

		if (self.showPLZ) {
			if (self.debug) log(value, 'is in countriesWithPLZ');
			self.formContainer.find('#asp_plz_wrapper').animate(
				{
					height : 'show',
					opacity : 'show'
				},
				{
					duration: 250,
					complete: function(){
						self.formContainer.find('#asp_plz').focus();
					}
				}
			);
		}
		else {
			if (self.debug) log(value, 'is NOT in countriesWithPLZ');
			self.formContainer.find('#asp_plz_wrapper').animate(
				{
					height : 'hide',
					opacity : 'hide'
				},
				{
					duration: 250,
					complete: function() {
					}
				}
			);
		}

		if (self.showAgencyArea) {
			if (self.debug) log(value, 'is in countriesWithAgencyArea');
			self.formContainer.find('#asp_usstates_wrapper').animate(
				{
					height : 'show',
					opacity : 'show'
				},
				{
					duration: 250,
					complete: function(){
						self.formContainer.find('#asp_usstates').focus();
					}

				}
			);
		}
		else {
			if (self.debug) log(value, 'is NOT in countriesWithAgencyArea');
			self.formContainer.find('#asp_usstates_wrapper').animate(
				{
					height : 'hide',
					opacity : 'hide'
				},
				{
					duration: 250,
					complete: function() {
					}
				}
			);
		}

		if (!self.showPLZ && !self.showAgencyArea) {
			self.submitForm();
		}

	});

	self.formContainer.find('#asp_usstates').change(function() {
		if (self.showAgencyArea) {
			self.submitForm();
		}
	});

	// on "submit"
	self.formContainer.submit(function(e){
		self.submitForm();
		e.preventDefault();
		return false;
	});


};

ASP.prototype.submitForm = function() {

	var self = this;
	if (self.debug) log('submit ASP Form');

	// reset errors
	self.formContainer.find('.alert').hide();
	self.formContainer.find('.has-error').removeClass('has-error');

	if (!self.formContainer.find('#asp_country').val()) {
		// "Please select a country first";
		self.formContainer.find('#asp_country_wrapper').addClass('has-error');
		self.formContainer.find('#asp_country_error').show();
		return;
	}


	if (self.showPLZ) {
		var plzValue = self.formContainer.find('#asp_plz').val();
		if (!plzValue) {
			// "Please enter a valid area code"
			self.formContainer.find('#asp_plz_wrapper').addClass('has-error');
			self.formContainer.find('#asp_plz_error').show();
			return;
		}
	}
	if (self.showAgencyArea) {
		var value = self.formContainer.find('#asp_usstates').val();
		if (!value) {
			self.formContainer.find('#asp_usstates_wrapper').addClass('has-error');
			self.formContainer.find('#asp_usstates_error').show();
			return;
		}
	}

	// geocode
	self.requestAnsprechpartner();
};

ASP.prototype.requestAnsprechpartner = function() {
	var self = this;

	if (self.debug) log('requestAnsprechpartner');


// compose data
	var data = {};

// form "fields"
	if (self.debug) log(self, self.formContainer);

	data.zg = self.formContainer.find('#asp_zielgruppe').val();
	data.country = self.formContainer.find('#asp_country').val()

	if (self.showPLZ) {
		data.plz = self.formContainer.find('#asp_plz').val();
	}
	if (self.showAgencyArea) {
		data.agencyArea = self.formContainer.find('#asp_usstates').val();
	}
    data.regionCode = window.WhoAmI.getMarkt();

// context
	data.id				= 'bzq';
	data.module			= 'Aussendienst/AjaxAnsprechpartnerData';
	data.languageCode	= CMJSContext.page.properties.languageCode;

	if (self.debug) log("---", data);

// make requests
	var dealerRequest = $j.ajax({
		type: 'POST',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: data
	});

// cleanup

// clean up and show result list
	self.resultContainer.slideUp({
		duration: 200,
		complete: function(){
			if (dealerRequest.state() === 'pending') {
				self.resultListContainer.hide().empty();
				self.resultEmptyContainer.hide();
			}
		}
	});

// process result
	dealerRequest.done(function(response){

		if (self.debug) log("ASP dealerRequest:" , response);
		if ((response.status && response.status === 'NOK') || (response.matches && response.matches.length === 0)) {
			self.resultListContainer.hide().empty();
			self.resultEmptyContainer.show();
		}
		else {
			self.resultEmptyContainer.hide();
			self.resultListContainer.empty().show();


			// For each category..
			var categoryOpened = false;
			var lastCategory = null;

            let displayCategories = self.categories;
            if (data.country === 'US') {
                displayCategories = self.oldCategories;
            }

			$j.each(displayCategories, function(i, cat) {

                if (!response.matches[cat] || response.matches[cat].length < 1) {
                    return;
                }

                const isAkkordeon = !cat.includes('2022') && window.WhoAmI.getMarkt() !== 'na';

                const catResults = response.matches[cat];

                const list = jQuery('<div class="asp-list row of-pvteaser-row"></div>');
				// with results
                $j.each(catResults, function(j, result){
					list.append(self.getResultHtml(result, cat));
				});

				if (catResults.length > 0) {
					const category = jQuery('<div class="of-pcontainer"></div>');
                    if (isAkkordeon) {
                        category.addClass('of-pcontainer--akkordeon');
                    }
					category.append('<h3>' + CMJSContext.L('asp_category_' + cat) + '</h3>');
                    const cPC = jQuery('<div class="childParagraphsContainer"></div>');
                    list.appendTo(cPC);
                    cPC.appendTo(category);
                    category.appendTo(self.resultListContainer);

					// Automatische Kategorieöffnung... Oh man
                    if (isAkkordeon) {
                        if (self.debug) console.log('categoryOpened', categoryOpened, cat, data.zg);
                        if (!categoryOpened
                            &&
                            (
                                (
                                    cat === 'hotline'
                                    &&
                                    (
                                        data.zg === 'architekt_planer' // new
                                        ||
                                        data.zg === 'architekt' // old
                                        ||
                                        data.zg === 'tga_fachplaner.elektrotechnik' // old
                                    )
                                )
                                ||
                                (
                                    cat === 'obj_archi'
                                    &&
                                    (
                                        data.zg === 'architekt_planer' // new
                                        ||
                                        data.zg === 'architekt' // old
                                    )
                                )
                                ||
                                (
                                    cat === 'betreuung'
                                    &&
                                    (
                                        data.zg === 'fachhaendler.baubeschlag' // old
                                        ||
                                        data.zg === 'fachhaendler.elektronik' // old
                                        ||
                                        data.zg === 'fachhaendler.sanitaer' // old
                                        ||
                                        data.zg === 'fachhaendler.sicherheitstechnik' // old
                                        ||
                                        data.zg === 'tga_fachplaner.sanitaertechnik' // old
                                        ||
                                        data.zg === 'tga_fachplaner.anlagengruppenuebergreifend' // old
                                        ||
                                        data.zg === 'fachhaendler' // new
                                        ||
                                        data.zg === 'architekt_planer' // new
                                        ||
                                        data.zg === 'verarbeiter_handwerker' // old and new
                                    )
                                )
                                ||
                                (
                                    cat === 'investorenberatung'
                                    &&
                                    data.zg === 'investor' // new
                                )
                                ||
                                (
                                    cat === 'gebietsverkaufsleiter'
                                    &&
                                    (
                                        data.zg === 'investor' // new
                                        ||
                                        data.zg === 'generalunternehmer' // old
                                    )
                                )
                            )


                        ) {
                            categoryOpened = true;
                            category.addClass('of-pcontainer--akkordeon-open');
                        }

                        lastCategory = category;
                    }
				}
			});

			if (!categoryOpened && lastCategory !== null) {
				lastCategory.addClass('of-pcontainer--akkordeon-open');
			}
		}

		// show result list
		self.resultContainer.slideDown({
			complete: function(){
				window.initAkkordeons();
			}
		});
	});

};

ASP.prototype.getResultHtml = function(data, cat) {
	var self = this;

	if (self.debug) log("data", data);

	// enrich data with additional display properties
	/*
		Fields in Template:
			{{oddOrEven}}
			{{image_url}}
			{{givenName}}
			{{name}}
			{{company}}
			{{supplement}}
			{{supplement2}}
			{{street}}
			{{streetNumber}}
			{{plz}}
			{{city}}
			{{country}}
			{{phone}}
			{{fax}}
			{{mobile}}
			{{email}}
			{{url}}
			{{display_url}}
			{{function}}
			{{doTrack}}

			data.tags
	 */

	var viewData = data;



    // Tracken?
    let doTrack = false;
    if (cat === 'obj_archi'
    ||  cat === 'betreuung'
    ||  cat === 'investorenberatung'
    ||  cat === 'gebietsverkaufsleiter'
    ||  (cat === 'zentral' && Array.isArray(data.function) && (
            data.function.includes('asp_zentr_inland')
            || data.function.includes('asp_zentr_sanitaer')
        ))
    ) {
        doTrack = true;
    }

	// phone
	if (data.phone) {
		viewData['link_phone'] = data.phone.replace(/[^0-9+]/g, '');
	}
	if (data.mobile) {
		viewData['link_mobile'] = data.mobile.replace(/[^0-9+]/g, '');
	}


	// url
	var displayUrl = '';
	var linkUrl = '';
	if (data.url && data.url.substring(0, 4) === "http") {
		displayUrl = data.url.substring(data.url.indexOf('/') + 2);
		linkUrl = data.url;
	}
	else {
		displayUrl = data.url;
		linkUrl = 'http://' + data.url;
	}
	viewData['link_url'] = linkUrl;
	viewData['display_url'] = displayUrl;

	// Workaround für unpersönliche Adressen
	if (viewData['name'] === 'Unknown') {
		viewData['name'] = viewData['company'];
		viewData['givenName'] = null;
		viewData['company'] = null;
	}

	var tags_betreuung = ['asp_obj_fachhandel', 'asp_berater_elektronik', 'asp_keyaccount_elektronik', 'asp_berater_sanitaer'];
	var tags_zentral = ['asp_zentr_inland','asp_zentr_export','asp_zentr_elektronik','asp_zentr_sanitaer','asp_zentr_pm_beschlag','asp_zentr_pm_elektronik','asp_zentr_pm_sanitaer','asp_zentr_architektur','asp_zentr_marketing','asp_zentr_online','asp_zentr_personal','asp_zentr_einkauf','asp_zentr_edv'];

	// Ausgabe Funktionen
    let fn = '';
	if (cat === 'betreuung') {
		for (let i = 0; i < tags_betreuung.length; i++) {
			const tag = tags_betreuung[i];
			if (data.function.indexOf(tag) >= 0) {
				const localizedTag = CMJSContext.L(tag);
				if (fn === '') fn = localizedTag;
				else fn += ', ' + localizedTag;
			}
		}
		viewData['function'] = fn;
	}
	else if (cat === 'zentral') {
		for (let i = 0; i < tags_zentral.length; i++) {
            const tag = tags_zentral[i];
			if (data.function.indexOf(tag) >= 0) {
                const localizedTag = CMJSContext.L(tag);
				if (fn === '') fn = localizedTag;
				else fn += ', ' + localizedTag;
			}
		}
		viewData['function'] = fn;
	}
	else {
		viewData['function'] = '';
	}

	const rendered = Mustache.render(this.template_asp_result, viewData);
	if (doTrack) {
        const elem = jQuery(rendered);

        elem.find('a.no-transition-link').on('click', () => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'Ansprechpartner', {
                    event_category: CMJSContext.L('asp_category_' + cat),
                    event_label: (viewData.function ? viewData.function + ' > ' : '') + viewData.givenName + ' ' + viewData.name
                });
            }
        });

        return elem;
    }

	return rendered;
};

export default ASP;
