'use strict';

// Dependencies
import { Component } from 'react';
import SearchActions from '../../actions/SearchActions';
import SearchConstants from '../../constants/SearchConstants';
import cn from 'classnames';
import valueForKeyPath from '../../../_react_common/valueForKeyPath';
import PropTypes from 'prop-types';

// SubComponents
// none...

export default class SearchInput extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			'highlightedSuggestion': null,
			'hasFocus': false
		};
    }

    _onChange = event => {
		SearchActions.query(event.target.value);
		this.setState({'highlightedSuggestion': null});
	};

    _onKeyDown = keyDown => {

		if (keyDown.key === 'Escape' && this.props.inputOpen) {
			keyDown.preventDefault();
			SearchActions.closeInput();
			this.setState({'highlightedSuggestion': null});
			return;
		}

		let solrSuggestions = valueForKeyPath(this.props, 'results.data.result_cms.spellcheck.collations') || [];
		let selectNext = false, i, suggestion;
		if (solrSuggestions.length > 0) {
			if (keyDown.key === 'ArrowDown') {
				keyDown.preventDefault();
				if (this.state.highlightedSuggestion === null) {
					this.setState({'highlightedSuggestion': solrSuggestions[1].trim()}); // 0 is 'collation'
				}
				else {
					selectNext = false;
					for (i = 1; i < solrSuggestions.length; i += 2) {
						suggestion = solrSuggestions[i].trim();
						if (selectNext) {
							this.setState({'highlightedSuggestion': suggestion});
							break;
						}
						if (suggestion === this.state.highlightedSuggestion) {
							selectNext = true;
						}
					}
				}
			}
			else if (keyDown.key === 'ArrowUp') {
				keyDown.preventDefault();
				if (this.state.highlightedSuggestion === null) {
					this.setState({'highlightedSuggestion': solrSuggestions[solrSuggestions.length - 1].trim()});
				}
				else {
					selectNext = false;
					for (i = solrSuggestions.length - 1; i > 0; i -= 2) {
						suggestion = solrSuggestions[i].trim();
						if (selectNext) {
							this.setState({'highlightedSuggestion': suggestion});
							break;
						}
						if (suggestion === this.state.highlightedSuggestion) {
							selectNext = true;
						}
					}
				}
			}
			else if (keyDown.key === 'Enter' && this.state.highlightedSuggestion !== null) {
				keyDown.preventDefault();
				SearchActions.query(this.state.highlightedSuggestion);
				this.setState({'highlightedSuggestion': null});
			}
		}
	};

    _onClearClick = () => {
		SearchActions.query(null);

		if (this.textInput) {
			if (this.props.displayMode === 'mobile') {
				this.textInput.value = '';
			}
			this.textInput.focus();
		}
	};

    _onFocus = () => {
		this.setState({ 'hasFocus': true });
	};

    _onBlur = () => {
		this.setState({ 'hasFocus': false });
	};

    _onSuggestionClick = suggestion => {
		SearchActions.query(suggestion);
	};

    _onSuggestionMouseOver = suggestion => {
		if (this.state.highlightedSuggestion !== suggestion) {
			this.setState({'highlightedSuggestion': suggestion});
		}
	};

    _onSuggestionMouseOut = suggestion => {
		if (this.state.highlightedSuggestion === suggestion) {
			this.setState({'highlightedSuggestion': null});
		}
	};

    render() {
		let clear = null;
		if (this.props.q) {
			clear = <div className='searchinput__input__clear' onClick={this._onClearClick} />;
		}

		let suggestionList = null;

		if (((this.props.mode === SearchConstants.MODE_PAGE && this.state.hasFocus) || this.props.inputOpen) && this.props.displayMode !== 'mobile') {
			let solrSuggestions = valueForKeyPath(this.props, 'results.data.result_cms.spellcheck.collations') || [];
			let suggestions = solrSuggestions.map(suggestion => {
				suggestion = suggestion.trim();
				if (suggestion === 'collation') {
					return null;
				}
				else {
					return <div key={suggestion}
						className={cn({
							'searchinput__suggestion': true,
							'searchinput__suggestion--dimmed': this.state.highlightedSuggestion !== null && this.state.highlightedSuggestion !== suggestion,
							'searchinput__suggestion--highlighted': this.state.highlightedSuggestion === suggestion
						})}
						onClick={this._onSuggestionClick.bind(this, suggestion)}
						onMouseOver={this._onSuggestionMouseOver.bind(this, suggestion)}
						onMouseOut={this._onSuggestionMouseOut.bind(this, suggestion)}
					>{suggestion}</div>;
				}
			});

			if (suggestions.length > 0) {
				suggestionList = <div className='searchinput__suggestionlist'>{suggestions}</div>;
			}
		}

		let label;
		if (this.props.mode === SearchConstants.MODE_PAGE) {
			label = <label htmlFor='searchinput__field' className='searchinput__label'>{CMJSContext.L('srch.page.new_search')}</label>;
		}

		let theInput;
		if (this.props.displayMode === 'mobile') {
			theInput = <input
				id='searchinput__field'
				ref={(input) => { this.textInput = input; }}
				className='searchinput__field'
				type='text'
				onChange={this._onChange}
				onKeyDown={this._onKeyDown}
				onFocus={this._onFocus}
				onBlur={this._onBlur}
				value={this.props.q || ''}
				placeholder={CMJSContext.L('srch.placeholder.' + this.props.mode + '.' + this.props.displayMode)}
			/>;
		}
		else {
			theInput = <input
				id='searchinput__field'
				ref={(input) => { this.textInput = input; }}
				className='searchinput__field'
				type='text'
				onChange={this._onChange}
				onKeyDown={this._onKeyDown}
				onFocus={this._onFocus}
				onBlur={this._onBlur}
				value={this.state.highlightedSuggestion || this.props.q || ''}
				placeholder={CMJSContext.L('srch.placeholder.' + this.props.mode + '.' + this.props.displayMode)}
			/>;
		}

		return (
			<div className='searchinput'>
				{label}
				{theInput}
				{clear}
				{suggestionList}
			</div>
		);
	}

	componentDidMount() {
        if (this.textInput) {
            this.textInput.focus();
        }
    }

    componentDidUpdate(prevProps) {
		// Add focus on input element
		if (this.props.mode === SearchConstants.MODE_MENU && prevProps.inputOpen === false && this.props.inputOpen === true) {
            if (this.textInput) {
                this.textInput.focus();
            }
		}
	}
}

SearchInput.propTypes = {
	mode: PropTypes.string,
	q: PropTypes.string,
	displayMode: PropTypes.string,
	inputOpen: PropTypes.bool,
	highlightedFacet: PropTypes.string,
	highlightedResult: PropTypes.string
};
