import loadGoogleMaps from '@lib/loadGoogleMaps';
import MapStyles from '@lib/googleMapsStyles';

window.PStaticMap = function(containerid, latCenter, longCenter, latMarker, longMarker, zoom) {
	loadGoogleMaps().then(function () {
		"use strict";

		// Styles a map in night mode.
		var map = new google.maps.Map(document.getElementById(containerid), {
			center: { lat: latCenter, lng: longCenter },
			clickableIcons: false,
			disableDefaultUI: true,
			disableDoubleClickZoom: true,
			draggable: false,
			keyboardShortcuts: false,
			scrollwheel: false,
			zoom: zoom || 12,
			styles: MapStyles
		});

		new google.maps.Marker({
			position: { lat: latMarker, lng: longMarker },
			icon: {
				url: CMJSContext.page.properties.ajaxBase + '/modules/res/res/img/pins/ico_pin@2x.png',
				size: new google.maps.Size(50, 74),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(12.5, 37),
				scaledSize: new google.maps.Size(25, 37)
			},
			map: map
		});

		function responsiveMapAdjustments() {
			if (window.innerWidth < 768) {
				// XS - Just center on marker
				map.setCenter({ lat: latMarker, lng: longMarker });
			}
			else if (window.innerWidth < 1024) {
				// SM
				//map.setCenter({ lat: latMarker, lng: longMarker });
				map.setCenter({ lat: latCenter, lng: longCenter });
			}
			else if (window.innerWidth < 1200) {
				// MD
				//map.setCenter({ lat: latCenter, lng: longCenter });
				map.setCenter({ lat: latMarker, lng: longMarker });
			}
			else {
				// LG
				map.setCenter({ lat: latCenter, lng: longCenter });
				//map.setCenter({ lat: latMarker, lng: longMarker });
			}

			google.maps.event.trigger(map, "resize");
		}

		google.maps.event.addDomListener(window, 'resize', responsiveMapAdjustments);
		responsiveMapAdjustments();
		jQuery(window).bind('resize', responsiveMapAdjustments);
		jQuery(window).bind('orientationchange', responsiveMapAdjustments);
	});
}

// https://developers.google.com/maps/documentation/javascript/markers?hl=de#icons
