'use strict';

import { Dispatcher } from 'flux';

let AppDispatcher = Object.assign(new Dispatcher(), {
	handleViewAction: function(action) {
		this.dispatch({
			source: 'VIEW_ACTION',
			action: action
		});
	},

	handleServerAction: function(action) {
		this.dispatch({
			source: 'SERVER_ACTION',
			action: action
		});
	}
});

export default AppDispatcher;
