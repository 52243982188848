'use strict';

import NavigationConstants from './NavigationConstants';
import AppDispatcher from '../_react_common/dispatchers/AppDispatcher';

const NavigationActions = {

	_init: function(navContent, displayMode) {
		AppDispatcher.dispatch({
			actionType: NavigationConstants.ACTION_INIT,
			displayMode: displayMode,
            navContent: navContent
		});
	},
    updateDisplayMode(displayMode) {
        AppDispatcher.dispatch({
            actionType: NavigationConstants.ACTION_UPDATE_DISPLAY_MODE,
            displayMode: displayMode
        });
    },

    toggleMenuItem(item) {
        AppDispatcher.dispatch({
            actionType: NavigationConstants.ACTION_TOGGLE_MENU_ITEM,
            item: item
        });
    },
    closeNavigation() {
        AppDispatcher.dispatch({
            actionType: NavigationConstants.ACTION_TOGGLE_MENU_ITEM,
            item: null
        });
    }
};

export default NavigationActions;
