'use strict';

CMJSContext.LL({

	'de': {
		'srch': 'Suche',
		'srch.tooltip': 'Suche öffnen',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'Suchbegriff oder Modellnummer eingeben',
		'srch.placeholder.menu.mobile': 'Suchbegriff / Modellnummer',
		'srch.placeholder.page.standard': 'Suchbegriff / Modellnummer',
		'srch.placeholder.page.mobile': 'Suchbegriff / Modellnummer',
		'srch.placeholder.404.standard': 'Suchbegriff / Modellnummer',
		'srch.placeholder.404.mobile': 'Suchbegriff / Modellnummer',

		'srch.page.mainHeadline': 'Suchergebnisse',

		'srch.page.headline.standard': 'Ihre Suche nach „{{q}}“ ergab {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}keine{{/numResults}} Treffer',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}keine{{/numResults}} Treffer',
		'srch.page.new_search': 'Neue Suche',
		'srch.no_results': 'Keine Ergebnisse',
		'srch.no_results.p': 'Zu dem eingegeben Begriff konnten leider keine Seiten gefunden werden.\nBitte korrigieren Sie eventuelle Rechtschreibfehler oder wählen Sie einen anderen Suchbegriff.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Treffer anzeigen)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'Alle Suchergebnisse',
		'srch.facet.selector': 'Digitaler Katalog',
		'srch.facet.other': 'Sonstige',

		// Suchergebnissseite
		'srch.load_more': 'Weitere Treffer anzeigen'
	},
	'en': {
		'srch': 'Search',
		'srch.tooltip': 'Open search',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'enter target word or model number',
		'srch.placeholder.menu.mobile': 'target word / model number',
		'srch.placeholder.page.standard': 'target word / model number',
		'srch.placeholder.page.mobile': 'target word / model number',
		'srch.placeholder.404.standard': 'target word / model number',
		'srch.placeholder.404.mobile': 'target word / model number',

		'srch.page.mainHeadline': 'Search results',

		'srch.page.headline.standard': 'Your search „{{q}}“ found {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}no{{/numResults}} result',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}no{{/numResults}} result',
		'srch.page.new_search': 'New search',
		'srch.no_results': 'No results',
		'srch.no_results.p': 'No result could be found for the entered term.\nPlease correct possible spelling mistakes or choose a different target word.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Show results)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'All search results',
		'srch.facet.selector': 'Digital catalog',
		'srch.facet.other': 'Other',

		// Suchergebnissseite
		'srch.load_more': 'Show further results'
	},
	'en-us': {
		'srch': 'Search',
		'srch.tooltip': 'Open search',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'enter target word or model number',
		'srch.placeholder.menu.mobile': 'target word / model number',
		'srch.placeholder.page.standard': 'target word / model number',
		'srch.placeholder.page.mobile': 'target word / model number',
		'srch.placeholder.404.standard': 'target word / model number',
		'srch.placeholder.404.mobile': 'target word / model number',

		'srch.page.mainHeadline': 'Search results',

		'srch.page.headline.standard': 'Your search „{{q}}“ found {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}no{{/numResults}} result',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}no{{/numResults}} result',
		'srch.page.new_search': 'New search',
		'srch.no_results': 'No results',
		'srch.no_results.p': 'No result could be found for the entered term.\nPlease correct possible spelling mistakes or choose a different target word.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Show results)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'All search results',
		'srch.facet.selector': 'Digital catalog',
		'srch.facet.other': 'Other',

		// Suchergebnissseite
		'srch.load_more': 'Show further results'
	},
	'fr': {
		'srch': 'recherche',
		'srch.tooltip': 'ouvrir recherche',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'saisir mot de recherche ou référence de produit',
		'srch.placeholder.menu.mobile': 'mot de recherche / référence de produit',
		'srch.placeholder.page.standard': 'mot de recherche / référence de produit',
		'srch.placeholder.page.mobile': 'mot de recherche / référence de produit',
		'srch.placeholder.404.standard': 'mot de recherche / référence de produit',
		'srch.placeholder.404.mobile': 'mot de recherche / référence de produit',

		'srch.page.mainHeadline': 'Résultats de recherche',

		'srch.page.headline.standard': 'votre recherche de „{{q}}“ a donné {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}0{{/numResults}} résultat(s)',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}0{{/numResults}} résultat(s)',
		'srch.page.new_search': 'Nouvelle recherche',
		'srch.no_results': 'pas de résultat',
		'srch.no_results.p': 'Le mot de recherche ne permet pas de trouver de pages.\nVeuillez svp corriger une éventuelle faute d’orthographe ou choisissez un autre mot de recherche.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} afficher les résultats)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'Tous les résultats de recherche',
		'srch.facet.selector': 'Catalogue digital',
		'srch.facet.other': 'divers',

		// Suchergebnissseite
		'srch.load_more': 'Montrer plus de résultats'
	},
	'nl': {
		'srch': 'Zoeken',
		'srch.tooltip': 'Zoeken openen',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'Zoekterm of model invoeren',
		'srch.placeholder.menu.mobile': 'Zoekterm / modelnummer',
		'srch.placeholder.page.standard': 'Zoekterm / modelnummer',
		'srch.placeholder.page.mobile': 'Zoekterm / modelnummer',
		'srch.placeholder.404.standard': 'Zoekterm / modelnummer',
		'srch.placeholder.404.mobile': 'Zoekterm / Modelnummer',

		'srch.page.mainHeadline': 'Zoekresultaten',

		'srch.page.headline.standard': 'Uw zoekopdracht naar„{{q}}“ heeft {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}geen{{/numResults}} resultaten opgeleverd',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}geen{{/numResults}} resulaten',
		'srch.page.new_search': 'Nieuwe zoekopdracht',
		'srch.no_results': 'Geen resultaten',
		'srch.no_results.p': 'De ingevoerde zoekterm heeft helaas geen resultaten opgeleverd.\nCorrigeer eventuele spelfouten of kies een andere zoekterm.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Bekijk resultaten)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'Alle resultaten',
		'srch.facet.selector': 'Digitale catalogus',
		'srch.facet.other': 'Overige',

		// Suchergebnissseite
		'srch.load_more': 'Bekijk meer resultaten'
	},
	'it': {
		'srch': 'Ricerca',
		'srch.tooltip': 'Ricerca aperta',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'Inserire il termine di ricerca o il numero di modello',
		'srch.placeholder.menu.mobile': 'Parola chiave di ricerca / Numero di modello',
		'srch.placeholder.page.standard': 'Parola chiave di ricerca / Numero di modello',
		'srch.placeholder.page.mobile': 'Parola chiave di ricerca / Numero di modello',
		'srch.placeholder.404.standard': 'Parola chiave di ricerca / Numero di modello',
		'srch.placeholder.404.mobile': 'Parola chiave di ricerca / Numero di modello',

		'srch.page.mainHeadline': 'Risultati della ricerca',

		'srch.page.headline.standard': 'La ricerca di "{{{{{q}}}" restituito {{#numResults}}{{{numResults}}{{{{/numResults}{{^numResults}}}} No{{{{/numResults}} Hits',
		'srch.page.headline.mobile': '{{#numResults}}{{{{numResults}}{{{{/numResults}}{{{^numResults}}} No{{{{{/numResults}} Hits',
		'srch.page.new_search': 'Nuova ricerca',
		'srch.no_results': 'Nessun risultato',
		'srch.no_results.p': 'Purtroppo non è stato possibile trovare pagine per il termine inserito. \nSiprega di correggere eventuali errori di ortografia o selezionare un termine di ricerca diverso.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Mostra risultati)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'Tutti i risultati della ricerca',
		'srch.facet.selector': 'Catalogo Digitale',
		'srch.facet.other': 'Altro',

		// Suchergebnissseite
		'srch.load_more': 'Mostra altri risultati'
	},
	'es': {
		'srch': 'Buscar',
		'srch.tooltip': 'Abrir búsqueda',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'Introduzca el término de búsqueda o el número de modelo',
		'srch.placeholder.menu.mobile': 'Buscar palabra clave / Número de modelo',
		'srch.placeholder.page.standard': 'Buscar palabra clave / Número de modelo',
		'srch.placeholder.page.mobile': 'Buscar palabra clave / Número de modelo',
		'srch.placeholder.404.standard': 'Buscar palabra clave / Número de modelo',
		'srch.placeholder.404.mobile': 'Buscar palabra clave / Número de modelo',

		'srch.page.mainHeadline': 'Resultados de búsqueda',

		'srch.page.headline.standard': 'Su búsqueda de "{{{q}}" ha devuelto {{#numResults}}{{{numResults}}{{/numResults}}{{^numResults}}}}No{{{/numResults}} Hits',
		'srch.page.headline.mobile': '{{#numResults}}{{{{numResults}}{{{{/numResults}}{{{^numResults}}} No{{{{{/numResults}} Hits',
		'srch.page.new_search': 'Nueva Búsqueda',
		'srch.no_results': 'No hay resultados',
		'srch.no_results.p': 'Lamentablemente, no se han encontrado páginas para el término introducido. Por favor, corrija cualquier error ortográfico o seleccione un término de búsqueda diferente.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} Mostrar resultados)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'Todos los resultados',
		'srch.facet.selector': 'Catálogo Digital',
		'srch.facet.other': 'Otros',

		// Suchergebnissseite
		'srch.load_more': 'Mostrar más visitas'
	},
	'ru': {
		'srch': 'поиск',
		'srch.tooltip': 'открыть поиск',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': 'Введите ключевое слово или номер модели',
		'srch.placeholder.menu.mobile': 'ключевое слово или номер модели',
		'srch.placeholder.page.standard': 'ключевое слово/ номер модели',
		'srch.placeholder.page.mobile': 'ключевое слово/ номер модели',
		'srch.placeholder.404.standard': 'ключевое слово/ номер модели',
		'srch.placeholder.404.mobile': 'ключевое слово/ номер модели',

		'srch.page.mainHeadline': 'Search results',

		'srch.page.headline.standard': 'Ваш поиск „{{q}}“ отдал{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}никакие{{/numResults}} попадание',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}никакие{{/numResults}} попадание',
		'srch.page.new_search': 'новый поиск',
		'srch.no_results': 'нет результатов',
		'srch.no_results.p': 'нет страниц по Вашему запросу.\nПожалуйста исправьте.',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} посмотреть результаты)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': 'результаты поиска',
		'srch.facet.selector': 'цифровой каталог',
		'srch.facet.other': 'другой',

		// Suchergebnissseite
		'srch.load_more': 'посмотреть больше резултатов'
	},
	'zh': {
		'srch': '搜索',
		'srch.tooltip': '打开搜索',

		// Platzhalter für div. Eingabefelder. Mobile und 404-Seite dürfen nicht länger werden.
		'srch.placeholder.menu.standard': '输入搜索关键词或型号',
		'srch.placeholder.menu.mobile': '搜索词/型号',
		'srch.placeholder.page.standard': '搜索词/型号',
		'srch.placeholder.page.mobile': '搜索词/型号',
		'srch.placeholder.404.standard': '搜索词/型号',
		'srch.placeholder.404.mobile': '搜索词/型号',

		'srch.page.mainHeadline': '搜索结果',

		'srch.page.headline.standard': '您的搜索 „{{q}}“ 发现 {{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}没有{{/numResults}} 匹配',
		'srch.page.headline.mobile': '{{#numResults}}{{numResults}}{{/numResults}}{{^numResults}}没有{{/numResults}} 匹配',
		'srch.page.new_search': '重新搜索',
		'srch.no_results': '没有结果',
		'srch.no_results.p': '非常抱歉，找不到所输入词的页面\n 请更正所有错误的拼写或选择其他搜索词。',

		// Headline im Menü z.B. Mensch + Architektur (92 Treffer anzeigen)
		'srch.num_results.standard': '({{numResults}} 显示匹配)',
		// Headline auf der Suchergebnissseite z.B. Mensch + Architektur (92)
		'srch.num_results.mobile': '({{numResults}})',

		// Überschriften für die 'generischen' Facetten. Die restlichen kommen direkt aus den CMS Seiten
		'srch.facet.all': '所有搜索结果',
		'srch.facet.selector': '电子目录',
		'srch.facet.other': '其他',

		// Suchergebnissseite
		'srch.load_more': '显示更多匹配'
	}
});