'use strict';

// Dependencies
import { Component } from 'react';

class NavigationIcons extends Component {
	render() {

		const icons = [];

		if (CMJSContext.page.properties.meinFsbURL) {
            let className = 'nav__icon';
            // Mein FSB nur highlighten, wenn nicht im Merkzettel
            if (document.location.href.indexOf(CMJSContext.page.properties.meinFsbURL) >= 0 && document.location.href.indexOf(CMJSContext.page.properties.merkzettelURL) < 0) {
                className += ' nav__active';
            }
			const icon = <a key="meinfsb" className={className} href={CMJSContext.page.properties.meinFsbURL}>
                    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(4.000000, 4.000000)" fill="currentcolor">
                                <path d="M12.1468564,10.556353 C10.140706,10.0931709 8.27339352,9.68715729 9.17759042,7.97910017 C11.9310158,2.78025964 9.90736483,0 7.00051762,0 C4.03591848,0 2.06301917,2.88701321 4.82344481,7.97910017 C5.7550593,9.69707429 3.81716116,10.1030879 1.85417885,10.556353 C0.0615355682,10.9705335 -0.00555000854,11.86073 0.00028351988,13.4171154 L0.00261693125,14.0004682 L13.9972516,14.0004682 L13.999585,13.4351993 C14.0065852,11.8671469 13.9459166,10.9722836 12.1468564,10.556353 L12.1468564,10.556353 Z"/>
                            </g>
                        </g>
                    </svg>
				</a>;
			icons.push(icon);
		}

		if (CMJSContext.page.properties.merkzettelURL) {
            let className = 'nav__icon';
            if (document.location.href.indexOf(CMJSContext.page.properties.merkzettelURL) >= 0) {
                className += ' nav__active';
            }
			const icon = <a key="merken" className={className} href={CMJSContext.page.properties.merkzettelURL}>
                    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(1.000000, 1.000000)" fill="currentcolor">
                                <polygon points="10 0 12.3608333 7.25704272 20 7.25704272 13.8191667 11.7429573 16.18 19 10 14.5149179 3.81916667 19 6.18 11.7429573 1.77635684e-15 7.25704272 7.63916667 7.25704272"/>
                            </g>
                        </g>
                    </svg>
				</a>;
			icons.push(icon);
		}

		if (CMJSContext.page.properties.languageCode) {
			const icon = <span key="language" className="nav__icon" onClick={() => window.WhoAmI.openWhoAmIPopup()}>
					{CMJSContext.page.properties.languageCode.toLocaleUpperCase()}
			</span>;
			icons.push(icon);
		}

		return (
			<div className="nav__icons">
				{icons}
			</div>
		);
	}
}
export default NavigationIcons;
