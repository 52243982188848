// <ul class="dk-artikel-downloads" data-group-items="Produktdatenblatt,Ausschreibungstext">
//
// Takes the Group Names form the data attribute (seperated by comma)
// For each group it checks if there are any list items whose inner text is starting with that group name.
// If more than one item matches, a new item is created for the group and all matching items are moved into it in a nested list.

window.listItemGruppierer = function() {
    document.querySelectorAll('ol[data-group-items],ul[data-group-items]').forEach(function (list) {
        list.dataset.groupItems.split(',').forEach(function (groupName) {
            const foundElements = [];
            list.querySelectorAll('li').forEach(function (item) {
                if (item.innerText.startsWith(groupName)) {
                    foundElements.push(item);
                }
            });
            if (foundElements.length > 1) {
                const id = 'dk-a-d-p-' + groupName.replace(/[^a-z0-9]/gi, '-').toLowerCase();
                const groupItem = document.createElement('li');
                groupItem.innerHTML = '<label class="dk-a-d-p-label ico-06" for="' + id + '">' + groupName + '<span class="dk-a-d-p-arrow ico-41"></span></label>';
                groupItem.classList.add('dk-artikel-download-popup');
                const groupList = document.createElement('ul');
                groupList.id = id;
                groupList.classList.add('dk-a-d-p-list');
                foundElements.forEach(function (item) {
                    groupList.appendChild(item);
                });
                groupItem.appendChild(groupList);
                list.appendChild(groupItem);
            }
        });
    });
}

jQuery(document).ready(window.listItemGruppierer);
