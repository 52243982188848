'use strict';

/*

*/


import AppDispatcher from '../../_react_common/dispatchers/AppDispatcher';
import SearchConstants from '../constants/SearchConstants.js';
import Evemit from 'evemit';
import SearchStore from './SearchStore';

// Storeage objects

let _highlightedResult = null;
let _highlightedFacet = null;

// Merge our store with Node's Event Emitter
let QuickResultsHighlightStore = Object.assign(new Evemit(), {

	getState: function() {
		return {
			highlightedResult: _highlightedResult,
			highlightedFacet: _highlightedFacet
		};
	},

	// Register dispatcher callback
	dispatchToken: AppDispatcher.register(function(action) {
		let state = SearchStore.getState();
		let changed = false;
		if (state.mode === SearchConstants.MODE_MENU && state.displayMode !== 'mobile') {
			// Define what to do for certain actions
			switch (action.actionType) {
				case SearchConstants.ACTION_RESULTS:
					_highlightedResult = null;
					_highlightedFacet = null;
					QuickResultsHighlightStore.emit('change');
					break;

				case SearchConstants.ACTION_RESULT_OVER:
					if (action.highlightedResult !== null) {
						if (_highlightedResult !== action.highlightedResult) {
							_highlightedResult = action.highlightedResult;
							changed = true;
						}
					}
					if (_highlightedFacet !== action.highlightedFacet) {
						_highlightedFacet = action.highlightedFacet;
						changed = true;
					}

					if (changed) {
						QuickResultsHighlightStore.emit('change');
					}
					break;

				case SearchConstants.ACTION_RESULT_OUT:
					if (_highlightedFacet === action.highlightedFacet) {
						_highlightedFacet = null;
						changed = true;
					}
					if (_highlightedResult === action.highlightedResult) {
						_highlightedResult = null;
						changed = true;
					}
					if (changed) {
						QuickResultsHighlightStore.emit('change');
					}
					break;
			}
		}

		return true;
	})
});
export default  QuickResultsHighlightStore;
