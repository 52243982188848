'use strict';

export default  {
	ACTION_INIT: 'nav21 nt',
    ACTION_UPDATE_DISPLAY_MODE: 'nav21 upd_dspl_md',
    ACTION_TOGGLE_MENU_ITEM: 'nav21 tgl mn itm',
    DISPLAY_MODE_DESKTOP: 'nav21 dm dskt',
    DISPLAY_MODE_MOBILE: 'nav21 dm mob',

};
