'use strict';

// Dependencies
import { Component } from 'react';
import SearchConstants from '../../constants/SearchConstants';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';
import SearchActions from '../../actions/SearchActions';
import PropTypes from 'prop-types';

// SubComponents
import ProduktErgebnis from './../Common/ProduktErgebnis.jsx';

export default class SelektorErgebnisse extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			page: 1
		};
    }

    _loadMore = () => {
		if (this._isActive()) {
			this.setState({page: this.state.page + 1});
			SearchActions.filter(SearchConstants.FACET_SELEKTOR, (this.state.page + 1) * SearchConstants.NUM_PAGES_ACTIVE);
		}
		else {
			SearchActions.filter(SearchConstants.FACET_SELEKTOR);
		}
	};

    _isActive = () => {
		return SearchConstants.FACET_SELEKTOR === this.props.activeFacet;
	};

    render() {
		let ergebnisse = valueForKeyPath(this.props, 'results.data.result_selector.response.docs') || [];
		let numResults = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;

		// skip if no results
		if (ergebnisse.length === 0) {
			return null;
		}

		// trim display to size.
		if (!this._isActive()) {
			ergebnisse = ergebnisse.slice(0, SearchConstants.NUM_PROD_INACTIVE);
		}
		else {
			ergebnisse = ergebnisse.slice(0, this.state.page * SearchConstants.NUM_PROD_ACTIVE);
		}

		// render single result entry
		let ergebnisTags = ergebnisse.map(produkt => {
			return <ProduktErgebnis key={produkt.id} produkt={produkt} single={ergebnisse.length === 1} {...this.props}/>;
		});

		// render 'load More' link or loading indicator
		let loading, loadMore;
		if (this.props.loading) {
			loading = <div className='pagesearch__ergebnisse__bereich__loading' />;
		}
		else {
			if (!this._isActive() && (numResults > SearchConstants.NUM_PROD_INACTIVE) || (numResults > SearchConstants.NUM_PROD_ACTIVE * this.state.page)) {
				loadMore = <div className='pagesearch__ergebnisse__bereich__more' onClick={this._loadMore}>{CMJSContext.L('srch.load_more')}</div>;
			}
		}

		return (
			<div className='pagesearch__ergebnisse__bereich pagesearch__ergebnisse__bereich--selector'>
				<h3 className='pagesearch__ergebnisse__bereich__headline'>{CMJSContext.L('srch.facet.' + SearchConstants.FACET_SELEKTOR)}</h3>
				<div className='produktergebnisse clearfix'>
					<div className='clearfix'>{ergebnisTags}</div>
					{loading}
					{loadMore}
				</div>
			</div>
		);
	}
}

SelektorErgebnisse.propTypes = {
	activeFacet: PropTypes.string,
	loading: PropTypes.bool
};
