'use strict';

// Dependencies
import {Component} from 'react';
import PropTypes from 'prop-types';

class FSBLPBlockNavigationUebersicht extends Component {
    render() {
        return <div className="nav__block">
            {this.props.headline && <div className="nav__block__headline" dangerouslySetInnerHTML={{__html: this.props.headline}} />}
            {this.props.references && (
                this.props.references.map((ref) => {
                    const target = ref.url.indexOf('http') === 0 && !(ref.url.includes('www.fsb.de') || ref.url.includes('stage.fsb.de') || ref.url.includes('www.fsbna.com') || ref.url.includes('stage.fsbna.com') || ref.url.includes('www4.') || ref.url.includes('stage4.')) ? '_blank' : '';
                    let styleClass = 'nav__block__link';
                    if (ref.styleClass) {
                        styleClass += ' ' + ref.styleClass;
                    }
                    return <a key={ref.url} target={target} className={styleClass} href={ref.url.replaceAll('&amp;', '&')}>{ref.navigationTitle}</a>;
                })
            )}
        </div>;
    }
}

FSBLPBlockNavigationUebersicht.propTypes = {
    component: PropTypes.string.isRequired,
    elemid: PropTypes.string.isRequired,
    headline: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.object),
    styleClass: PropTypes.string,
};

export default FSBLPBlockNavigationUebersicht;
