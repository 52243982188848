'use strict';

// Dependencies
import {Component} from 'react';
import PropTypes from 'prop-types';

class FSBLPBlockNavigationBanner extends Component {
    render() {
        return <div className={this.props.styleClass}>
            {this.props.headline && <h3 dangerouslySetInnerHTML={{__html: this.props.headline}} />}
            {this.props.subHeadline && <h4 dangerouslySetInnerHTML={{__html: this.props.subHeadline}} />}
            {this.props.text && <div dangerouslySetInnerHTML={{__html: this.props.text}} />}
            {this.props.references && <ul className="ReferenceList">
                {this.props.references.map((ref) => {
                    const target = ref.url.indexOf('http') === 0 && !(ref.url.includes('www.fsb.de') || ref.url.includes('stage.fsb.de') || ref.url.includes('www.fsbna.com') || ref.url.includes('stage.fsbna.com') || ref.url.includes('www4.') || ref.url.includes('stage4.')) ? '_blank' : '';
                    return <li key={ref.url}><a href={ref.url.replaceAll('&amp;', '&')} target={target}>{ref.navigationTitle}</a></li>;
                })}
            </ul>}
            {this.props.medium && <div dangerouslySetInnerHTML={{__html: this.props.medium}}></div>}
        </div>;
    }
}

FSBLPBlockNavigationBanner.propTypes = {
    component: PropTypes.string.isRequired,
    elemid: PropTypes.string.isRequired,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.object),
    styleClass: PropTypes.string,
    text: PropTypes.string,
    medium: PropTypes.string,
};

export default FSBLPBlockNavigationBanner;
