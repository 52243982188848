import { MerkzettelDatenClass } from './MerkzettelData';
import MerkzettelMailing from '@src/js/merkzettel/elements/MerkzettelMailing';

/*
 * FSB-28
 *
 * Weitere Merkzettel / Speichern / Sammeldownload
 *
 * Bezugsquellensuche
 * Ansprechpartnersuche
 * Weiterleiten
 *
 */
const MerkzettelActionButtons = function (jqElement) {
    this.jqElement = jqElement;
	this.name = "MerkzettelActionButtons";
	this.debug = false;

	this.produkt = null;
};

MerkzettelActionButtons.prototype.init = function () {
	var self = this;

	if (!self.didInit) {
		if (self.debug) log("MerkzettelActionButtons init");

		if (self.debug) log("MerkzettelActionButtons Adding observers");
		CMJSContext.NotificationCenter.defaultCenter.addObserver(self, 'onProduktAddedNotification', MerkzettelDatenClass.NOTIFICATION_PRODUKT_ADDED, window.merkzettelDaten);
		CMJSContext.NotificationCenter.defaultCenter.addObserver(self, 'onProduktRemovedNotification', MerkzettelDatenClass.NOTIFICATION_PRODUKT_REMOVED, window.merkzettelDaten);
		CMJSContext.NotificationCenter.defaultCenter.addObserver(self, 'onMerkzettelUpdate', MerkzettelDatenClass.NOTIFICATION_MERKZETTEL_UPDATE, window.merkzettelDaten);

		if (window.merkzettelDaten && window.merkzettelDaten.initialized) {
			self.updateHtml();
		}

		self.didInit = true;
	}
};

MerkzettelActionButtons.prototype.updateHtml = function() {
	// Buttons handle a single Merkzettel
	this.jqElement.addClass("of-panelactions").empty();

	// Sammeldownload
	if (CMJSContext.page.properties.site !== '/ssf.de') {
		$j('<a class="of-panelactions-right btn btn-default link-button-light mod_aartikel_btn--dl" href="#" style="float:left; margin-left: 0;">' + CMJSContext.L('MZ.button.sammeldownload') + '</a>')
			.click(this.onDownloadButtonClick.bind(this))
			.appendTo(this.jqElement);
	}

	// Weiterleiten
	$j('<a class="of-panelactions-right btn link-button-dark mod_aartikel_btn--snd" href="#">' + CMJSContext.L('MZ.button.sendMerkzettel') + '</a> ')
		.click(this.onWeiterleitenButtonClick.bind(this))
		.appendTo(this.jqElement);

	if (CMJSContext.page.properties.site !== '/ssf.de') {
		// Bezugsquellen
		$j('<a class="of-panelactions-right btn link-button-dark mod_aartikel_btn--qu" href="#">' + CMJSContext.L('MZ.button.dealer') + '</a> ')
			.click(this.onBezugsquellenButtonClick.bind(this))
			.appendTo(this.jqElement);
		// Ansprechpartner
		$j('<a class="of-panelactions-right btn link-button-dark mod_aartikel_btn--pa" href="#">' + CMJSContext.L('MZ.button.contact') + '</a> ')
			.click(this.onAnsprechpartnerButtonClick.bind(this))
			.appendTo(this.jqElement);
	}
};


MerkzettelActionButtons.prototype.onMerkzettelUpdate = function() {
	this.updateHtml();
};

MerkzettelActionButtons.prototype.onProduktAddedNotification = function() {
	this.updateHtml();
};

MerkzettelActionButtons.prototype.onProduktRemovedNotification = function() {
	this.updateHtml();
};


MerkzettelActionButtons.prototype.setProdukt = function(produkt) {
	this.produkt = produkt;
	this.merkzettel = null;
	this.updateHtml();
};




// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
//*
MerkzettelActionButtons.prototype.downloadRequestIsRunning = false;

MerkzettelActionButtons.prototype.onDownloadButtonClick = function() {
	var self = this;

	if (!self.downloadRequestIsRunning) {
		self.downloadRequestIsRunning = true;

		// show loader
		self.jqElement.find('.download .rotador').fadeIn(200);

		// make request
		var generateDownloadRequest = $j.ajax({
			type: 'POST',
			url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
			dataType: 'json',
			data: {
				module			: 'Merkzettel/AjaxDownloadActions',
				languageCode	: CMJSContext.page.properties.languageCode,
				regionCode		: CMJSContext.page.properties.region
			}
		});

		generateDownloadRequest.done(function(data) {
			if (data.status === 'OK') {
				document.location = data.downloadUrl;
			}
			else {
				// ???
			}
			self.jqElement.find('.download .rotador').fadeOut(200);
			self.downloadRequestIsRunning = false;
		});
	}
	return false;
};
//*/

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //


MerkzettelActionButtons.prototype.onAnsprechpartnerButtonClick = function() {
	var url = window.merkzettelDaten.getHrefToMerkzettelOverview('asp');

	if (document.getElementById('my-mz')) {
		history.pushState(null,null, url);
        CMJSContext.NotificationCenter.defaultCenter.postNotification('URLManipulation');
	}
	else {
		document.location = url;
	}

	return false;
};


// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //


MerkzettelActionButtons.prototype.onBezugsquellenButtonClick = function() {
	var url = window.merkzettelDaten.getHrefToMerkzettelOverview('bzq');

	if (document.getElementById('my-mz')) {
		history.pushState(null,null, url);
        CMJSContext.NotificationCenter.defaultCenter.postNotification('URLManipulation');
	}
	else {
		document.location = url;
	}

	return false;
};


// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //


MerkzettelActionButtons.prototype.onWeiterleitenButtonClick = function() {

	new MerkzettelMailing().sendMerkzettel();

	return false;
};


export default MerkzettelActionButtons;
