import Hyphenator from '@lib/Hyphenator';

export default function clampNumTextlines(jqElement, maxLines, hyphenated, estimatedNumChars) {
	var allowedHeight = parseFloat(jqElement.css('line-height')) * maxLines;

	// Save original Text in data-Tag
	var text = jqElement.data('text');

	// Wenn der Text noch nicht gespeicher wurde ist es das 1. Mal, dass wir ihn abschneiden.
	// Wir gehen davon aus, dass er schon hyphenated ist.
	if (typeof text === 'undefined') {
		text = jqElement.text();
		jqElement.data('text', text);
	}
	// Ansonsten wurde er schonmal beschnitten; Also zuerst den original Text wieder in das Element schreiben.
	else {
		if (hyphenated && Hyphenator) {
			jqElement.html(Hyphenator.hyphenate(text, CMJSContext.page.properties.languageCode));
		}
		else {
			jqElement.text(text);
		}
	}

	var catHeight = jqElement.height();
	// In eine Zeile passen 27|26|32 i's also fangen wir mit 30 Zeichen an und arbeiten uns runter.
	var length = Math.min(text.length, estimatedNumChars ? estimatedNumChars : 9999);
	while (catHeight > allowedHeight) {

		if (text.length > length) {
			text = text.substring(0, length);
			if (hyphenated && Hyphenator) {
				jqElement.html(Hyphenator.hyphenate(text, CMJSContext.page.properties.languageCode) + "…");
			}
			else {
				jqElement.text(text + "…");
			}
		}

		catHeight = jqElement.height();
		length--;
	}
}
