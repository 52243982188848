'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchActions from '../../actions/SearchActions';
import SearchConstants from '../../constants/SearchConstants';
import valueForKeyPath from '../../../_react_common/valueForKeyPath.js';

class Facette extends Component {
    _activateFacet = () => {
		SearchActions.filter(this.props.facetKey);
	};

    render() {

		let matches = 0;

		if (this.props.facetKey === SearchConstants.FACET_ALL) {
			matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
			matches += parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.matches')) || 0;
		}
		else if (this.props.facetKey === SearchConstants.FACET_SELEKTOR) {
			matches = parseInt(valueForKeyPath(this.props, 'results.data.result_selector.response.numFound')) || 0;
		}
		else {
			matches = parseInt(valueForKeyPath(this.props, 'results.data.result_cms.grouped.fsbKategorie.groups.' + this.props.facetKey + '.numFound')) || 0;
		}

		let className = 'pagesearch__facette';
		if (this.props.activeFacet === this.props.facetKey) {
			className += ' pagesearch__facette--active';
		}
		if (matches === 0) {
			className += ' pagesearch__facette--empty';
		}

		return (
			<div className={className} onClick={this._activateFacet}>
				{this.props.facetName} ({matches})
			</div>
		);
	}
}
Facette.propTypes = {
	facetKey: PropTypes.string.isRequired,
	facetName: PropTypes.string.isRequired,
	activeFacet: PropTypes.string
};

export default  Facette;
