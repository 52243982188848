'use strict';

// Dependencies
import { Component } from 'react';
import ReactDOM from 'react-dom';
import SearchStore from '../stores/SearchStore.js';

// SubComponents
import Debug from './Debug.jsx';
import PageSearch from './PageSearch/PageSearch.jsx';

class SiteSearch extends Component {
    __debugElement = null;

    constructor(props, context) {
        super(props, context);

        this.state = this._retrieveStateFromStore();

		SearchStore.on('change', this._storeChanged);
    }

    componentDidMount() {
		let p = document.getElementById('siteSearchDebug');
		if (!p) {
			p = document.createElement('div');
			p.id = 'siteSearchDebug';
			document.body.appendChild(p);
		}
		this.__debugElement = p;

		this.componentDidUpdate();
	}
    componentWillUnmount() {
		SearchStore.off('change', this._storeChanged);
		document.body.removeChild(this.__debugElement);
	}

    _storeChanged = () => {
		this.setState(this._retrieveStateFromStore());
	};

    _retrieveStateFromStore() {
		return SearchStore.getState();
	}

    render() {
		return <PageSearch {...this.state} />;
	}

    componentDidUpdate() {
		ReactDOM.render(<Debug {...this.state} />, this.__debugElement);
	}
}
export default  SiteSearch;
