'use strict';

// Dependencies
import {Component} from 'react';
import PropTypes from 'prop-types';
import FSBLPBlockNavigationStandard from '@nav21/components/FSBLPBlockNavigationStandard';
import FSBLPBlockNavigationUebersicht from '@nav21/components/FSBLPBlockNavigationUebersicht';
import FSBLPBlockNavigationVTeaser from '@nav21/components/FSBLPBlockNavigationVTeaser';
import FSBLPBlockNavigationBanner from '@nav21/components/FSBLPBlockNavigationBanner';

class DesktopNavigation extends Component {
    render() {
        // Ebene 1 sind immer Responsive Spalten!!!1
        const columns = this.props.activeMenuItem.elements.map((column) => {
            return <div key={column.elemid} className={"nav__column " + column.styleClass}>
                {column.elements.map((element) => {
                    switch (element.component) {
                        case 'FSBLPBlockNavigationStandard':
                            return <FSBLPBlockNavigationStandard key={element.elemid} {...element} />;
                        case 'FSBLPBlockNavigationUebersicht':
                            return <FSBLPBlockNavigationUebersicht key={element.elemid} {...element} />;
                        case 'FSBLPBlockNavigationVTeaser':
                            return <FSBLPBlockNavigationVTeaser key={element.elemid} {...element} />;
                        case 'FSBLPBlockNavigationBanner':
                            return <FSBLPBlockNavigationBanner key={element.elemid} {...element} />;
                        default:
                            return <small key={element.elemid} >Error: Unsupported component {element.component}</small>
                    }
                })}
            </div>;
        });

        return <div className="row">
            {columns}
        </div>;
    }
}

DesktopNavigation.propTypes = {
    activeMenuItem: PropTypes.object
};

export default DesktopNavigation;
