'use strict';

export default  {
	ACTION_FOCUS_INPUT: 'srch fcs npt',
	ACTION_CLOSE_INPUT: 'srch cls npt',
	ACTION_QUERY: 'srch qry',
	ACTION_FILTER: 'srch fltr',
	ACTION_LOAD_MORE: 'srch ld mr',
	ACTION_INIT: 'srch nt',
	ACTION_RESULTS: 'srch rslts',
	ACTION_RESP_CHANGE: 'srch rspchng',
	ACTION_CLOSE_INPUT_DONE: 'srch clsnptdn',
	ACTION_REDUCE_QUICK_RESULTS_HEIGHT: 'srch rdc_qck_rslts_hght',
	ACTION_RESET_QUICK_RESULTS_HEIGHT: 'srch rst_qck_rslts_hght',
	ACTION_TOGGLE_HINWEIS_CONTENT: 'srch tgl_hnws_cntnt',
	ACTION_RESULT_OVER: 'srch rslt_ms_vr',
	ACTION_RESULT_OUT: 'srch rslt_ms_t',
	ACTION_UPDATE_DISPLAY_MODE: 'srch upd_dspl_md',

	MODE_PAGE: 'page',
	MODE_MENU: 'menu',

	FACET_ALL: 'all',
	FACET_SELEKTOR: 'selector',
	FACET_OTHER: 'other',
	FACET_FAQ: '__faq__',

	SELEKTOR_IMAGE_URI: '/static/productimg/',
	FALLBACK_IMAGE: 'solr-placeholder.png',

	NUM_PAGES_INACTIVE: 5,
	NUM_PAGES_ACTIVE: 10,
	NUM_PROD_INACTIVE: 4,
	NUM_PROD_ACTIVE: 12,

	QUICK_RESULTS_NUM_PAGES: 3,
	QUICK_RESULTS_NUM_PROD: 4
};
