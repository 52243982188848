'use strict';

export default function(object, keyPath) {

	if (!object || !keyPath) {
		return undefined;
	}

	let paths = keyPath.split('.'),
		current = object,
		i;

	for (i = 0; i < paths.length; ++i) {
		if (!current[paths[i]]) {
			return undefined;
		}
		else {
			current = current[paths[i]];
		}
	}
	return current;
}
