var AlleArchitektouren = function(rootelement) {
	this.root = rootelement;

	this.wrapperTyp = jQuery('.architektour-filter-typ', this.root);
	this.wrapperSort = jQuery('.architektour-filter-sortiment', this.root);

	this.selectedTypKey = null;
	this.selectedTypLabel = null;
	this.selectedSortKey = null;
	this.selectedSortLabel = null;

	var aa = this;
	jQuery('li', this.wrapperTyp).on('click', function(event){event.preventDefault();aa.onTypClick.call(aa, this)});
	jQuery('li', this.wrapperSort).on('click', function(event){event.preventDefault();aa.onSortClick.call(aa, this)});

	var initialTyp = this.wrapperTyp.find('li[data-key="all"]');
	if (initialTyp != null && initialTyp.length === 1) {
		this.selectTyp(initialTyp.data('key'), initialTyp.text());
	}

	jQuery(window).bind('resize', this.updateDisplay.bind(this));
	jQuery(window).bind('orientationchange', this.updateDisplay.bind(this));
};

AlleArchitektouren.prototype.onTypClick = function(button) {
	var key = jQuery(button).data('key');
	if (typeof key === "undefined") {
		return;
	}

	this.selectTyp(key, jQuery(button).text());
};
AlleArchitektouren.prototype.selectTyp = function(key, label) {
	this.selectedTypKey = key;
	this.selectedTypLabel = label;
	this.selectedSortKey = 'all';
	this.selectedSortLabel = this.wrapperSort.find('li[data-key="all"]').text();

	this.updateDisplay();
}
AlleArchitektouren.prototype.onSortClick = function(buttonel) {
	var button = jQuery(buttonel);

	if (button.hasClass('strike')) {
		return;
	}

	var key = button.data('key');
	if (typeof key === "undefined") {
		return;
	}

	this.selectSort(key, jQuery(button).text());
};
AlleArchitektouren.prototype.selectSort = function(key, label) {
	this.selectedSortKey = key;
	this.selectedSortLabel = label;

	this.updateDisplay();
}
AlleArchitektouren.prototype.updateDisplay = function() {

	// Mobile Dropdown aktualisieren
	jQuery('.selectedval', this.wrapperTyp).text(this.selectedTypLabel);
	jQuery('.selectedval', this.wrapperSort).text(this.selectedSortLabel);

	// Sortiment-Buttons ausblenden/durchstreichen
	var allowedSortButtons = window.alleArchitektourenTypeMapping[this.selectedTypKey];
	allowedSortButtons.push('all');
	// Desktop
	if (window.getResolutionClass() !== 'xs') {
		jQuery('li', this.wrapperSort).show().addClass('strike');
		jQuery.each(allowedSortButtons, function(i,e) {
			jQuery('li[data-key="' + e + '"]', this.wrapperSort).removeClass('strike');
		});
	}
	// Mobil
	else {
		jQuery('li', this.wrapperSort).removeClass('strike').hide();
		jQuery.each(allowedSortButtons, function(i,e) {
			jQuery('li[data-key="' + e + '"]', this.wrapperSort).show();
		});
	}

	// Selektion resetten
	jQuery('li', this.wrapperTyp).removeClass('architektouren-typ--active');
	jQuery('li', this.wrapperSort).removeClass('architektouren-typ--active');
	// Neue Selektion markieren
	jQuery('li[data-key="' + this.selectedTypKey + '"]', this.wrapperTyp).addClass('architektouren-typ--active');
	jQuery('li[data-key="' + this.selectedSortKey + '"]', this.wrapperSort).addClass('architektouren-typ--active');

	// Architektouren ein/ausblenden
	var aa = this;
	jQuery('.architektour', this.root).each(function(i, e){
		var elem = jQuery(e);
		if ((aa.selectedTypKey === 'all' || elem.hasClass('architektour--' + aa.selectedTypKey))
			&& (aa.selectedSortKey === 'all' || elem.hasClass('architektour--' + aa.selectedSortKey))) {

			elem.show();
		}
		else {
			elem.hide();
		}
	});
	jQuery('.filter', this.root).removeClass('filter-open');
};



(function(){
	jQuery('.AlleArchitektouren:not(.AlleArchitektouren--Presse)').each(function(i,e){
		new AlleArchitektouren(jQuery(e));
	});
})();
