(function() {
    if (document.location.href.match(/\/impressum\//) === null) {
        return;
    }

    const userAgent = window.navigator.userAgent;
    const phoneNumber = '+49 5272 608-0';
    const contactEl = $j('p:contains("' + phoneNumber + '")');
    if (contactEl.length > 0) {
        if (!(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i))) {
            const trimmedPhoneNumber = phoneNumber.replace(/[ -]/g, '');
            contactEl.html(
                contactEl.html().replace(phoneNumber, '<a href="tel:' + trimmedPhoneNumber + '">' + phoneNumber + '</a>')
            );
        }

        contactEl.find('a[href^="tel:"]').on('click', () => {
            gtag('event', 'telefon', {
                event_category: 'Kontakt',
                event_label: 'Telefon - Impressum'
            });
        });
        contactEl.find('a[href^="mailto:"]').on('click', () => {
            gtag('event', 'email', {
                event_category: 'Kontakt',
                event_label: 'Email - Impressum'
            });
        });
    }
})();
