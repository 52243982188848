"use strict";
import '@lib/Hyphenator';
/*global Hyphenator*/

(function() {

// 1. Update Menu
	if (typeof CMJSContext.page.properties.specwriteapi !== 'undefined') {

		let pendingAjaxRequest = null;

		const updateMenu = function() {
			const isAuthenticated = window.authentication.state === window.authentication.AUTHENTICATED;

			if (isAuthenticated) {
                const elem = jQuery('.mein_fsb_nav a[data-context="ausschreibungen"]');

                if (elem.length > 0) {
                    pendingAjaxRequest = $j.ajax({
                        type: 'GET',
                        url: '/sw-api/projects/count',
                        dataType: 'json',
                        headers: {
                            userId: window.authentication.data.email,
                            "cache-control": "no-cache"
                        }
                    });

                    pendingAjaxRequest.done(function (data) {
                        pendingAjaxRequest = null;

                        const value = parseInt(data);

                        if (!isNaN(value)) {
                            elem.append('<span> (' + value + ')</span>');
                        }
                    });
                }
			}
			else {
				if (pendingAjaxRequest !== null) {
					pendingAjaxRequest.abort();
				}
			}
		};

		/**
		 * Listened for changed in the window.authentication state and acts according to the new state.
		 * - authenticated: hide the login from the menu
		 * - not authenticated: show the login in the menu
		 */
		CMJSContext.NotificationCenter.defaultCenter.addObserver(null, () => {
			updateMenu();
		}, window.authentication.NOTIFICATION_STATE_CHANGED, window.authentication);
		CMJSContext.NotificationCenter.defaultCenter.addObserver(null, () => {
			updateMenu();
		}, window.authentication.NOTIFICATION_INITD, window.authentication);
	}




// 2. Scripts for SpecWrite Content-Page
	if (document.getElementById('sw-index') === null && document.getElementById('sw-projekt') === null) {
		return;
	}

	var h2s = document.getElementsByTagName('h2');
	if (typeof h2s !== 'undefined' && h2s.length > 0) { // Keine h2 => totaler Fail
		window.initialSpecWriteHeadline = h2s[0].textContent;
		window.initSpecWrite = function (pagename) {

			// 2.1 Akkordeons
			jQuery('.sw_artikel').each(function () {
				"use strict";
				var akkordeon = jQuery(this);
				var akkordeon_content = akkordeon.find('> .sw_artikel__details');
				// var triggerElement = akkordeon.children('.sw_artikel__info');

				//init animation "öffnen"
				if (akkordeon.hasClass('sw_artikel--open')) {
					akkordeon_content.stop().slideDown();
				}
				else {
					akkordeon_content.hide();
				}
/*
				triggerElement.off('click.swakkordeon').on('click.swakkordeon', function () {
					// CSS Alle Geschwister-Akkordeons schließen
					akkordeon.siblings('.sw_artikel').removeClass('sw_artikel--open');
					akkordeon.toggleClass('sw_artikel--open');

					// JS Alle Geschwister-Akkordeons schließen
					akkordeon.siblings('.sw_artikel').find('> .sw_artikel__details').stop().slideUp();
					akkordeon_content.stop().slideToggle();
				});
*/
			});

			// 2.2 Hyphenator
			Hyphenator.run();

			// 2.3 Breadcrump und h2
			if (pagename) {
				var currentBreadcrump = $j('.Breadcrump li.specwrite').text();
				if (currentBreadcrump !== pagename) {
					$j('.Breadcrump li.specwrite').remove();
					$j('.Breadcrump ul').append('<li class="specwrite">' + pagename + '</li>');
				}

				var currenth2 = $j('h2').text();
				if (currenth2 !== pagename) {
					$j('h2').text(pagename);
				}
			}
			else {
				$j('.Breadcrump li.specwrite').remove();
				$j('h2').text(window.initialSpecWriteHeadline);
			}

			// 2.4 Sticky header
			var pageHeader = jQuery('body > header .nav__wrapper');
			var tabellen = jQuery('.sw_projekt__tabelle');
			var updateTabellenHeader = function() {
				tabellen.each(function(i, tabellenElement) {
					var tabelle = jQuery(tabellenElement);
					window.swtabelle = tabelle;
					if (tabelle.is(':visible')) {
						var header = tabelle.find('.sw_projekt__header');

						// Platzhalter für position fixed
						var pageHeaderPlaceholder = tabelle.find('.sw_projekt__header--placeholder');
						if (pageHeaderPlaceholder.length === 0) {
							jQuery('<div class="sw_projekt__header--placeholder"></div>').hide().insertAfter(header);
						}

						// Figure out how many pixels to scroll down
						var scrollOffset =
							// Scroll position of the page
							window.pageYOffset
							// But only when the offset is larget than the initial offset of the table in the page itself
							- tabelle.offset().top
							// making sure the table header stays below the page header
							+ pageHeader.height()
						;

						// Muss der Header positioniert werden?
						// Nach oben maximal auf seine Ausgangsposition.
						if (scrollOffset < 0) {
							// nein
							header.css({
								position: 'static',
								width: 'auto',
								height: 'auto'
							});
							pageHeaderPlaceholder.hide();
						}
						// Nach unten maximal ans Ende der Tabelle so, dass noch eine Zeile sichtbar ist.
						// Sprich ohne der Höhe des Headers selbst und ohne Höhe der letzten Zeile´
						else if (scrollOffset > tabelle.outerHeight() - header.outerHeight() - tabelle.find('.sw_artikel:last-of-type').outerHeight()) {
							header.css({
								position: 'relative',
								top: tabelle.outerHeight() - header.outerHeight() - tabelle.find('.sw_artikel:last-of-type').outerHeight(),
								width: 'auto',
								height: 'auto'
							});
							pageHeaderPlaceholder.hide();
						}
						// Ansonsten fixed unter dem Seitenheader
						else {
							header.css({
								height: header.outerHeight(),
								width: header.outerWidth(),
							});
							pageHeaderPlaceholder.css({
								height: header.outerHeight(),
								width: header.outerWidth()
							});
							header.css({
								position: 'fixed',
								top: pageHeader.height()
							});
							pageHeaderPlaceholder.show();
						}
					}
				});
			};

			jQuery(window).off('scroll.swsticky').on('scroll.swsticky', updateTabellenHeader);
			jQuery(window).off('resize.swsticky').on('resize.swsticky', updateTabellenHeader);
			jQuery(window).off('orientationchange.swsticky').on('orientationchange.swsticky', updateTabellenHeader);
		};
	}


})();



