import { MerkzettelDatenClass } from './elements/MerkzettelData';

if (typeof window.merkzettelLoader === 'undefined'){
	window.merkzettelLoader = jQuery.Deferred();
}

window.merkzettelDaten = new MerkzettelDatenClass();
window.merkzettelDaten.init();

window.merkzettelLoader.resolve();
