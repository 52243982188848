
/**
 * Provides methods to communicate with the PHP backend over AJAX and to generate registration and login forms.
 */
var authenticationClass = function() {
	this.deferredInitialization = null;
	this.AUTHENTICATED = true;
	this.NOT_AUTHENTICATED = false;
	this.state = this.NOT_AUTHENTICATED;

	this.NOTIFICATION_STATE_CHANGED = 'AuthenticationStateChanged';
	this.NOTIFICATION_INITD = 'AuthenticationInitialised';

	this.debug = false;
	if (this.debug) {console.log('debug active in authentication.js');}

	// load current authentication state from PHP
	this.init();
};

/**
 * Checks whether or not the user is authenticated. Must be checked after INITD event was triggered.
 * @return Nothing
 * @triggers window.authentication.NOTIFICATION_INITD
 */
authenticationClass.prototype.init = function() {
	var self = this;

	if (self.deferredInitialization == null) {
		self.deferredInitialization = jQuery.Deferred();

		$j(function(){
			var authTester = $j.ajax({
				type: 'POST',
				url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
				dataType: 'json',
				data: {
					module			: 'CRM/AjaxLogin',
					languageCode	: CMJSContext.page.properties.languageCode,
				}
			});

			authTester.done(function(data){
				if (self.debug) log('authTester done', data);
				if (data.status == 'OK') {
					self.state = self.AUTHENTICATED;
					self.data = data;
					CMJSContext.NotificationCenter.defaultCenter.postNotification(self.NOTIFICATION_INITD, self, {});
				}
				else if (data.status == 'NOK') {
					self.state = self.NOT_AUTHENTICATED;
					self.data = data;
					CMJSContext.NotificationCenter.defaultCenter.postNotification(self.NOTIFICATION_INITD, self, {});
				}

				self.deferredInitialization.resolve();
			});
		});
	}

	return self.deferredInitialization;
};

/**
 * Holds the current authentication state.
 * @return Constant. either AUTHENTICATED or NOT_AUTHENTICATED
 */
authenticationClass.prototype.state = function() {
	return this.state;
};

authenticationClass.prototype.setState = function(newState) {
	if (newState === this.state) {
		return;
	}
	else if (newState === this.NOT_AUTHENTICATED || newState === this.AUTHENTICATED) {
		this.state = newState;
		CMJSContext.NotificationCenter.defaultCenter.postNotification(this.NOTIFICATION_STATE_CHANGED, this, {});
	}
};

authenticationClass.prototype.waitForState = function() {
	var deferred = jQuery.Deferred();

	self.deferredInitialization.done(deferred.resolve());

	return deferred;
};


/**
 * Sends the login request to the PHP-Backend which communicates with the CRM.
 * If login is successfull the php backend sets a cookie named uauth within the ajax request.
 * @param username The username
 * @param password The password
 * @return {jQuery.Deferred} Deferred object which is resolved if the login was successfull. If not the deferred object
 * is rejected.
 */
authenticationClass.prototype.login = function(username, password) {
	var self = this;
	if (self.debug) log('authenticationClass.prototype.login', username, password);

	var login = $j.ajax({
		type: 'POST',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: {
			module			: 'CRM/AjaxLogin',
			languageCode	: CMJSContext.page.properties.languageCode,
			'_username' 	: username,
			'_password' 	: password
		}
	});

	var ret = jQuery.Deferred();

	login.done(function(data){
		if (self.debug) log('login done', data);
		if (data.status === 'OK') {
			self.data = data;
			self.setState(self.AUTHENTICATED);
			ret.resolve();
		}
		else if (data.status === 'NOK') {
			self.data = data;
			self.setState(self.NOT_AUTHENTICATED);
			ret.reject();
		}
	});

	login.fail(function(){
		if (self.debug) log('failed to authenticate...');
		self.data = null;
		self.setState(self.NOT_AUTHENTICATED);
		ret.reject();
	});

	return ret;
};

/**
 * Communicated the logout wish to the backend. If successful, the new authentication state is broadcasted.
 */
authenticationClass.prototype.logout = function() {
	var self = this;
	if (self.debug) log('authenticationClass.prototype.logout');

	var logout = $j.ajax({
		type: 'GET',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: {
			module			: 'CRM/AjaxLogin',
			languageCode	: CMJSContext.page.properties.languageCode,
			doLogout: '1'
		}
	});

	logout.done(function(){
		self.data = null;
		self.setState(self.NOT_AUTHENTICATED);
	});

	logout.fail(function(){
		if (self.debug) log('failed to deauthenticate...');
	});
};

/**
 * The "single" object of the authenthenticationClass class. Don't create more ;-)
 * @type {authenticationClass}
 */
window.authentication = new authenticationClass();

