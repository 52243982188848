// Dropdowns
jQuery('.selectedval').click(function(event) {
	//event.preventDefault();
	var dropdown = jQuery(this).parent().parent();
	if (dropdown.hasClass('filter-open')) {
		dropdown.removeClass('filter-open');
	}
	else {
		// Mini-Delay, damit das Event erst hochbubbeln kann und und die Funktion untendrunter das dropdown gleich wieder schließt
		setTimeout(function(){ dropdown.addClass("filter-open"); }, 10);
	}
	event.preventDefault();
});
// Dropdowns schließen bei Klick irgendwo auf der Seite sofern nicht innerhalb eines offenen Dropdowns
jQuery(window).on('click', function(event) {
	jQuery('.filter-open').each(function(i, dropdown) {
		var toggler = jQuery(dropdown).find('.selectedval').get(0);
		var popup =  jQuery(dropdown).find('ul').get(0);
		if (!jQuery.contains(toggler, event.target) && !jQuery.contains(popup, event.target)) {
			jQuery(dropdown).removeClass('filter-open');
		}
	});
});
