'use strict';

import { Produkt, Referenz, Merkzettel, MerkzettelDatenClass } from './MerkzettelData';

/**
 * ProduktActions FSB-28
 *
 * Shows a symbol to add the current produkt to the merkzettel and a symbol to email the current produkt to a recipient
 * The symbols are usually a star and an envelope.
 *
 * The element is initialized by default and can be accessed via
 * CMJSContext.page.elements['MerkzettelProduktActions'];
 *
 * To display the action buttons call the setProduktData method with the following parameter:
 *
 	CMJSContext.page.elements['ProduktActions'].setProduktData({
 		htmlElement			: jQuery('#button'),							// jQuery-element representing the star
 		htmlClassNotAdded	: '',											// class will be removed when the product is on the mz and added if not
 		htmlClassAdded		: '',											// class will be added when the product is on the mz and removed if not

 		id					: 'fosbfierbgirebgod',							// unique product id
		url					: 'http://www.fsb.de/produkt4711',				// url to the page about the produkt
		mediaUrl			: 'http://www.fsb.de/static/someImage.png',		// image to be displayed in the merkzettel and in emails (56*170px)
		name				: 'Produkt 4711',								// name of the produkt usesd as headline
		shortName			: '4711',										// Short name of the product. max 10 chars for small display purposes
		description			: 'The Produkt 4711 is a very good produkt!',	// short description, e.g. bestellnummern
		references			: [
								{
									'url'		: 'http://www.fsb.de/download/4711.pdf',
									'linktitle'	: 'Produkt Informations'
								},
								{...}
							],
		bzqFilter			: 'bzq_sanitaer' // optional, one of: bzq_baubeschlag, bzq_sanitaer, bzq_ezk
	});
 *
 *
 * @constructor
 */
CMJSContext.Element.MerkzettelProduktActions = function () {
	this.name = "MerkzettelProduktActions";

	this.starElement = null;
	this.htmlClassNotAdded = "star--not-added";
	this.htmlClassAdded = "star--added";

	this.debug = false;
};

CMJSContext.Element.MerkzettelProduktActions.prototype = new CMJSContext.Element();
CMJSContext.Element.MerkzettelProduktActions.prototype.init = function () {
	var self = this;
	if (self.debug) log("MerkzettelProduktActions init with", self.jsProperties);

	// free created elements
	this.starElement = self.jsProperties.htmlElement;
	if (self.jsProperties.htmlClassNotAdded) this.htmlClassNotAdded = self.jsProperties.htmlClassNotAdded;
	if (self.jsProperties.htmlClassAdded) this.htmlClassAdded = self.jsProperties.htmlClassAdded;

	if (!self.jsProperties.shortName) {
		this.shortName = self.jsProperties.name;
	}

	this.produkt = new Produkt(self.jsProperties.pid, self.jsProperties.url, self.jsProperties.mediaUrl, self.jsProperties.name, self.jsProperties.shortName, self.jsProperties.description, self.jsProperties.bzqFilter);

	if (this.produkt instanceof Produkt) {
		$j.each(self.jsProperties.references, function(i,ref){
			if (typeof ref === 'object') {
				self.produkt.addReferenz(new Referenz(ref.url, ref.linktitle));
			}
		});
		this.produkt.references = self.jsProperties.references;
	}

	// produkt data was successfully updated. generate html if needed
	if (window.merkzettelDaten instanceof MerkzettelDatenClass && window.merkzettelDaten.initialized) {
		this.updateHtml();
	}
	else {
		if (this.debug) log('MerkzettelProduktActions', 'window.merkzettelDaten not ready yet');
	}

	CMJSContext.NotificationCenter.defaultCenter.addObserver(self, self.onProduktAddedNotification.bind(self), MerkzettelDatenClass.NOTIFICATION_PRODUKT_ADDED, window.merkzettelDaten);
	CMJSContext.NotificationCenter.defaultCenter.addObserver(self, self.onProduktRemovedNotification.bind(self), MerkzettelDatenClass.NOTIFICATION_PRODUKT_REMOVED, window.merkzettelDaten);
	CMJSContext.NotificationCenter.defaultCenter.addObserver(self, self.onMerkzettelUpdate.bind(self), MerkzettelDatenClass.NOTIFICATION_MERKZETTEL_UPDATE, window.merkzettelDaten);

	self.didInit = true;
};

CMJSContext.Element.MerkzettelProduktActions.prototype.destroy = function () {
	if (this.starElement) this.starElement.off('click.mz');

	CMJSContext.NotificationCenter.defaultCenter.removeObserver(this, MerkzettelDatenClass.NOTIFICATION_PRODUKT_ADDED, window.merkzettelDaten);
	CMJSContext.NotificationCenter.defaultCenter.removeObserver(this, MerkzettelDatenClass.NOTIFICATION_PRODUKT_REMOVED, window.merkzettelDaten);
	CMJSContext.NotificationCenter.defaultCenter.removeObserver(this, MerkzettelDatenClass.NOTIFICATION_MERKZETTEL_UPDATE, window.merkzettelDaten);
};


CMJSContext.Element.MerkzettelProduktActions.prototype.onMerkzettelUpdate = function(notification) {
	if (this.debug) log('MerkzettelProduktActions', 'onMerkzettelUpdate', notification);
	this.updateHtml();
};

CMJSContext.Element.MerkzettelProduktActions.prototype.updateHtml = function() {
	this.updateStarElement();
};

CMJSContext.Element.MerkzettelProduktActions.prototype.updateStarElement = function() {
	var self = this;

	if (window.merkzettelDaten instanceof MerkzettelDatenClass && this.produkt instanceof Produkt) {
		if (window.merkzettelDaten.merkzettel instanceof Merkzettel && window.merkzettelDaten.merkzettel.containsProdukt(this.produkt)) {

			if (this.htmlClassAdded) this.starElement.addClass(this.htmlClassAdded);
			this.starElement.removeClass(this.htmlClassNotAdded);

		}
		else {
			this.starElement.addClass(this.htmlClassNotAdded);
			this.starElement.removeClass(this.htmlClassAdded);
		}

		this.starElement.off('click.mz').on('click.mz', function(event){
			return self.onSingleModeStarClick(event);
		});
	}
};

// star element

CMJSContext.Element.MerkzettelProduktActions.prototype.onSingleModeStarClick = function(event) {
	var self = this;
	if (self.debug) log('MerkzettelProduktActions', 'onSingleModeStarClick');

	if (self.produkt instanceof Produkt) {
		if (window.merkzettelDaten.merkzettel instanceof Merkzettel && window.merkzettelDaten.merkzettel.containsProdukt(self.produkt)) {
			window.merkzettelDaten.merkzettel.removeProdukt(self.produkt);
		}
		else {
			window.merkzettelDaten.addProdukt(self.produkt);
		}
	}

	if (event) {
		event.preventDefault();
		event.stopPropagation();
	}
	return false;
};

CMJSContext.Element.MerkzettelProduktActions.prototype.onProduktAddedNotification = function(notification) {
	var self = this;

	if (notification.userInfo().produkt.equals(self.produkt)) {
		self.starElement.addClass(self.htmlClassAdded);
		self.starElement.removeClass(self.htmlClassNotAdded);
	}
};

CMJSContext.Element.MerkzettelProduktActions.prototype.onProduktRemovedNotification = function(notification) {
	var self = this;

	if (notification.userInfo().produkt.equals(self.produkt)) {
		self.starElement.addClass(self.htmlClassNotAdded);
		self.starElement.removeClass(self.htmlClassAdded);
	}
};
