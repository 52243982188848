import './bodyEnd/00locale';

import './00-Libraries/jquery-easing';
import './00-Libraries/jquery-loadRecaptcha';
import './00-Libraries/jquery-touchswipe';
import './00-Libraries/select2.full';
import './00-Libraries/slick';

import './bodyEnd/bootstrap/bootstrap-datetimepicker';
import './bodyEnd/bootstrap/collapse';
import './bodyEnd/bootstrap/modal';
import './bodyEnd/bootstrap/tab';
import './bodyEnd/bootstrap/tooltip';
import './bodyEnd/bootstrap/transition';
import './bodyEnd/Content/alleArchitektouren';
import './bodyEnd/Content/alleIrgendwasDieDropdowns';
import './bodyEnd/Content/allePressemeldungen';
import ASP from './bodyEnd/Content/asp';
import './bodyEnd/Content/content';
import './bodyEnd/Content/Hyphenator';
import './bodyEnd/Content/img-svg';
import './bodyEnd/Content/karussell';
import './bodyEnd/Content/karussell_bubbels';
import './bodyEnd/Content/openforms';
import './bodyEnd/Content/pcontainer--akkordeon';
import './bodyEnd/Content/pstaticmap';
import './bodyEnd/Content/select2';
import './bodyEnd/Content/startseite';
import './bodyEnd/Content/les-couleurs';
import './bodyEnd/CRM/authentication';
import './bodyEnd/CRM/marktSpracheUndZielgruppe';
import './bodyEnd/Produkte/ArtikelUebersicht';
import './bodyEnd/Produkte/AusgewaehlteArtikel';
import './bodyEnd/Produkte/ListItemGruppierer';
import './bodyEnd/scrollbarWidth';
import './bodyEnd/impressum';
import './bodyEnd/SpecWrite';

import './merkzettel/elements/MerkzettelData.js';
import './merkzettel/elements/MerkzettelMailing.js';
import './merkzettel/elements/ActionButtons.js';
import './merkzettel/elements/ProduktActions.js';
import './merkzettel/localizations.js';
import './merkzettel/Initialization.js';

import './sitesearch/sitesearch.jsx';
import './nav21/nav21.jsx';


// Conditionally init or import other modules

// Ansprechpartnersuche
const aspContainers = document.querySelectorAll('.Module_AnsprechpartnerForm');
if (aspContainers.length > 0) {
    aspContainers.forEach(container => {
        new ASP(container);
    });
}

// Bezugsquellensuche
const bzqContainers = document.querySelectorAll('div[data-inject="bzq"]');
if (bzqContainers.length > 0) {
    import('./bzq/BZQ2020').then(({default: BZQ2020}) => {
        bzqContainers.forEach(container => {
            new BZQ2020(container, null, null, null, container.dataset.location || null);
        });
    });
}

// Merkzettel
if (document.getElementById('my-mz')) {
    import('./merkzettel/ListInjection');
}

// Katalog
if (document.body.classList.contains('of-selector')) {
    import('./selector/00_lib_jquery_ui');
    import('./selector/artikel_fullscreen_3d');
    import('./selector/dk_filter');
    import('./selector/uebersicht');
}


// Track clicks on a.download
$j('a.download').on('click', function() {
    gtag('event', 'download', {
        event_category: 'Download',
        event_label: $j(this).text()
    });
});

// Track Linkedin conversion id
$j("a[class*='lintrk-conversion-']").on('click', function() {
    if (typeof window.lintrk === "function") {
        this.classList.forEach(function (className) {
            if (className.startsWith('lintrk-conversion-')) {
                window.lintrk('track', {conversion_id: className.substring(18)});
            }
        });
    }
});
