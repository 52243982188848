'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchConstants from '../../constants/SearchConstants';
import SearchActions from '../../actions/SearchActions';
import cN from 'classnames';

export default class SeitenErgebnis extends Component {
    _onMouseOver = (event) => {
		event.stopPropagation();
		SearchActions.resultMouseOver(this.props.facetKey, this.props.seite.id);
	};

    _onMouseOut = (event) => {
		event.stopPropagation();
		SearchActions.resultMouseOut(this.props.facetKey, this.props.seite.id);
	};

    render() {

		let beschreibung = '';
		if (this.props.mode === SearchConstants.MODE_PAGE && this.props.seite.title !== this.props.seite.description) {
			beschreibung = <div className='seitenergebnis__description'>{this.props.seite.description}</div>;
		}

		return (
			<div
				key={this.props.seite.id}
				className={cN(
					'seitenergebnis seitenergebnis--page',
					{
						'resulthighlight': true,
						'resulthighlight--dimmed': this.props.highlightedFacet === this.props.facetKey && this.props.highlightedResult !== null,
						'resulthighlight--highlighted': this.props.highlightedResult === this.props.seite.id
					}
				)}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				<div className='seitenergebnis__counter'>{this.props.i}</div>
				<div className='seitenergebnis__title'><a href={this.props.seite.exportedUrl}>{this.props.seite.title.replace(/^FSB\s.\s/i, '')}</a></div>
				{beschreibung}
			</div>
		);
	}
}

SeitenErgebnis.propTypes = {
	i: PropTypes.number.isRequired,
	seite: PropTypes.object.isRequired,
	mode: PropTypes.string,
	facetKey: PropTypes.string,
	highlightedFacet: PropTypes.string,
	highlightedResult: PropTypes.string,
};
