'use strict';

import ReactDOM from 'react-dom';
import NavigationWrapper from './components/NavigationWrapper';
import NavigationActions from '@nav21/NavigationActions';
import NavigationConstants from '@nav21/NavigationConstants';
import SearchActions from '@search/actions/SearchActions';
import SearchConstants from '@search/constants/SearchConstants';

const targetElement = document.getElementById('nav21');

if ((CMJSContext.page.properties.navigationContent || CMJSContext.page.properties.navigationUrl) && targetElement) {

    const init = (navData) => {
        if (navData === null) {
            return;
        }

        let responsiveDisplayMode = NavigationConstants.DISPLAY_MODE_DESKTOP;
        if (window.innerWidth < 768) {
            responsiveDisplayMode = NavigationConstants.DISPLAY_MODE_MOBILE;
        }

        NavigationActions._init(navData, responsiveDisplayMode);
        SearchActions._init(SearchConstants.MODE_MENU, CMJSContext.page.properties.displayMode, CMJSContext.page.properties.ajaxBase, CMJSContext.page.properties.searchURL, CMJSContext.page.properties.categories, CMJSContext.page.properties.responsive);

        if (window.location.hash.length > 1) {
            const hashValue = window.location.hash.substr(1);
            const targetNavItem = navData.find(item => {
                return item.path && item.path.endsWith(hashValue);
            });
            if (targetNavItem) {
                NavigationActions.toggleMenuItem(targetNavItem);
            }
        }

        ReactDOM.render(<NavigationWrapper />, targetElement);
    };

    if (CMJSContext.page.properties.navigationContent) {
        init(CMJSContext.page.properties.navigationContent);
    }
    else {
        const navLoader = $j.ajax({
            type: 'GET',
            url: CMJSContext.page.properties.navigationUrl
        });
        navLoader.fail((jqXHR, textStatus, errorThrown) => {
            console.error('failed to load navigation.js', errorThrown);
        });
        navLoader.done(function (data) {
            init(data);
        });
    }

	let windowResized;
	const windowResizedCallback = function() {
		let responsiveDisplayMode = NavigationConstants.DISPLAY_MODE_DESKTOP;
		if (window.innerWidth < 768) {
			responsiveDisplayMode = NavigationConstants.DISPLAY_MODE_MOBILE;
		}
		NavigationActions.updateDisplayMode(responsiveDisplayMode);
	};
	jQuery(window).on('resize orientationChanged', function(){
		clearTimeout(windowResized);
		windowResized = setTimeout(windowResizedCallback, 100);
	});

}
else {
	console.error('Missing navContent and targetElement');
}
