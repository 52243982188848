'use strict';

// Dependencies
import { Component } from 'react';

class PageNotFoundSearch extends Component {
    constructor(props, context) {
        super(props, context);

        let path = decodeURIComponent(window.location.pathname).replace(/^\/..\/..\//, '').replace(/\//g, ' ').trim();

        this.state = {
			localQuery: '' + path
		};
    }

    _onChange = event => {
		this.setState({
			localQuery: event.target.value
		});
	};

    _clearQuery = () => {
		this.setState({
			localQuery: ''
		});
	};

    render() {
		return (
			<form className='pagenotfoundsearch' action={CMJSContext.page.properties.searchURL} method='GET'>
				<div className="row pagenotfoundsearch__content">
					<div className="pagenotfoundsearch__label col-xs-hidden col-sm-3 col-md-4">
						<h2>{CMJSContext.L('srch')}</h2>
					</div>
					<div className="pagenotfoundsearch__field col-sm-9 col-md-8">
						<input
							name='query'
							className='pagenotfoundsearch__input'
							type='text'
							onChange={this._onChange}
							value={this.state.localQuery}
							placeholder={CMJSContext.L('srch.placeholder.404.standard')}
						/>
						<div className="searchinput__input__clear" onClick={this._clearQuery}></div>
					</div>
				</div>
			</form>
		);
	}
}
export default  PageNotFoundSearch;
