/******************* KARUSSELL *********************/
CMJSContext.namespace('CMJSContext.Element.Karussell.Notifications');
/*
	options
	- wrap around
	- show Bubbles
	- show Overlay Navigation
	- Vertical/Horizontal

(
  {
    name = "zeigeBubbles";
    description = "Nagivations-Bubbels unterhalb des Karussells anzeigen?";
    type = "Boolean";
    defaultValue = true;
  },
  {
    name = "zeigeNavigation";
    description = "Navigations-Pfeile zum wechseln der Elemente anzeigen?";
    type = "Boolean";
    defaultValue = true;
  },
  {
    name = "zeigeOverlay";
    description = "Overlay-Container. Benötigt für zeigeNavigation und KarusellText-Elemente";
    type = "Boolean";
    defaultValue = true;
  },
  {
    name = "timerDelay";
    description = "Angabe in Millisekunden, wie lange ein Karussell-Element angezeigt wird";
    type = "Number";
    defaultValue = 0;
  },
  {
    name = "animation";
    type = "String";
    defaultValue = "slide";
    values = ("slide", "fade");
  }
)

*/
CMJSContext.Element.Karussell = function () {
	this.name = "Karussell";
	this.debug = false;
	this.bubbels = null;

	this.currentIndex = null;
	this.numElements = null;

	this.elementContainer;
	this.elements;
	this.overlayContainer;
	this.timer;

	// page change duration
	this.duration = 1250;
};

CMJSContext.Element.Karussell.Notifications = {};

/**
 * Sent right before the animation-calls are made.
 * @param currentElement The currently shown element
 * @param currentIndex The current Index
 * @param nextElement The element which will be shown next
 * @param nextIndex The index of the new element
 * @param duration How long the animation will take
 */
CMJSContext.Element.Karussell.Notifications.willActivateElement = 'KarussellWillActivateElement';
/**
 * Sent once the new Element finished animating
 * @param currentElement The currently shown element
 * @param currentIndex The current Index
 * @param nextElement The element which will be shown next
 * @param nextIndex The index of the new element
 */
CMJSContext.Element.Karussell.Notifications.didActivateElement = 'KarussellDidActivateElement';

CMJSContext.Element.Karussell.prototype = new CMJSContext.Element();

CMJSContext.Element.Karussell.prototype.init = function() {
	var self = this;
	if (self.debug) log(self.elemId, 'init Karussell');
	if (self.debug) log(self.elemId, 'self.didInit', self.didInit);

	// ini variables
	self.elementContainer = self.j().children('.childParagraphsContainer');
	self.elements = self.elementContainer.children().not('script, .overlay, .filtered'); // i.e. no <script> Tags //TODO HERE Auf Startseite wird gedacht es erscheint unsichtbar

	if (self.debug) log(self.elemId, 'selfElements:', self.elements, 'num', self.elements.length, 'container', self.elementContainer.get(0), 'cch', self.elementContainer.children());
	self.numElements = self.elements.length;
	self.currentIndex = 0;

	if (self.numElements === 1) {
		self.j().addClass('of-pkarussell--single-page');
	}
	else {
		self.j().removeClass('of-pkarussell--single-page');
	}

	if (!self.didInit) {
		self.j().addClass('of-pkarussell--page-0 of-pkarussell--page-first');
	}

	// position first element. not absolute or the karussell collapses
	self.elements.slice(0,1).css({
		left: self.getVisiblePosition(0),
		top: 0,
		position: 'relative'
	});


	self.elements.slice(1).each((index, element) => $j(element).css({
		left: self.getRightPosition(index + 1 /* +1 wg slice */) + '%',
		top: 0,
		position: 'relative'
	}));

    self.elements.each(function() {
        self.onlyBanners = self.onlyBanners && $j(this).is('.of-pbanner');
    });

	// inject overlay div for Karussell-Text's and navigation
	if (!self.didInit && (self.jsProperties.timerDelay || self.jsProperties.zeigeOverlay)) {
		self.overlayContainer = $j('<div>').addClass('of-pkarussell--overlay').insertAfter(self.elementContainer);
        self.overlayContainer.addClass('of-pkarussell--overlay--clickthrough');

        if (self.onlyBanners) {
            self.overlayContainer.addClass('of-pkarussell--overlay--onlybanners');
        }
        else {
            self.overlayContainer.addClass('of-pkarussell--overlay--mixed');
        }
	}

	// add bubbels
    var zeigeBubblesNavigation = !self.jsProperties.zeigeOverlay && self.jsProperties.zeigeNavigation;
	if (self.jsProperties.zeigeBubbles || zeigeBubblesNavigation) {
		if (self.bubbels === null) {
			self.bubbels = CMJSContext.Element.factory('KarussellBubbles', this.elemId + 'bubbels', {'karussell': self, zeigeBubbles: self.jsProperties.zeigeBubbles, zeigeNavigation: zeigeBubblesNavigation}, {});
		}
		self.bubbels.init();
	}

	// add inside click areas
	if (!self.didInit && self.overlayContainer && self.jsProperties.zeigeOverlay && self.jsProperties.zeigeNavigation && !Modernizr.touchevents) {
		var arrowLeft = $j('<div>')
				.addClass('of-pkarussell--navigation of-pkarussell--previous')
				.append($j('<div class="of-pkarussell--hover">'));

		var arrowRight = $j('<div>')
				.addClass('of-pkarussell--navigation of-pkarussell--next')
				.append($j('<div class="of-pkarussell--hover">'));

		arrowLeft.click(function(){
                    self.activatePreviousElement();
					arrowLeft.children('.of-pkarussell--hover').hide();
					arrowRight.children('.of-pkarussell--hover').hide();
				});
		arrowRight.click(function(){
                    self.activateNextElement();
					arrowLeft.children('.of-pkarussell--hover').hide();
					arrowRight.children('.of-pkarussell--hover').hide();
				});

        self.overlayContainer.removeClass('of-pkarussell--overlay--clickthrough');
		self.overlayContainer.append(arrowLeft).append(arrowRight);
	}

	if (self.jsProperties.zeigeNavigation && Modernizr.touchevents) {
			var magicSwipeConstant = 557681;

			self.j().find('a').on('click', function(e,isMagic) {
				// alert(e.isDefaultPrevented());
				if (isMagic !== magicSwipeConstant) {
					return false;
				}

				window.location = this.href;
			});

			self.j().children('.childParagraphsContainer, .of-pkarussell--overlay').swipe({
				excludedElements: '',
				allowPageScroll: 'auto',
				swipeRight: function(){
					self.stopTimer();
					self.activateElement(self.currentIndex - 1, -1);
				},
				swipeLeft: function(){
					self.stopTimer();
					self.activateElement(self.currentIndex + 1, 1);
				},
				tap : function(event, target){
					$j(target).trigger('click', magicSwipeConstant);
					// Die MagicSwipe Konstante ist gesetzt, dann wurde getappt. Und dann wird der Klick abgefangen
				}
			});
	}


	// add timer
	if (self.jsProperties.timerDelay > 0) {
		self.startTimer(self.jsProperties.timerDelay);
	}

	self.didInit = true;
	if (self.debug) log(self.elemId, 'init\'d Karussell');
};

CMJSContext.Element.Karussell.prototype.getLeftPosition = function(i) {
    // 0 => -100
    // 1 => -200
    // 2 => -300
    return (i + 1) * -100;
}

CMJSContext.Element.Karussell.prototype.getVisiblePosition = function(i) {
    // 0 => 0
    // 1 => -100
    // 2 => -200

    return i * -100;
}

CMJSContext.Element.Karussell.prototype.getRightPosition = function(i) {
    // 0 => 100
    // 1 => 0
    // 2 => -100
    return 100 + (i * -100);
}

CMJSContext.Element.Karussell.prototype.activatePreviousElement = function() {
    this.stopTimer();
    this.activateElement(this.currentIndex - 1, -1);
};

CMJSContext.Element.Karussell.prototype.activateNextElement = function() {
    this.stopTimer();
    this.activateElement(this.currentIndex + 1, 1);
};

/**
 * @param i The element index to activate
 * @param direction from which direction should the next element slide in. -1 LTR, 1 RTL
 */
CMJSContext.Element.Karussell.prototype.activateElement = function(i, direction) {


	var self = this;

	// determine element to show
	var nextIndex = (i + self.numElements) % self.numElements;
	if (self.debug) log(self.elemId, 'activateElement', i, nextIndex, 'of', self.numElements);

	// quit on same element
	if (nextIndex === self.currentIndex) {
		if (self.debug) log(self.elemId, 'same element activated again - noop');
		return;
	}

	// get Elements
	var currentElement = self.elements.eq(self.currentIndex);
	var nextElement = self.elements.eq(nextIndex);
	if (self.debug) log(self.elemId, 'switching from', self.currentIndex, currentElement, 'to', nextIndex, nextElement);

	// determine direction
	if (direction === undefined) {
		if (nextIndex < self.currentIndex) {
			direction = -1; // LTR
		}
		else {
			direction = 1; // RTL
		}
	}

	if (self.debug) log(self.elemId, 'direction', (direction > 0 ? 'von rechts' : 'von links'));

	// prepare elements
    if (direction < 0) {
        // LTR, also Links anfangen
        nextElement.css('left', self.getLeftPosition(nextIndex) + '%');
    }
    else {
        nextElement.css('left', self.getRightPosition(nextIndex) + '%');
    }

	// notify
	if (self.debug) log(self.elemId, 'send notification', CMJSContext.Element.Karussell.Notifications.willActivateElement);
	CMJSContext.NotificationCenter.defaultCenter.postNotification(CMJSContext.Element.Karussell.Notifications.willActivateElement, self, {
		'currentElement': currentElement,
		'currentIndex': self.currentIndex,
		'nextElement': nextElement,
		'nextIndex': nextIndex,
		'duration': self.duration
	});

	// change container class representing the current element index
	self.j().removeClass('of-pkarussell--page-' + self.currentIndex);
	self.j().removeClass('of-pkarussell--page-first');
	self.j().removeClass('of-pkarussell--page-last');
	self.j().addClass('of-pkarussell--page-' + nextIndex);
	if (nextIndex === 0) {
		self.j().addClass('of-pkarussell--page-first');
	}
	else if (nextIndex + 1 >= self.numElements) {
		self.j().addClass('of-pkarussell--page-last');
	}

	// hide elements :O
	self.stopTimer();

	// TODO: Filtern ob aussen oder innen
	if (self.overlayContainer) {
		self.overlayContainer.children('.of-pkarussell--navigation').fadeOut();
	}


	// animate
	$j('body').addClass('body-movin');

    let animationProperties = {
        left: self.getVisiblePosition(nextIndex) + '%',
        visibility : 'visible',
        opacity: '1'
    }

    // Animate the next element
    if (self.jsProperties.animation == 'fade') {
        nextElement.css({
            left: self.getVisiblePosition(nextIndex) + '%',
            opacity: 0,
            zIndex: 10
        })
        animationProperties = {
            visibility : 'visible',
            opacity: 1
        }
    }
	nextElement.animate(animationProperties, {
		duration: self.duration,
		easing: 'easeOutQuint',
		queue: false,
		complete: function(){
			if (self.debug) log(self.elemId, 'send notification', CMJSContext.Element.Karussell.Notifications.didActivateElement);
			CMJSContext.NotificationCenter.defaultCenter.postNotification(CMJSContext.Element.Karussell.Notifications.didActivateElement, self, {
				'currentElement': currentElement,
				'currentIndex': self.currentIndex,
				'nextElement': nextElement,
				'nextIndex': nextIndex
			});

			// nur einmal beim Laden?
			if (self.jsProperties.timerDelay > 0) {
				self.startTimer(self.jsProperties.timerDelay);
			}

			// navigation wieder anzeigen - nur innen
			if (self.overlayContainer) {
				self.overlayContainer.children('.of-pkarussell--navigation').fadeIn();
			}

			$j('body').removeClass('body-movin');
		}
	});

    // And animate the current element
    if (self.jsProperties.animation == 'fade') {
        currentElement.css({
            zIndex: 0
        });
        currentElement.animate({
            opacity: 0
        }, {
            duration: self.duration,
            easing: 'easeOutQuint',
            queue: false
        });
    }
    else {
        currentElement.animate({
            // <0 = LTR
            left: (direction < 0 ? self.getRightPosition(self.currentIndex) : self.getLeftPosition(self.currentIndex)) + '%'
        }, {
            duration: self.duration,
            easing: 'easeOutQuint',
            queue: false
        });
    }

	// okay, store index
	this.currentIndex = nextIndex;

};

CMJSContext.Element.Karussell.prototype.startTimer = function(duration) {
	var self = this;
    if (!self.timer) {
        let container = self.overlayContainer;
        const currentElement = self.elements.eq(self.currentIndex);
        if (currentElement && !currentElement.is('.of-pcontainer')) {
            const images = currentElement.find('picture');
            if (images.length === 1) {
                container = images.eq(0);
            }
        }

        self.timer = $j('<div class="of-pkarussell--timercontainer"><div class="of-pkarussell--timer"></div></div>').appendTo(container);
        if (self.jsProperties.animation == 'fade') {
            self.timer.css('visibility', 'hidden');
        }
    }
    if (!self.j().hasClass('of-pkarussell--timerStopped')) {
        self.timer.find('.of-pkarussell--timer')
        .show()
        .animate(
            {
                width: '100%'
            },
            {
                duration: duration,
                easing: 'linear',
                complete: function() {
                    self.activateElement(self.currentIndex + 1, 1);
                }
            }
        );
		}
};

CMJSContext.Element.Karussell.prototype.stopTimer = function() {
	var self = this;
	if (self.timer) {
		self.timer.find('.of-pkarussell--timer').stop().width(0);
        self.timer.remove();
        self.timer = null;
	}
};

CMJSContext.Element.Karussell.prototype.onStartpageIntroFinished = function() {
	const self = this;
	self.startTimer(self.jsProperties.timerDelay);
};


//CMJSContext.NotificationCenter.defaultCenter.postNotification('startpageIntroFinished', self, {});
