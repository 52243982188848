CMJSContext.Element.KarussellBubbles = function () {
	this.name = "KarussellBubbles";
	this.debug = false;

	this.bubbels = null;
};

CMJSContext.Element.KarussellBubbles.prototype = new CMJSContext.Element();

CMJSContext.Element.KarussellBubbles.prototype.init = function() {
	var self = this;
	if (self.debug) log('init KarussellBubbles');

	var karussell = self.jsProperties.karussell;
    var zeigeBubbles = self.jsProperties.zeigeBubbles;
    var zeigeNavigation = self.jsProperties.zeigeNavigation;
    console.log('Bubbles', karussell, self, zeigeBubbles, zeigeNavigation);

    if (!(zeigeBubbles || zeigeNavigation)) {
        return;
    }

	if (self.debug) log('add observer');
	if (!self.didInit) {
		CMJSContext.NotificationCenter.defaultCenter.addObserver(this, 'onWillActivateElement', CMJSContext.Element.Karussell.Notifications.willActivateElement, karussell);
		if (self.debug) log('Center now has', CMJSContext.NotificationCenter.defaultCenter.observerCount(), 'obs');
	}

	if (!self.didInit) {
		if (self.debug) log('add bubbles');
		self.bubbels = $j('<div class="of-pkarussellbubbles">');
	}

	if (self.didInit) {
		self.bubbels.empty();
	}

	if (zeigeBubbles && karussell.elements.length > 1) {
        var bubbleContainer = $j('<div>');
		karussell.elements.each(function(i){
			$j('<div>')
				.addClass('of-pkarussellbubble')
				.click(function(){
					karussell.activateElement(i);
					karussell.stopTimer();
					return false;
				})
				.appendTo(bubbleContainer);
		});
        self.bubbels.append(bubbleContainer);
	}

    if (zeigeNavigation) {
        $j('<div>')
            .addClass('of-pkarussellbubble-nav')
            .addClass('of-pkarussellbubble-nav--prev')
            .click(function(){
                karussell.activatePreviousElement();
                return false;
            })
            .prependTo(self.bubbels);
        $j('<div>')
            .addClass('of-pkarussellbubble-nav')
            .addClass('of-pkarussellbubble-nav--next')
            .click(function(){
                karussell.activateNextElement();
                return false;
            })
            .appendTo(self.bubbels);
    }

	// first one is shown :O
	self.bubbels.find('.of-pkarussellbubble').eq(0).addClass('of-pkarussellbubble--selected').find('.hover').show();
	if (!self.didInit) {
		karussell.j().append(self.bubbels);
		self.bubbels.parent().addClass('of-pkarussell--hasbubbles');
	}

	self.didInit = true;
	if (self.debug) log('init\'d KarussellBubbles');
};

CMJSContext.Element.KarussellBubbles.prototype.onWillActivateElement = function(notification) {

	var duration = notification.userInfo().duration;

	var self = this;
	if (self.debug) log('KarussellBubbles', 'received notification', notification);
	if (self.debug) log('next index is', duration);

	self.bubbels.find('.of-pkarussellbubble--selected .hover').fadeOut(duration / 4);
	self.bubbels.find('.of-pkarussellbubble:eq(' + notification.userInfo().nextIndex + ') .hover').fadeIn(duration / 4);

	self.bubbels.find('.of-pkarussellbubble').removeClass('of-pkarussellbubble--selected');
	self.bubbels.find('.of-pkarussellbubble').eq(notification.userInfo().nextIndex).addClass('of-pkarussellbubble--selected');


};
