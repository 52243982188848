/*
 Remove Me :-o

 Was macht das PHP überhaupt noch?

 Im Falle einer normalen Seite prüft es, ob die Cookies alle gesetzt sind
 Wenn nicht, dann werden sie gesetzt und im Popup vorbelegt.

 Im Falle einer Katalog-Seite wird nix vorbelegt und keine Cookies gesetzt.

 Dann gibts noch ein Apache+PHP Konstrukt, dass sicherstellt, dass alle Links auf den Digitalen Katalog ein m-Parameter
 enthalten.


 Was macht dieses Kackteil?

 a) Es guckt, ob Cookies für Zielgruppe und Markt gesetzt sind. Das sollte im PHP immer der Fall sein.

 Es Hört auf das Absenden des MZS Popups per Button #mlzmodalsubmitbutton
 Dann wird die neue Zielgruppe gesetzt.

 */
/**
 * Provides methods to communicate with the PHP backend over AJAX and to generate the selection form
 */
const WhoAmIClass = function() {
	"use strict";
	this.debug = false;

	this.modal = null;

	this.markt = null;

	this.zgMainDropdown = null;
	this.zgSubDropdown = null;
	this.zielGruppen = null;

	this.marketDropdown = null;

	this.languageDropdown = null;

	this.init();
}

/**
 * Initializes the whole ordeal.
 * @param a_zielGruppen The JSON-Structure representing the zielGruppen
 * @param a_isPreselected Whether or not the values are preselected (i.e. this is not a template or not)
 */
WhoAmIClass.prototype.init = function() {

	// the modal window
	this.modal = jQuery('#mlzmodal');
	this.modal.on('hidden.bs.modal', this.onModalClose.bind(this));

	// Hook up Zielgruppen and init then
	this.zgMainDropdown = jQuery('#WhoAmIZG');
	this.zgSubDropdown = jQuery('#WhoAmIZG2')
	this.zielGruppen = window.WhoAmIZGs;
	this.initZGDropdowns();

	// Market and Language
	this.marketDropdown = jQuery('#WhoAmIMarket');
	this.languageDropdown = jQuery('#WhoAmILang');

	// Hook up Main event-Listener
	jQuery('#menu-my-market').on('click', this.openWhoAmIPopup.bind(this));
	jQuery('#menu-my-language').on('click', this.openWhoAmIPopup.bind(this));
	jQuery('#menu-my-market--mobile').on('click', this.openWhoAmIPopup.bind(this));
	jQuery('#menu-my-language--mobile').on('click', this.openWhoAmIPopup.bind(this));
	jQuery('#WhoAmISubmit').on('click', this.onSubmitButtonClick.bind(this));

	// If the dropdown are not preselected get the state via ajax because of authenticated users and IP Detection
	if (window.WhoAmIPrefilled !== true) {
		this.loadData();
	}
	else {
		this.setMarkt(window.WhoAmIMarkt, true);

		if (CMJSContext.page.properties.site === '/fsb.de' && window.WhoAmIMarkt === 'na' && window.WhoAmIAutomaticallyDetectedMarkt) {
			this.openWhoAmIPopup();
		}
	}
};

/**
 * Opens the modal window.
 */
WhoAmIClass.prototype.openWhoAmIPopup = function() {
	this.modal.modal();
	jQuery('select', this.modal).select2({
		dropdownParent: this.modal.find('.modal-dialog')
	});
	return false;
};

/**
 * Reacts to mouse clicks on the submit button.
 * - Prevents the default action
 * - Sends the data to the backend
 */
WhoAmIClass.prototype.onSubmitButtonClick = function(event) {
	var self = this;
	this.sendData(!this.WhoAmIPrefilled).then(function(){
		self.modal.modal('toggle');
	});

	event.preventDefault();
	return false;
};


WhoAmIClass.prototype.onModalClose = function() {
	if (!this.WhoAmIPrefilled && !this.zgMainDropdown.val()) {
		this.setZG('sonstiges');
		this.sendData(false);
	}
	
};

/**
 * Loads the data from the backend and handles the response
 */
WhoAmIClass.prototype.loadData = function() {
	var self = this;

	var loadData = jQuery.ajax({
		type: 'GET',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: {
			module			: 'MarktSpracheUndZielgruppeAjax',
			languageCode	: CMJSContext.page.properties.languageCode
		}
	});

	loadData.done(function(data){
		if (data.m) {
			self.setMarkt(data.m, true);
		}
		if (data.s) {
			self.languageDropdown.val(data.s).trigger('change.select2');
		}
		if (data.zg) {
			self.setZG(data.zg);
		}
		// keine Zielgruppe und loadData?
		// Popup öffnen
		else if (window.WhoAmIPrefilled !== true) {
			self.openWhoAmIPopup();
		}

	});
};

/**
 * Submits the data to the backend and handles the response (and triggers notifications?)
 */
WhoAmIClass.prototype.sendData = function(reloadOnZGSelection) {
	var self = this;

	self.zgMainDropdown.prop('disabled', true);
	self.zgSubDropdown.prop('disabled', true);
	self.marketDropdown.prop('disabled', true);
	self.languageDropdown.prop('disabled', true);

	var sendRequest = $j.ajax({
		type: 'POST',
		url: CMJSContext.page.properties.ajaxBase + '/modules/res/ajax.php',
		dataType: 'json',
		data: {
			module			: 'MarktSpracheUndZielgruppeAjax',
			languageCode	: CMJSContext.page.properties.languageCode,
			'zielGruppe'	: this.zgSubDropdown.val() ? this.zgMainDropdown.val() + '.' + this.zgSubDropdown.val() : this.zgMainDropdown.val(),
			'markt' 		: this.marketDropdown.val(),
			'sprache' 		: this.languageDropdown.val()
		}
	});

	if (typeof gtag !== 'undefined') {
		gtag('event', 'set_who_am_i', {
			'dim_markt': this.marketDropdown.val(),
			'dim_zg': this.zgSubDropdown.val() ? this.zgMainDropdown.val() + '.' + this.zgSubDropdown.val() : this.zgMainDropdown.val()
		});
	}

	var ret = jQuery.Deferred();

	sendRequest.done(function(data){
		if (self.debug) console.log('senData', data);


		// If the language was changed, we need to redirect to the start page. Why? Because we have stupid multi tree localization.
		if (data.r) {
			if (typeof window.pageTransitionTo === 'function') {
				window.pageTransitionTo(data.r);
			}
			else {
				document.location = data.r;
			}
			return;
		}




		if (data.zg) {
			// Im DK muss die Seite neugeladen werden, damit der DK die Änderungen mitbekommt
			if (reloadOnZGSelection) {
				document.location.reload();
			}
			self.setZG(data.zg);
		}
		if (data.m) {
			self.setMarkt(data.m);
		}
		if (data.s) {
			self.languageDropdown.val(data.s).trigger('change.select2');
		}

		self.zgMainDropdown.prop('disabled', false);
		self.zgSubDropdown.prop('disabled', false);
		self.marketDropdown.prop('disabled', false);
		self.languageDropdown.prop('disabled', false);

		ret.resolve();
	});

	sendRequest.fail(function(){
		// ... mhhh... kein Plan :-o

		self.zgMainDropdown.prop('disabled', false);
		self.zgSubDropdown.prop('disabled', false);
		self.marketDropdown.prop('disabled', false);
		self.languageDropdown.prop('disabled', false);

		ret.resolve();
	});

	return ret;
};

/**
 * Initializes the Zielgruppen-Dropdowns and their event listeners etc.
 */
WhoAmIClass.prototype.initZGDropdowns = function() {
	var self = this;
	self.zgMainDropdown.on('change', function(){
		var value = self.zgMainDropdown.val();
		self.updateSubZGDropdown(value);
	});
};

WhoAmIClass.prototype.setZG = function(zielGruppe) {
	if (zielGruppe.indexOf('.') > 0) {
		var zgParts = zielGruppe.split('.');

		this.zgMainDropdown.val(zgParts[0]).trigger('change.select2');
		this.updateSubZGDropdown(zgParts[0], zgParts[1]);
	}
	else {
		this.zgMainDropdown.val(zielGruppe).trigger('change.select2');
		this.zgSubDropdown.val('').parent().hide();
	}

	if (typeof gtag !== 'undefined') {
		gtag('event', 'set_zg', { 'dim_zg': zielGruppe});
	}
};

WhoAmIClass.prototype.updateSubZGDropdown = function(mainZG, selectedSubZG) {
	var data = this.zielGruppen[mainZG];
	if (data) {
		// clear the dropdown
		this.zgSubDropdown.empty();
		// fill dropdown
		for (var k in data) {
			if (typeof data[k] !== 'function') {
				jQuery('<option value="' + k + '">' + data[k] + '</option>').appendTo(this.zgSubDropdown);
			}
		}
		// show dropdown
		this.zgSubDropdown.parent().show();
		this.zgSubDropdown.select2({dropdownParent: jQuery('#mlzmodal').find('.modal-dialog')});

		if (selectedSubZG) {
			this.zgSubDropdown.val(selectedSubZG).trigger('change.select2');
		}
	}
	else {
		// hide detail selection
		this.zgSubDropdown.val('').parent().hide();
	}
};

WhoAmIClass.prototype.setMarkt = function(markt, isInitialDataLoad) {
	if (markt !== this.markt) {
		this.markt = markt;
		var marktName = this.marketDropdown.find('option[value=' + markt + ']').text();

		var marketLi = document.getElementById('menu-my-market');
		if (marketLi) {
			marketLi.innerText = window.WhoAmIMarktLabel + ': ' + marktName;
		}
		var marketLiMobile = document.getElementById('menu-my-market--mobile');
		if (marketLiMobile) {
			marketLiMobile.innerText = window.WhoAmIMarktLabel + ': ' + marktName;
		}

		var langName = this.languageDropdown.find('option[value=' + CMJSContext.page.properties.languageCode + ']').text();
		var langLi = document.getElementById('menu-my-language');
		if (langLi) {
			langLi.innerText = window.WhoAmILangLabel + ': ' + langName;
		}
		var langLiMobile = document.getElementById('menu-my-language--mobile');
		if (langLiMobile) {
			langLiMobile.innerText = window.WhoAmILangLabel + ': ' + langName;
		}

		this.marketDropdown.val(markt).trigger('change.select2');

		if (typeof gtag !== 'undefined') {
			gtag('event', 'change_markt', { 'dim_markt': markt });
		}

		// Nicht mehr automatisch den Markt umstellen gem. FSBSUP-77 Dann weicht der Markt der Seite halt vom Markt im Menü ab... merkt doch eh keiner?!
		// Im Katalog muss zudem der URL-Parameter geändert werden und vmtl. auf die Startseite weitergeleitet werden.
		if (!isInitialDataLoad && ((document.location.href.includes('?m=') && !document.location.href.includes('?m=' + markt)) || (document.location.href.includes('&m=') && !document.location.href.includes('&m=' + markt)))) {
			var newUrl = document.location.href;

			// neuen Markt reinsetzen
			newUrl = newUrl.replace(/([?&])m=[a-z]{2,3}/, '$1m=' + markt);

			// Im Katalog auf die Startseite umleiten (FSBSUP-1)
			if (document.location.href.includes('.jsp')) {
				newUrl = newUrl.replace(/[^/]*\.jsp.*$/, 'index.jsp?m=' + markt);
			}

			if (newUrl !== document.location.href) {
				document.location.href = newUrl;
			}
		}
	}
};

WhoAmIClass.prototype.getZielgruppe = function() {
	return this.zgMainDropdown.val();
};

WhoAmIClass.prototype.getMarkt = function() {
	return this.marketDropdown.val();
};

/**
 * The "single" object of the WhoAmI class. Don't create more ;-)
 * @type {WhoAmIClass}
 */
window.WhoAmI = new WhoAmIClass();
