'use strict';

// Dependencies
import { Component } from 'react';
import PropTypes from 'prop-types';
import cN from 'classnames';
import SearchConstants from '../../constants/SearchConstants';

// SubComponents
import Headline from './Headline.jsx';
import Facetten from './Facetten.jsx';
import Results from './Results.jsx';
import Hinweistext from '../Common/Hinweistext.jsx';

class PageSearch extends Component {

	render() {

		let mobileHinweise;
		if (this.props.displayMode === 'mobile') {
			mobileHinweise = <Hinweistext key={'res_' + SearchConstants.FACET_FAQ} {...this.props} />;
		}

		return (
			<div className={cN('sitesearch pagesearch', {'pagesearch--active-facet': this.props.activeFacet !== SearchConstants.FACET_ALL})}>
				<Headline {...this.props} />
				{mobileHinweise}
				<div className="row pagesearch__content">
					<div className="pagesearch__content__wrap">
						<div className="col-sm-4">
							<Facetten {...this.props} />
						</div>
						<div className="col-sm-8">
							<Results {...this.props} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
PageSearch.propTypes = {
	activeFacet: PropTypes.string,
	displayMode: PropTypes.string
};
export default  PageSearch;
