//Basic initialization for all fields.

(function(){
	jQuery.fn.select2.defaults.set('minimumResultsForSearch', 15);
	jQuery.fn.select2.defaults.set('placeholder', CMJSContext.L('select.please_choose'));
	jQuery.fn.select2.defaults.set('dropdownCssClass', 'sal-openforms');
	jQuery.fn.select2.defaults.set('width', '100%');

	// geht ncht... kA warum
	// => CMJSContext.L('select.no_match')
	jQuery.fn.select2.defaults.set('translations', {
													noResults: function() { return CMJSContext.L('select.no_match'); }
												});
	jQuery.fn.select2.defaults.set('language', {
													noResults: function() { return CMJSContext.L('select.no_match'); }
												});

	/*
	jQuery.fn.select2.defaults = {
		minimumResultsForSearch: 12,
		placeholder: CMJSContext.L('select.please_choose'),
		formatNoMatches: function () { return CMJSContext.L('select.no_match'); },
		dropdownCssClass: 'sal-openforms'
	};
	*/
	jQuery('select').select2()
		.on("change",
			function() {
				if (document.getElementById(this.id + 'UCId') != null) {
					ASB.partial(this.id + 'UCId', this.id, null);
				}
			}
		).on("select2-blur",
		function() {
			if (document.getElementById(this.id + 'UCId') != null) {
				ASB.partial(this.id + 'UCId', this.id, null);
			}
		}
	);
})();
