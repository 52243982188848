'use strict';

// Dependencies
import {Component} from 'react';
import PropTypes from 'prop-types';

class FSBLPBlockNavigationVTeaser extends Component {
    render() {

        let content = [];

        if (this.props.medium) {
            content.push(<div key="medium" dangerouslySetInnerHTML={{__html: this.props.medium}} />);
        }
        if (this.props.headline) {
            content.push(<h3 key="headline" dangerouslySetInnerHTML={{__html: this.props.headline}} />);
        }
        if (this.props.text) {
            content.push(<div key="text" dangerouslySetInnerHTML={{__html: this.props.text}} />);
        }

        if (Array.isArray(this.props.references) && this.props.references.length > 0) {
            const ref = this.props.references[0];
            const target = ref.url.indexOf('http') === 0 && !(ref.url.includes('www.fsb.de') || ref.url.includes('stage.fsb.de') || ref.url.includes('www.fsbna.com') || ref.url.includes('stage.fsbna.com') || ref.url.includes('www4.') || ref.url.includes('stage4.')) ? '_blank' : '';
            content = <a href={this.props.references[0].url.replaceAll('&amp;', '&')} target={target}>{content}</a>;
        }
        return <div className={this.props.styleClass}>
            {content}
        </div>;
    }
}

FSBLPBlockNavigationVTeaser.propTypes = {
    component: PropTypes.string.isRequired,
    elemid: PropTypes.string.isRequired,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.object),
    styleClass: PropTypes.string,
    text: PropTypes.string,
    medium: PropTypes.string,
};

export default FSBLPBlockNavigationVTeaser;
